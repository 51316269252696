import { IS_VERBOSE } from 'config/development'
import { getChainlinkChartPricesFromGraph } from 'domain/prices'
import { OracleKeeperFetcher } from 'domain/synthetics/tokens/useOracleKeeperFetcher'
import { TVDataProvider } from 'domain/tradingview/TVDataProvider'
import { Bar } from 'domain/tradingview/types'
import { sleep } from 'gmx/lib/sleep'

export class SyntheticsTVDataProvider extends TVDataProvider {
  candlesTimeout = 5000
  oracleKeeperFetcher: OracleKeeperFetcher

  constructor(p: {
    resolutions: { [key: number]: string }
    oracleKeeperFetcher: OracleKeeperFetcher
  }) {
    super(p)
    this.oracleKeeperFetcher = p.oracleKeeperFetcher
  }

  private async _getTokenChartPrice(
    chainId: number,
    ticker: string,
    period: string,
    from?: number,
    to?: number,
  ): Promise<Bar[]> {
    const limit = 5000

    const bars = await Promise.race([
      this.oracleKeeperFetcher
        .fetchPythOracleCandles({ symbol: ticker, period, limit, from, to })
        .then((bars) => bars),
      sleep(this.candlesTimeout).then(() =>
        Promise.reject(`Oracle candles timeout`),
      ),
    ])
      .catch((ex) => {
        // eslint-disable-next-line no-console
        IS_VERBOSE && console.warn(ex, 'Switching to graph chainlink data')
        return Promise.race([
          getChainlinkChartPricesFromGraph(ticker, period) as Promise<Bar[]>,
          sleep(this.candlesTimeout).then(() =>
            Promise.reject(`Chainlink candles timeout`),
          ),
        ])
      })
      .catch((ex) => {
        // eslint-disable-next-line no-console
        IS_VERBOSE && console.warn('Load history candles failed', ex)
        return [] as Bar[]
      })

    return bars
  }

  override async getTokenChartPrice(
    chainId: number,
    ticker: string,
    period: string,
    from?: number,
    to?: number,
  ): Promise<Bar[]> {
    // synthetic pair, so we need to execute twice
    if (!ticker.includes('/')) {
      return this._getTokenChartPrice(chainId, ticker, period, from, to)
    }

    const tickers = ticker.split('/')

    const pr = tickers.map((x) =>
      this._getTokenChartPrice(chainId, x, period, from, to),
    )

    const bars = await Promise.all(pr)

    if (bars.length !== 2) {
      IS_VERBOSE &&
        // eslint-disable-next-line no-console
        console.warn('something went wrong loading the bars for both tickers')
      return []
    }

    const t1 = bars[0]
    const t2 = bars[1]

    const div = (x: number, y: number) => x / y

    const computedBars: Bar[] = t1.map((bar, i) => {
      const other = t2[i]

      return {
        ...bar,
        close: div(bar.close, other.close),
        high: div(bar.high, other.high),
        low: div(bar.low, other.low),
        open: div(bar.open, other.open),
        ticker,
      }
    })

    return computedBars
  }

  override async getLimitBars(p: {
    chainId: number
    ticker: string
    period: string
    limit: number
    from?: number
    to?: number
  }) {
    return this.oracleKeeperFetcher.fetchPythOracleCandles({
      symbol: p.ticker,
      period: p.ticker,
      limit: p.limit,
      from: p.from,
      to: p.to,
    })
  }
}
