import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import tradeboxStore from '@store/tradeboxStore'
import BuyInputSection from 'components/BuyInputSection/BuyInputSection'
import { USD_DECIMALS } from 'gmx/lib/legacy'
import { formatAmount } from 'gmx/lib/numbers'
import React, { useCallback } from 'react'

type Props = {
  triggerRatioInputValue: string
  setTriggerRatioInputValue: (triggerRatioInputValue: string) => void
}

const TriggerRatioInput: React.FC<Props> = ({
  triggerRatioInputValue,
  setTriggerRatioInputValue,
}) => {
  const markRatio = tradeboxStore((store) => store.markRatio)

  const onInputValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTriggerRatioInputValue(e.target.value)
    },
    [setTriggerRatioInputValue],
  )

  return (
    <BuyInputSection
      topLeftLabel={`Price`}
      topRightLabel={`Mark`}
      topRightValue={formatAmount(markRatio?.ratio, USD_DECIMALS, 4)}
      // onClickTopRightLabel={() => {
      //   setTriggerRatioInputValue(
      //     formatAmount(markRatio?.ratio, USD_DECIMALS, 10),
      //   )
      // }}
      inputValue={triggerRatioInputValue}
      onInputValueChange={onInputValueChange}
    >
      {markRatio && (
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-1">
            <RenderTokenIcon
              symbol={markRatio.smallestToken.symbol}
              size="small"
              state={triggerRatioInputValue ? 'active' : 'default'}
            />
            <span>{markRatio.smallestToken.symbol}</span>
          </div>
          <span>per</span>
          <div className="flex items-center gap-1">
            <RenderTokenIcon
              symbol={markRatio.largestToken.symbol}
              size="small"
              state={triggerRatioInputValue ? 'active' : 'default'}
            />
            <span>{markRatio.largestToken.symbol}</span>
          </div>
        </div>
      )}
    </BuyInputSection>
  )
}

export default TriggerRatioInput
