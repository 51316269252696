import { ShareBar } from '@components/shared/ShareBar'
import Tooltip from '@components/shared/Tooltip'
import { tradeboxStoreBigNumberSelector } from '@store/tradeboxStore'
import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import StatsTooltipRow from 'components/StatsTooltip/StatsTooltipRow'
import { formatPercentage, formatUsd, getBasisPoints } from 'gmx/lib/numbers'
import { TradeBoxProps } from '../TradeBox/TradeBox'

type Props = Pick<TradeBoxProps, 'marketInfo'>

const OpenInterestBalanceRow = (p: Props) => {
  const { marketInfo } = p
  const totalInterestUsd = tradeboxStoreBigNumberSelector(
    (store) => store.marketCardInfo.totalInterestUsd,
  )

  return (
    <ExchangeInfoRow
      label={`Open Interest Balance`}
      value={
        <div>
          <Tooltip
            placement="bottom"
            content={
              <div>
                {marketInfo && totalInterestUsd && (
                  <>
                    <StatsTooltipRow
                      label={`Long Open Interest`}
                      value={
                        <span>
                          {formatUsd(marketInfo.longInterestUsd, {
                            displayDecimals: 0,
                          })}{' '}
                          <br />
                          {totalInterestUsd.gt(0) &&
                            `(${formatPercentage(
                              getBasisPoints(
                                marketInfo.longInterestUsd,
                                totalInterestUsd,
                              ),
                            )})`}
                        </span>
                      }
                      showDollar={false}
                    />
                    <br />
                    <StatsTooltipRow
                      label={`Short Open Interest`}
                      value={
                        <span>
                          {formatUsd(marketInfo.shortInterestUsd, {
                            displayDecimals: 0,
                          })}{' '}
                          <br />
                          {totalInterestUsd.gt(0) &&
                            `(${formatPercentage(
                              getBasisPoints(
                                marketInfo.shortInterestUsd,
                                totalInterestUsd,
                              ),
                            )})`}
                        </span>
                      }
                      showDollar={false}
                    />
                  </>
                )}
              </div>
            }
          >
            {totalInterestUsd?.gt(0) ? (
              <ShareBar
                share={marketInfo?.longInterestUsd}
                total={totalInterestUsd}
              />
            ) : (
              '...'
            )}
          </Tooltip>
        </div>
      }
    />
  )
}

export default OpenInterestBalanceRow
