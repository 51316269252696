import Checkbox from 'components/Checkbox/Checkbox'
import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import { MarketSelector } from 'components/MarketSelector/MarketSelector'
import {
  getKeepLeverageKey,
  getLeverageEnabledKey,
  getLeverageKey,
} from 'config/localStorage'
import { useUserReferralInfo } from 'domain/referrals/hooks'

import {
  MarketInfo,
  MarketsInfoData,
  getMarketIndexName,
} from 'domain/synthetics/markets'
import { OrderInfo, OrderType, OrdersInfoData } from 'domain/synthetics/orders'
import {
  PositionInfo,
  PositionsInfoData,
  formatLeverage,
  usePositionsConstants,
} from 'domain/synthetics/positions'
import { TokenData, TokensData } from 'domain/synthetics/tokens'
import {
  AvailableTokenOptions,
  TradeMode,
  TradeType,
  TriggerThresholdType,
  useSwapRoutes,
} from 'domain/synthetics/trade'
import { TradeFlags } from 'domain/synthetics/trade/useTradeFlags'

import Tooltip from '@components/shared/Tooltip'
import WalletSwapSlider from '@components/swap/WalletSwapSlider'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import tradeboxStore from '@store/tradeboxStore'
import { BASIS_POINTS_DIVISOR, MAX_ALLOWED_LEVERAGE } from 'config/factors'
import { MAX_METAMASK_MOBILE_DECIMALS } from 'config/ui'
import {
  useIsSubaccountActive,
  useSubaccount,
  useSubaccountModalOpen,
} from 'context/SubaccountContext/SubaccountContext'
import { Token } from 'domain/tokens'
import { BigNumber, ethers } from 'ethers'
import { useChainId } from 'gmx/lib/chains'
import { DUST_BNB, USD_DECIMALS } from 'gmx/lib/legacy'
import { useLocalStorageSerializeKey } from 'gmx/lib/localStorage'
import { formatAmountFree, limitDecimals, parseValue } from 'gmx/lib/numbers'
import useIsMetamaskMobile from 'gmx/lib/wallets/useIsMetamaskMobile'
import useWallet from 'gmx/lib/wallets/useWallet'
import { PendingTransaction } from 'hooks/usePaymaster'
import { useViewport } from 'hooks/useViewport'
import InfoIcon from 'public/icons/info.svg'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ConfirmationBox } from '../ConfirmationBox/ConfirmationBox'
import MarketCard from '../MarketCard/MarketCard'
import MarketCardPricesUpdater from '../MarketCard/MarketCardPricesUpdater'
import { SwapCard } from '../SwapCard/SwapCard'
import AmountsProcessor from './AmountsProcessor'
import CollateralSelectorRow from './CollateralSelectorRow'
import DecreaseSizeInput from './DecreaseSizeInput'
import FormValidator from './FormValidator'
import IncreaseComponent from './IncreaseComponent'
import IncreaseOrderInfo from './IncreaseOrderInfo/IncreaseOrderInfo'
import LeverageSliderWrapper from './LeverageSliderWrapper'
import MarketOptionsUpdater from './MarketOptionsUpdater'
import MarketPoolSelectorRow from './MarketPoolSelectorRow'
import PayComponent from './PayComponent'
import ReceiveRow from './ReceiveRow'
import SlippagePercentageSelector from './SlippagePercentageSelector'
import SwapComponent from './SwapComponent'
import SwitchTokens from './SwitchTokens'
import TradeBoxFormSubmitButton from './TradeBoxFormSubmitButton'
import TradeBoxHeader from './TradeBoxHeader'
import TriggerOrderInfo from './TriggerOrderInfo'
import TriggerPriceInput from './TriggerPriceInput'
import TriggerRatioInput from './TriggerRatioInput'
import { getLeverageFromOption } from './utils'
import WrappedTradeFeesRow from './WrappedTradeFeesRow'

export type FocusedInputType = 'from' | 'to' | undefined

export type TradeBoxProps = {
  tradeType: TradeType
  tradeMode: TradeMode
  availableTradeModes: TradeMode[]
  tradeFlags: TradeFlags
  isWrapOrUnwrap: boolean
  fromTokenAddress?: string
  fromToken?: TokenData
  toTokenAddress?: string
  toToken?: TokenData
  marketAddress?: string
  marketInfo?: MarketInfo
  collateralAddress?: string
  collateralToken?: TokenData
  avaialbleTokenOptions: AvailableTokenOptions
  existingPosition?: PositionInfo
  existingOrder?: OrderInfo
  positionsInfo?: PositionsInfoData
  ordersInfo?: OrdersInfoData
  allowedSlippage: number
  acceptablePriceImpactBpsForLimitOrders: BigNumber
  savedIsPnlInLeverage: boolean
  isHigherSlippageAllowed: boolean
  shouldDisableValidation?: boolean
  marketsInfoData?: MarketsInfoData
  tokensData?: TokensData
  setIsHigherSlippageAllowed: (value: boolean) => void
  onSelectFromTokenAddress: (fromTokenAddress?: string) => void
  onSelectToTokenAddress: (toTokenAddress?: string) => void
  onSelectTradeType: (tradeType: TradeType) => void
  onSelectTradeMode: (tradeMode: TradeMode) => void
  setPendingTxns: (txns: PendingTransaction[]) => void
  onSelectMarketAddress: (marketAddress?: string) => void
  onSelectCollateralAddress: (collateralAddress?: string) => void
  setIsEditingAcceptablePriceImpact: (val: boolean) => void
  switchTokenAddresses: () => void
}

export const tradeTypeLabels = {
  [TradeType.Long]: `Long`,
  [TradeType.Short]: `Short`,
  [TradeType.Swap]: `Swap`,
}

export type TradeStageTypes = 'trade' | 'confirmation' | 'processing'

export function TradeBox(p: TradeBoxProps) {
  const {
    tradeMode,
    tradeType,
    tradeFlags,
    availableTradeModes,
    isWrapOrUnwrap,
    tokensData,
    fromTokenAddress,
    fromToken,
    toTokenAddress,
    toToken,
    marketAddress,
    marketInfo,
    collateralAddress,
    collateralToken,
    avaialbleTokenOptions,
    savedIsPnlInLeverage,
    positionsInfo,
    existingPosition,
    existingOrder,
    ordersInfo,
    shouldDisableValidation,
    acceptablePriceImpactBpsForLimitOrders,
    allowedSlippage,
    isHigherSlippageAllowed,
    marketsInfoData,
    setIsHigherSlippageAllowed,
    onSelectMarketAddress,
    onSelectCollateralAddress,
    onSelectFromTokenAddress,
    onSelectToTokenAddress,
    onSelectTradeMode,
    onSelectTradeType,
    setIsEditingAcceptablePriceImpact,
    setPendingTxns,
    switchTokenAddresses,
  } = p
  const {
    isLong,
    isSwap,
    isIncrease,
    isPosition,
    isLimit,
    isTrigger,
    isMarket,
  } = tradeFlags
  const { openConnectModal } = useConnectModal()
  const { sortedAllMarkets } = avaialbleTokenOptions
  const [isDraggingSlider, setIsDraggingSlider] = useState(false)
  const slippage = tradeboxStore((store) => store.slippage)
  const updateProp = tradeboxStore((store) => store.updateProp)
  const isSubaccountActive = useIsSubaccountActive()

  const { isMobile } = useViewport()

  const { chainId } = useChainId()
  const { signer, account } = useWallet()
  const isMetamaskMobile = useIsMetamaskMobile()
  // const { gasPrice } = useGasPrice(chainId)
  // const { gasLimits } = useGasLimits(chainId)
  const userReferralInfo = useUserReferralInfo(signer, chainId, account)
  // const { showDebugValues } = useSettings()
  // const { data: hasOutdatedUi } = useHasOutdatedUi()

  const { minCollateralUsd, minPositionSizeUsd } =
    usePositionsConstants(chainId)

  const [stage, setStage] = useState<TradeStageTypes>('trade')
  const [focusedInput, setFocusedInput] = useState<FocusedInputType>()

  const [fixedTriggerThresholdType, setFixedTriggerThresholdType] =
    useState<TriggerThresholdType>()
  const [fixedTriggerOrderType, setFixedTriggerOrderType] = useState<
    OrderType.LimitDecrease | OrderType.StopLossDecrease
  >()
  const [fixedTriggerAcceptablePrice, setFixedTriggerAcceptablePrice] =
    useState<BigNumber>()

  const [fromTokenInputValue, setFromTokenInputValue] = useState('')

  const [toTokenInputValue, setToTokenInputValue] = useState('')

  const [closeSizeInputValue, setCloseSizeInputValue] = useState('')
  const closeSizeUsd = parseValue(closeSizeInputValue || '0', USD_DECIMALS)!

  const [triggerPriceInputValue, setTriggerPriceInputValue] =
    useState<string>('')
  const triggerPrice = parseValue(triggerPriceInputValue, USD_DECIMALS)

  const [triggerRatioInputValue, setTriggerRatioInputValue] =
    useState<string>('')

  const [leverageOption, setLeverageOption] = useLocalStorageSerializeKey(
    getLeverageKey(chainId),
    2,
  )
  const leverage = getLeverageFromOption(leverageOption)
  const [, setOneClickModalOpen] = useSubaccountModalOpen()
  const subaccount = useSubaccount(null, 1)

  const [isLeverageEnabled, setIsLeverageEnabled] = useLocalStorageSerializeKey(
    getLeverageEnabledKey(chainId),
    true,
  )
  const [keepLeverage, setKeepLeverage] = useLocalStorageSerializeKey(
    getKeepLeverageKey(chainId),
    true,
  )
  const useOneClickTrading = tradeboxStore((store) => store.useOneClickTrading)

  const swapRoute = useSwapRoutes({
    marketsInfoData,
    fromTokenAddress,
    toTokenAddress: isPosition ? collateralAddress : toTokenAddress,
  })

  const hasExistingOrder = Boolean(existingOrder),
    hasExistingPosition = Boolean(existingPosition)

  const availableCollaterals = useMemo(() => {
    if (!marketInfo) {
      return []
    }

    if (marketInfo.isSameCollaterals) {
      return [marketInfo.longToken]
    }

    return [marketInfo.longToken, marketInfo.shortToken]
  }, [marketInfo])

  const swapOutLiquidity = swapRoute.maxSwapLiquidity

  const onSubmit = useCallback(() => {
    if (!account) {
      openConnectModal?.()
      return
    }

    const decreaseAmounts = tradeboxStore.getState().decreaseAmounts
    if (
      isTrigger &&
      decreaseAmounts?.triggerThresholdType &&
      decreaseAmounts?.triggerOrderType &&
      decreaseAmounts.acceptablePrice
    ) {
      setFixedTriggerOrderType(decreaseAmounts.triggerOrderType)
      setFixedTriggerThresholdType(decreaseAmounts.triggerThresholdType)
      setFixedTriggerAcceptablePrice(decreaseAmounts.acceptablePrice)
    }

    setStage('confirmation')
  }, [account, openConnectModal, isTrigger, setStage])

  useEffect(
    function resetTriggerPrice() {
      setTriggerPriceInputValue('')
    },
    [toTokenAddress, tradeMode],
  )

  const onSwitchTokens = useCallback(() => {
    setFocusedInput((old) => (old === 'from' ? 'to' : 'from'))
    switchTokenAddresses()
    setFromTokenInputValue(toTokenInputValue || '')
    setToTokenInputValue(fromTokenInputValue || '')
  }, [
    setFocusedInput,
    switchTokenAddresses,
    setFromTokenInputValue,
    setToTokenInputValue,
    fromTokenInputValue,
    toTokenInputValue,
  ])

  const onConfirmationClose = useCallback(() => {
    setStage('trade')
  }, [])

  const onConfirmed = useCallback(() => {
    if (isMarket) {
      setStage('processing')
      return
    }
    setStage('trade')
  }, [isMarket])

  // if (showDebugValues) {
  //   const swapPathStats =
  //     swapAmounts?.swapPathStats || increaseAmounts?.swapPathStats

  // if (swapPathStats) {
  //   console.log('Swap Path', {
  //     path: swapPathStats.swapPath
  //       .map((marketAddress) => marketsInfoData?.[marketAddress]?.name)
  //       .join(' -> '),
  //     priceImpact: swapPathStats.swapSteps
  //       .map((step) => formatDeltaUsd(step.priceImpactDeltaUsd))
  //       .join(' -> '),
  //     usdOut: swapPathStats.swapSteps
  //       .map((step) => formatUsd(step.usdOut))
  //       .join(' -> '),
  //   })
  // }
  // }

  useEffect(() => {
    if (Number(leverageOption) > MAX_ALLOWED_LEVERAGE / BASIS_POINTS_DIVISOR) {
      setLeverageOption(MAX_ALLOWED_LEVERAGE / BASIS_POINTS_DIVISOR)
    }
  }, [])

  const handleSliderDrag = useCallback(() => {
    if (!isDraggingSlider) {
      setIsDraggingSlider(true)
    }
  }, [isDraggingSlider])

  const handleSliderDragEnd = useCallback(() => {
    if (isDraggingSlider) {
      setIsDraggingSlider(false)
    }
  }, [isDraggingSlider])

  const handleSliderChange = (amountIn: number) => {
    setFromTokenInputValue(amountIn.toString())
  }

  const onMaxClick = useCallback(() => {
    if (fromToken?.balance) {
      const maxAvailableAmount = fromToken.isNative
        ? fromToken.balance.sub(BigNumber.from(DUST_BNB).mul(2))
        : fromToken.balance
      setFocusedInput('from')
      const formattedAmount = formatAmountFree(
        maxAvailableAmount,
        fromToken.decimals,
      )
      const finalAmount = isMetamaskMobile
        ? limitDecimals(formattedAmount, MAX_METAMASK_MOBILE_DECIMALS)
        : formattedAmount
      setFromTokenInputValue(finalAmount)
    }
  }, [
    fromToken?.balance,
    fromToken?.isNative,
    fromToken?.decimals,
    isMetamaskMobile,
  ])

  const onFromInputValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFocusedInput('from')
      setFromTokenInputValue(e.target.value)
    },
    [setFocusedInput, setFromTokenInputValue],
  )

  const onToInputValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setFocusedInput('to')
      setToTokenInputValue(e.target.value)
    },
    [setFocusedInput, setToTokenInputValue],
  )

  const handleOnSelectFromTokenAddress = useCallback(
    (token: Token) => {
      onSelectFromTokenAddress(token.address)
    },
    [onSelectFromTokenAddress],
  )

  const handleOnSelectToTokenAddress = useCallback(
    (token: Token) => {
      onSelectToTokenAddress(token.address)
    },
    [onSelectToTokenAddress],
  )

  const handleSlippagePercentageChange = (val: number) => {
    updateProp('slippage', val)
  }

  function renderTokenInputs() {
    return (
      <>
        <PayComponent
          isIncrease={isIncrease}
          fromToken={fromToken}
          fromTokenInputValue={fromTokenInputValue}
          onInputValueChange={onFromInputValueChange}
          onMaxClick={onMaxClick}
          availableTokenOptions={avaialbleTokenOptions}
          fromTokenAddress={fromTokenAddress}
          chainId={chainId}
          onSelectFromTokenAddress={handleOnSelectFromTokenAddress}
        />
        {isSwap && fromToken?.balance && (
          <div className="pt-4">
            <WalletSwapSlider
              amount={Number(fromTokenInputValue)}
              onChange={(v) => handleSliderChange(Number(v))}
              step={0.001}
              maxAmount={parseFloat(
                ethers.utils.formatUnits(
                  (fromToken?.balance as BigNumber) || 0,
                  fromToken?.decimals,
                ),
              )}
              handleStartDrag={handleSliderDrag}
              handleEndDrag={handleSliderDragEnd}
            />
          </div>
        )}

        {isSwap && <SwitchTokens onSwitchTokens={onSwitchTokens} />}

        {isSwap && (
          <SwapComponent
            toToken={toToken}
            toTokenInputValue={toTokenInputValue}
            onInputValueChange={onToInputValueChange}
            availableTokenOptions={avaialbleTokenOptions}
            toTokenAddress={toTokenAddress}
            chainId={chainId}
            onSelectTokenAddress={handleOnSelectToTokenAddress}
          />
        )}

        {isIncrease && (
          <IncreaseComponent
            toTokenInputValue={toTokenInputValue}
            onInputValueChange={onToInputValueChange}
            availableTokenOptions={avaialbleTokenOptions}
            toTokenAddress={toTokenAddress}
            chainId={chainId}
            onSelectTokenAddress={handleOnSelectToTokenAddress}
            tradeType={tradeType}
            isLeverageEnabled={isLeverageEnabled}
            leverageOption={leverageOption}
          />
        )}
      </>
    )
  }

  function renderPositionControls() {
    return (
      <>
        {isTrigger && (
          <ExchangeInfoRow
            className="mb-1 mt-4 w-full"
            label={`Market`}
            value={
              <MarketSelector
                label={`Market`}
                selectedIndexName={
                  toToken
                    ? getMarketIndexName({
                        indexToken: toToken,
                        isSpotOnly: false,
                      })
                    : undefined
                }
                markets={sortedAllMarkets || []}
                isSideMenu
                onSelectMarket={(indexName, marketInfo) =>
                  onSelectToTokenAddress(marketInfo.indexToken.address)
                }
              />
            }
          />
        )}
        <MarketPoolSelectorRow
          selectedMarket={marketInfo}
          indexToken={toToken}
          hasExistingOrder={hasExistingOrder}
          hasExistingPosition={hasExistingPosition}
          onSelectMarketAddress={onSelectMarketAddress}
        />
        <CollateralSelectorRow
          selectedMarketAddress={marketInfo?.marketTokenAddress}
          selectedCollateralAddress={collateralAddress}
          availableCollaterals={availableCollaterals}
          hasExistingOrder={hasExistingOrder}
          hasExistingPosition={hasExistingPosition}
          onSelectCollateralAddress={onSelectCollateralAddress}
          isMarket={isMarket}
        />
        {isTrigger && existingPosition?.leverage && (
          <Checkbox
            reverseOrder
            className="mb-1 w-full"
            isChecked={keepLeverage}
            setIsChecked={setKeepLeverage}
          >
            <span className="font-sm">
              Keep leverage at {formatLeverage(existingPosition.leverage)}
            </span>
          </Checkbox>
        )}
      </>
    )
  }

  const handleSubaccountClick = useCallback(() => {
    setOneClickModalOpen(true)
  }, [setOneClickModalOpen])

  return (
    <>
      <div
        className="mt-1.5 px-4 sm:mx-4 sm:px-0 md:mt-0 md:pt-5 lg:pt-0"
        style={{ width: isMobile ? '100%' : '364px' }}
      >
        <AmountsProcessor
          tradeFlags={tradeFlags}
          fromToken={fromToken}
          toToken={toToken}
          marketInfo={marketInfo}
          collateralToken={collateralToken}
          savedIsPnlInLeverage={savedIsPnlInLeverage}
          existingPosition={existingPosition}
          acceptablePriceImpactBpsForLimitOrders={
            acceptablePriceImpactBpsForLimitOrders
          }
          isWrapOrUnwrap={isWrapOrUnwrap}
          tokensData={tokensData}
          // extra Props
          fromTokenInputValue={fromTokenInputValue}
          toTokenInputValue={toTokenInputValue}
          leverage={leverage}
          triggerPrice={triggerPrice}
          swapRoute={swapRoute}
          userReferralInfo={userReferralInfo}
          isLeverageEnabled={isLeverageEnabled}
          focusedInput={focusedInput}
          closeSizeUsd={closeSizeUsd}
          minCollateralUsd={minCollateralUsd}
          minPositionSizeUsd={minPositionSizeUsd}
          keepLeverage={keepLeverage}
          triggerRatioInputValue={triggerRatioInputValue}
          chainId={chainId}
          setFocusedInput={setFocusedInput}
          setFromTokenInputValue={setFromTokenInputValue}
          setToTokenInputValue={setToTokenInputValue}
        />
        <MarketOptionsUpdater
          chainId={chainId}
          hasExistingOrder={hasExistingOrder}
          hasExistingPosition={hasExistingPosition}
          marketAddress={marketAddress}
          marketsInfoData={marketsInfoData}
          onSelectMarketAddress={onSelectMarketAddress}
          onSelectCollateralAddress={onSelectCollateralAddress}
          ordersInfo={ordersInfo}
          positionsInfo={positionsInfo}
          tradeFlags={tradeFlags}
          toToken={toToken}
        />
        <div>
          <TradeBoxHeader
            tradeType={tradeType}
            onSelectTradeType={onSelectTradeType}
            tradeMode={tradeMode}
            onSelectTradeMode={onSelectTradeMode}
            availableTradeModes={availableTradeModes}
            isMobile={isMobile}
          />
          <FormValidator
            tradeFlags={tradeFlags}
            fromToken={fromToken}
            toToken={toToken}
            marketInfo={marketInfo}
            existingPosition={existingPosition}
            collateralToken={collateralToken}
            isWrapOrUnwrap={isWrapOrUnwrap}
            chainId={chainId}
            account={account}
            swapOutLiquidity={swapOutLiquidity}
            tradeType={tradeType}
            triggerPrice={triggerPrice}
            fixedTriggerThresholdType={fixedTriggerThresholdType}
            stage={stage}
            minCollateralUsd={minCollateralUsd}
            closeSizeUsd={closeSizeUsd}
            oneClickTradingError={
              useOneClickTrading && isSubaccountActive && !subaccount
            }
          />

          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSubmit()
            }}
          >
            {(isSwap || isIncrease) && renderTokenInputs()}
            {isTrigger && (
              <DecreaseSizeInput
                existingPosition={existingPosition}
                closeSizeInputValue={closeSizeInputValue}
                setCloseSizeInputValue={setCloseSizeInputValue}
              />
            )}

            {isSwap && isLimit && (
              <TriggerRatioInput
                triggerRatioInputValue={triggerRatioInputValue}
                setTriggerRatioInputValue={setTriggerRatioInputValue}
              />
            )}
            {isPosition && (isLimit || isTrigger) && (
              <TriggerPriceInput
                triggerPriceInputValue={triggerPriceInputValue}
                setTriggerPriceInputValue={setTriggerPriceInputValue}
              />
            )}

            {isIncrease && (
              <div className="pb-10 pt-4">
                <LeverageSliderWrapper
                  isLeverageEnabled={!!isLeverageEnabled}
                  setIsLeverageEnabled={setIsLeverageEnabled}
                  isLong={isLong}
                  leverageOption={leverageOption}
                  setLeverageOption={setLeverageOption}
                />
              </div>
            )}

            {isIncrease && !(isLimit || isTrigger) && (
              <div className="space-y-2">
                <p className="text-xs font-medium text-th-fgd-3">Slippage</p>
                <SlippagePercentageSelector
                  slippagePercentage={slippage}
                  changeSlippagePercentage={handleSlippagePercentageChange}
                />
              </div>
            )}

            <div className="flex justify-between pt-4">
              <div className="flex items-center gap-2">
                <div className="text-xs font-medium capitalize text-th-fgd-3">
                  One Click Trading
                </div>
                <Tooltip content="Reduce wallet signing popups with One-Click Trading. This option is also available through the Wallet menu in the top right.">
                  <InfoIcon />
                </Tooltip>
              </div>
              <Checkbox
                reverseOrder
                className="mb-1 w-fit"
                isChecked={useOneClickTrading}
                setIsChecked={() =>
                  updateProp('useOneClickTrading', !useOneClickTrading)
                }
                onClick={handleSubaccountClick}
              />
            </div>

            <div className="Exchange-swap-button-container mt-4 w-full">
              <TradeBoxFormSubmitButton
                account={account}
                onSubmit={onSubmit}
                tradeType={tradeType}
                shouldDisableValidation={!!shouldDisableValidation}
                handleSubaccountClick={handleSubaccountClick}
                oneClickTradingError={
                  useOneClickTrading && isSubaccountActive && !subaccount
                }
              />
            </div>

            <div className="mb-4 mt-4">
              {isPosition && <>{renderPositionControls()}</>}

              {isIncrease && (
                <IncreaseOrderInfo
                  isLeverageEnabled={isLeverageEnabled}
                  leverage={leverage}
                  existingPosition={existingPosition}
                  toToken={toToken}
                  tradeFlags={tradeFlags}
                  allowedSlippage={allowedSlippage}
                  isLong={isLong}
                  setIsEditingAcceptablePriceImpact={
                    setIsEditingAcceptablePriceImpact
                  }
                  acceptablePriceImpactBpsForLimitOrders={
                    acceptablePriceImpactBpsForLimitOrders
                  }
                />
              )}
              {isTrigger && (
                <TriggerOrderInfo
                  toToken={toToken}
                  acceptablePriceImpactBpsForLimitOrders={
                    acceptablePriceImpactBpsForLimitOrders
                  }
                  existingPosition={existingPosition}
                  setIsEditingAcceptablePriceImpact={
                    setIsEditingAcceptablePriceImpact
                  }
                />
              )}

              <WrappedTradeFeesRow />

              <ReceiveRow
                isTrigger={isTrigger}
                existingPosition={existingPosition}
                collateralToken={collateralToken}
              />
            </div>
          </form>
        </div>
      </div>

      {isSwap && (
        <div className="px-4">
          <SwapCard
            maxLiquidityUsd={swapOutLiquidity}
            fromToken={fromToken}
            toToken={toToken}
          />
        </div>
      )}
      <div className="Exchange-swap-info-group">
        {isPosition && (
          <>
            <MarketCardPricesUpdater isLong={isLong} marketInfo={marketInfo} />
            <MarketCard
              isLong={isLong}
              isIncrease={isIncrease}
              marketInfo={marketInfo}
              allowedSlippage={slippage || allowedSlippage}
            />
          </>
        )}
      </div>
      {stage === 'confirmation' && (
        <ConfirmationBox
          PayComponent={
            <PayComponent
              isIncrease={isIncrease}
              fromToken={fromToken}
              fromTokenInputValue={fromTokenInputValue}
              onInputValueChange={onFromInputValueChange}
              onMaxClick={onMaxClick}
              availableTokenOptions={avaialbleTokenOptions}
              fromTokenAddress={fromTokenAddress}
              chainId={chainId}
              onSelectFromTokenAddress={handleOnSelectFromTokenAddress}
            />
          }
          // IsSwapComponent={
          //   <SwapComponent
          //     toToken={toToken}
          //     toTokenInputValue={toTokenInputValue}
          //     onInputValueChange={onToInputValueChange}
          //     availableTokenOptions={avaialbleTokenOptions}
          //     toTokenAddress={toTokenAddress}
          //     chainId={chainId}
          //     onSelectTokenAddress={handleOnSelectToTokenAddress}
          //   />
          // }
          // IsIncreaseComponent={
          //   <IncreaseComponent
          //     toTokenInputValue={toTokenInputValue}
          //     onInputValueChange={onToInputValueChange}
          //     availableTokenOptions={avaialbleTokenOptions}
          //     toTokenAddress={toTokenAddress}
          //     chainId={chainId}
          //     onSelectTokenAddress={handleOnSelectToTokenAddress}
          //     tradeType={tradeType}
          //     isLeverageEnabled={isLeverageEnabled}
          //     leverageOption={leverageOption}
          //   />
          // }
          isVisible={stage === 'confirmation'}
          setStage={setStage}
          tradeFlags={tradeFlags}
          isWrapOrUnwrap={isWrapOrUnwrap}
          fromToken={fromToken}
          toToken={toToken}
          triggerPrice={triggerPrice}
          fixedTriggerThresholdType={fixedTriggerThresholdType}
          fixedTriggerOrderType={fixedTriggerOrderType}
          fixedTriggerAcceptablePrice={fixedTriggerAcceptablePrice}
          // triggerRatio={triggerRatio}
          marketInfo={marketInfo}
          collateralToken={collateralToken}
          // marketsOptions={marketsOptions}
          // swapAmounts={swapAmounts}
          // increaseAmounts={increaseAmounts}
          // decreaseAmounts={decreaseAmounts}
          // nextPositionValues={nextPositionValues}
          swapLiquidityUsd={swapOutLiquidity}
          // longLiquidityUsd={longLiquidity}
          // shortLiquidityUsd={shortLiquidity}
          keepLeverage={keepLeverage}
          // fees={fees}
          // executionFee={executionFee}
          // error={error}
          existingPosition={existingPosition}
          shouldDisableValidation={shouldDisableValidation!}
          isHigherSlippageAllowed={isHigherSlippageAllowed}
          ordersData={ordersInfo}
          tokensData={tokensData}
          setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
          setKeepLeverage={setKeepLeverage}
          onClose={onConfirmationClose}
          onSubmitted={onConfirmed}
          setPendingTxns={setPendingTxns}
        />
      )}
    </>
  )
}
