import TradeSwap from 'public/icons/trade-swap-icon.svg'
import { memo } from 'react'

type Props = {
  onSwitchTokens: () => void
}

const SwitchTokens: React.FC<Props> = ({ onSwitchTokens }) => {
  return (
    <div className="Exchange-swap-ball-container">
      <button
        type="button"
        className="Exchange-swap-ball"
        onClick={onSwitchTokens}
      >
        <TradeSwap className="Exchange-swap-ball-icon" />
      </button>
    </div>
  )
}

export default memo(SwitchTokens)
