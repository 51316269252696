import cx from 'classnames'
import { BASIS_POINTS_DIVISOR, MAX_ALLOWED_LEVERAGE } from 'config/factors'
import Slider, { Handle, SliderTooltip } from 'rc-slider'
import 'rc-slider/assets/index.css'

const leverageMarks = {
  2: '2x',
  10: '10x',
  20: '20x',
  30: '30x',
  40: '40x',
  50: '50x',
}

type Props = {
  isPositive?: boolean
  value?: number
  onChange: (value: number) => void
}

export function LeverageSlider({ isPositive, value, onChange }: Props) {
  return (
    <div
      className={cx('LeverageSlider', {
        positive: isPositive,
        negative: !isPositive,
      })}
    >
      <Slider
        min={1.1}
        max={MAX_ALLOWED_LEVERAGE / BASIS_POINTS_DIVISOR}
        step={0.1}
        marks={leverageMarks}
        handle={LeverageSliderHandle}
        onChange={onChange}
        defaultValue={value}
      />
    </div>
  )
}

interface LeverageSliderHandleProps {
  className: string
  prefixCls?: string
  vertical?: boolean
  offset: number
  value: number
  dragging?: boolean
  disabled?: boolean
  min?: number
  max?: number
  reverse?: boolean
  index: number
  tabIndex?: number
  ariaLabel: string
  ariaLabelledBy: string
  ariaValueTextFormatter?: (value: number) => string
  style?: React.CSSProperties
}

export function LeverageSliderHandle({
  value,
  dragging,
  index,
  ...restProps
}: LeverageSliderHandleProps) {
  return (
    <SliderTooltip
      prefixCls="rc-slider-tooltip"
      overlay={`${value.toFixed(2)}x`}
      visible={dragging}
      placement="top"
      key={index}
    >
      <Handle value={value} {...restProps} />
    </SliderTooltip>
  )
}
