import tradeboxStore from '@store/tradeboxStore'
import {
  useIsSubaccountActive,
  useSubaccountAddress,
} from 'context/SubaccountContext/SubaccountContext'
import { useHasOutdatedUi } from 'domain/legacy'
import { getTriggerNameByOrderType } from 'domain/synthetics/positions/utils'
import { TriggerThresholdType } from 'domain/synthetics/trade/types'
import {
  getCommonError,
  getDecreaseError,
  getIncreaseError,
  getSwapError,
} from 'domain/synthetics/trade/utils/validation'
import { BigNumber } from 'ethers'
import { useEffect, useMemo } from 'react'
import { TradeBoxProps, TradeStageTypes, tradeTypeLabels } from './TradeBox'
type Props = Pick<
  TradeBoxProps,
  | 'tradeFlags'
  | 'fromToken'
  | 'toToken'
  | 'isWrapOrUnwrap'
  | 'marketInfo'
  | 'collateralToken'
  | 'existingPosition'
  | 'tradeType'
> & {
  chainId: number
  account: string | undefined
  swapOutLiquidity: BigNumber
  triggerPrice: BigNumber | undefined
  fixedTriggerThresholdType: TriggerThresholdType | undefined
  stage: TradeStageTypes
  minCollateralUsd: BigNumber | undefined
  closeSizeUsd: BigNumber
  oneClickTradingError: boolean
}

const FormValidator = (p: Props) => {
  const {
    tradeFlags,
    fromToken,
    toToken,
    marketInfo,
    existingPosition,
    collateralToken,
    isWrapOrUnwrap,
    chainId,
    account,
    swapOutLiquidity,
    triggerPrice,
    fixedTriggerThresholdType,
    stage,
    minCollateralUsd,
    closeSizeUsd,
    tradeType,
    oneClickTradingError,
  } = p

  const { data: hasOutdatedUi } = useHasOutdatedUi()
  const { isLong, isSwap, isIncrease, isLimit, isTrigger, isMarket } =
    tradeFlags

  const subaccountAddress = useSubaccountAddress()
  const isSubaccountActive = useIsSubaccountActive()

  const {
    fromTokenAmount,
    swapAmounts,
    toTokenAmount,
    triggerRatio,
    markPrice,
    markRatio,
    fees,
    increaseAmounts,
    decreaseAmounts,
    longLiquidity,
    shortLiquidity,
    nextPositionValues,
    updateProp,
    useOneClickTrading,
  } = tradeboxStore()

  const error = useMemo(() => {
    const commonError = getCommonError({
      chainId,
      isConnected: Boolean(account),
      hasOutdatedUi,
    })

    let tradeError: string[] | undefined[] = [undefined]

    if (isSwap) {
      tradeError = getSwapError({
        fromToken,
        toToken,
        fromTokenAmount,
        fromUsd: swapAmounts?.usdIn,
        toTokenAmount,
        toUsd: swapAmounts?.usdOut,
        swapPathStats: swapAmounts?.swapPathStats,
        swapLiquidity: swapOutLiquidity,
        isLimit,
        isWrapOrUnwrap,
        triggerRatio,
        markRatio,
        fees,
      })
    } else if (isIncrease) {
      tradeError = getIncreaseError({
        marketInfo,
        indexToken: toToken,
        initialCollateralToken: fromToken,
        initialCollateralAmount: fromTokenAmount,
        initialCollateralUsd: increaseAmounts?.initialCollateralUsd,
        targetCollateralToken: collateralToken,
        collateralUsd: increaseAmounts?.collateralDeltaUsd,
        sizeDeltaUsd: increaseAmounts?.sizeDeltaUsd,
        existingPosition,
        fees,
        swapPathStats: increaseAmounts?.swapPathStats,
        collateralLiquidity: swapOutLiquidity,
        minCollateralUsd,
        longLiquidity,
        shortLiquidity,
        isLong,
        markPrice,
        triggerPrice,
        isLimit,
        nextPositionValues,
      })
    } else if (isTrigger) {
      tradeError = getDecreaseError({
        marketInfo,
        inputSizeUsd: closeSizeUsd,
        sizeDeltaUsd: decreaseAmounts?.sizeDeltaUsd,
        triggerPrice,
        markPrice,
        existingPosition,
        isContractAccount: false,
        receiveToken: existingPosition?.collateralToken,
        nextPositionValues,
        isLong,
        isTrigger: true,
        minCollateralUsd,
        isNotEnoughReceiveTokenLiquidity: false,
        fixedTriggerThresholdType:
          stage === 'confirmation' ? fixedTriggerThresholdType : undefined,
      })
    }

    return commonError[0] || tradeError[0]
  }, [
    chainId,
    account,
    hasOutdatedUi,
    isSwap,
    isIncrease,
    isTrigger,
    fromToken,
    toToken,
    fromTokenAmount,
    swapAmounts,
    toTokenAmount,
    swapOutLiquidity,
    isLimit,
    isWrapOrUnwrap,
    triggerRatio,
    markRatio,
    fees,
    marketInfo,
    increaseAmounts,
    collateralToken,
    existingPosition,
    minCollateralUsd,
    longLiquidity,
    shortLiquidity,
    isLong,
    markPrice,
    triggerPrice,
    nextPositionValues,
    closeSizeUsd,
    decreaseAmounts?.sizeDeltaUsd,
    stage,
    fixedTriggerThresholdType,
  ])

  useEffect(() => {
    updateProp('formError', error)
  }, [error])

  useEffect(
    function updateSubmitButtonText() {
      let text = ''
      if (error) {
        text = error
      } else if (
        (!isSubaccountActive || !subaccountAddress) &&
        useOneClickTrading
      ) {
        text = `Enable One Click Trading`
      } else if (oneClickTradingError) {
        text = `Add ETH to sub-account to for Gas Fees`
      } else {
        if (isMarket) {
          if (isSwap) {
            text = `Swap ${fromToken?.symbol}`
          } else {
            text = `${tradeTypeLabels[tradeType!]} ${toToken?.symbol}`
          }
        } else if (isLimit) {
          text = `Create Limit order`
        } else {
          text = `Create ${getTriggerNameByOrderType(
            decreaseAmounts?.triggerOrderType!,
          )} Order`
        }
      }
      updateProp('submitButtonText', text)
    },
    [
      decreaseAmounts?.triggerOrderType,
      error,
      fromToken?.symbol,
      isLimit,
      isMarket,
      isSwap,
      toToken?.symbol,
      tradeType,
      tradeTypeLabels,
      useOneClickTrading,
      subaccountAddress,
      isSubaccountActive,
      oneClickTradingError,
    ],
  )

  return null
}

export default FormValidator
