import tradeboxStore from '@store/tradeboxStore'
import cx from 'classnames'
import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import TokenSelector from 'components/TokenSelector/TokenSelector'
import Tooltip from 'components/Tooltip/Tooltip'
import { Token } from 'domain/tokens'
import { useChainId } from 'gmx/lib/chains'
import { memo, useMemo } from 'react'

export type Props = {
  selectedCollateralAddress?: string
  selectedMarketAddress?: string
  availableCollaterals?: Token[]
  hasExistingPosition?: boolean
  hasExistingOrder?: boolean
  onSelectCollateralAddress: (address?: string) => void
  isMarket: boolean
}

function CollateralSelectorRow(p: Props) {
  const {
    selectedCollateralAddress,
    selectedMarketAddress,
    hasExistingOrder,
    hasExistingPosition,
    availableCollaterals,
    onSelectCollateralAddress,
    isMarket,
  } = p
  const marketsOptions = tradeboxStore((store) => store.marketsOptions)

  const {
    collateralWithOrder,
    marketWithOrder,
    marketWithPosition,
    collateralWithPosition,
  } = marketsOptions || {}

  const { chainId } = useChainId()

  const { message, level } = useMemo(() => {
    if (
      !hasExistingPosition &&
      collateralWithPosition &&
      selectedMarketAddress === marketWithPosition?.marketTokenAddress &&
      collateralWithPosition?.address !== selectedCollateralAddress
    ) {
      if (isMarket) {
        return {
          message: (
            <div>
              <>
                <span className="negative">
                  You have an existing position with{' '}
                  {collateralWithPosition.symbol} as collateral. This action
                  will not apply for that position.
                </span>
                <div
                  className="clickable mt-4 underline"
                  onClick={() => {
                    onSelectCollateralAddress(collateralWithPosition.address)
                  }}
                >
                  Switch to {collateralWithPosition.symbol} collateral.
                </div>{' '}
              </>
            </div>
          ),
          level: 'error',
        }
      }

      return {
        message: (
          <div>
            <>
              <span className="negative">
                You have an existing position with{' '}
                {collateralWithPosition.symbol} as collateral. This Order will
                not be valid for that Position.
              </span>
              <div
                className="clickable mt-4 underline"
                onClick={() => {
                  onSelectCollateralAddress(collateralWithPosition.address)
                }}
              >
                Switch to {collateralWithPosition.symbol} collateral.
              </div>{' '}
            </>
          </div>
        ),
        level: 'error',
      }
    }

    if (
      !hasExistingPosition &&
      !hasExistingOrder &&
      !collateralWithPosition &&
      marketWithOrder &&
      selectedMarketAddress === marketWithOrder?.marketTokenAddress &&
      collateralWithOrder &&
      collateralWithOrder.address !== selectedCollateralAddress
    ) {
      return {
        message: (
          <div>
            <>
              You have an existing order with {collateralWithOrder.symbol} as
              collateral.{' '}
              <div
                className="clickable mt-4 underline"
                onClick={() => {
                  onSelectCollateralAddress(collateralWithOrder.address)
                }}
              >
                Switch to {collateralWithOrder.symbol} collateral.
              </div>{' '}
            </>
          </div>
        ),
        level: 'warning',
      }
    }

    return { message: null }
  }, [
    hasExistingPosition,
    collateralWithPosition,
    selectedMarketAddress,
    marketWithPosition?.marketTokenAddress,
    selectedCollateralAddress,
    hasExistingOrder,
    marketWithOrder,
    collateralWithOrder,
    onSelectCollateralAddress,
    isMarket,
  ])

  return (
    <ExchangeInfoRow
      label={
        message ? (
          <Tooltip
            handle={`Collateral In`}
            position="left-bottom"
            className={cx('relative', {
              error: level === 'text-th-error decoration-th-error',
            })}
            renderContent={() => <div className="text-th-fgd-1">{message}</div>}
          />
        ) : (
          `Collateral In`
        )
      }
      className="mb-1"
      value={
        selectedCollateralAddress &&
        availableCollaterals && (
          <TokenSelector
            label={`Collateral In`}
            chainId={chainId}
            tokenAddress={selectedCollateralAddress}
            onSelectToken={(token) => {
              onSelectCollateralAddress(token.address)
            }}
            tokens={availableCollaterals}
            showTokenImgInDropdown={true}
          />
        )
      }
    />
  )
}

export default memo(CollateralSelectorRow)
