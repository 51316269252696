import tradeboxStore from '@store/tradeboxStore'
import { formatPrice } from 'apis/birdeye/helpers'
import BuyInputSection from 'components/BuyInputSection/BuyInputSection'
import { BigNumber, ethers } from 'ethers'
import { USD_DECIMALS } from 'gmx/lib/legacy'
import React, { useCallback } from 'react'

type Props = {
  triggerPriceInputValue: string
  setTriggerPriceInputValue: (triggerPriceInputValue: string) => void
}

const TriggerPriceInput: React.FC<Props> = ({
  triggerPriceInputValue,
  setTriggerPriceInputValue,
}) => {
  const markPrice = tradeboxStore((store) => store.markPrice)

  const onInputValueChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setTriggerPriceInputValue(e.target.value)
    },
    [setTriggerPriceInputValue],
  )

  return (
    <BuyInputSection
      topLeftLabel={`Price`}
      topRightLabel={`Mark`}
      topRightValue={formatPrice(
        parseFloat(
          ethers.utils.formatUnits(
            markPrice || BigNumber.from(0),
            USD_DECIMALS,
          ),
        ),
      )}
      // onClickTopRightLabel={() => {
      //   setTriggerPriceInputValue(
      //     formatAmount(markPrice, USD_DECIMALS, toToken?.priceDecimals || 2),
      //   )
      // }}
      inputValue={triggerPriceInputValue}
      onInputValueChange={onInputValueChange}
    >
      USD
    </BuyInputSection>
  )
}

export default TriggerPriceInput
