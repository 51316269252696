import ToggleSwitch from '@components/shared/ToggleSwitch'
import { LeverageSlider } from 'components/LeverageSlider/LeverageSlider'
import { memo } from 'react'

type Props = {
  isLeverageEnabled: boolean
  setIsLeverageEnabled: (isLeverageEnabled: boolean) => void
  isLong: boolean
  leverageOption: number | undefined
  setLeverageOption: (leverageOption: Props['leverageOption']) => void
}

const LeverageSliderWrapper: React.FC<Props> = ({
  isLeverageEnabled,
  setIsLeverageEnabled,
  isLong,
  leverageOption,
  setLeverageOption,
}) => {
  return (
    <div>
      <ToggleSwitch
        isChecked={isLeverageEnabled ?? false}
        setIsChecked={setIsLeverageEnabled}
        className="mb-4"
      >
        <p className="text-xs">Leverage slider</p>
      </ToggleSwitch>
      {isLeverageEnabled && (
        <LeverageSlider
          value={leverageOption}
          onChange={setLeverageOption}
          isPositive={isLong}
        />
      )}
    </div>
  )
}

export default memo(LeverageSliderWrapper)
