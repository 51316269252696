import { getServerUrl } from 'config/backend'
import { IS_VERBOSE } from 'config/development'
import { getTokenBySymbol, getWrappedToken } from 'config/tokens'
import { getChartPricesFromStats, TIMEZONE_OFFSET } from 'domain/prices'
import { CHART_PERIODS } from 'gmx/lib/legacy'

function getCurrentBarTimestamp(periodSeconds: number) {
  return (
    Math.floor(Date.now() / (periodSeconds * 1000)) * (periodSeconds * 1000)
  )
}

export const getTokenChartPrice = async (
  chainId: number,
  symbol: string,
  period: string,
) => {
  let prices
  try {
    prices = await getChartPricesFromStats(chainId, symbol, period)
  } catch (ex) {
    // eslint-disable-next-line no-console
    IS_VERBOSE && console.warn(ex, 'Switching to graph chainlink data')
  }
  return prices
}

export async function getCurrentPriceOfToken(chainId: number, symbol: string) {
  try {
    const indexPricesUrl = getServerUrl(chainId, '/prices')
    const response = await fetch(indexPricesUrl)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`)
    }
    const indexPrices = await response.json()
    let symbolInfo = getTokenBySymbol(chainId, symbol)
    if (symbolInfo.isNative) {
      symbolInfo = getWrappedToken(chainId)
    }

    return indexPrices[symbolInfo.address]
  } catch (err) {
    // eslint-disable-next-line no-console
    IS_VERBOSE && console.error(err)
  }
}

export function fillBarGaps(prices: any, periodSeconds: any) {
  if (prices.length < 2) {
    return prices
  }

  const lastChartPeriod =
    getCurrentBarTimestamp(periodSeconds) / 1000 + TIMEZONE_OFFSET
  let lastBar = prices[prices.length - 1]

  if (lastBar.time !== lastChartPeriod) {
    prices.push({
      ...lastBar,
      open: lastBar.close,
      time: lastChartPeriod,
    })
  }

  const newPrices = [prices[0]]
  let prevTime = prices[0].time

  for (let i = 1; i < prices.length; i++) {
    const { time, open } = prices[i]
    if (prevTime) {
      const numBarsToFill = Math.floor((time - prevTime) / periodSeconds) - 1
      for (let j = numBarsToFill; j > 0; j--) {
        const newBar = {
          time: time - j * periodSeconds,
          open,
          close: open,
          high: open * 1.0003,
          low: open * 0.9996,
        }
        newPrices.push(newBar)
      }
    }
    prevTime = time
    newPrices.push(prices[i])
  }

  return newPrices
}

export function getStableCoinPrice(period: string, from: number, to: number) {
  const periodSeconds = CHART_PERIODS[period]
  const fromCandle = Math.floor(from / periodSeconds) * periodSeconds
  const toCandle = Math.floor(to / periodSeconds) * periodSeconds
  let priceData: any = []
  for (
    let candleTime = fromCandle;
    candleTime <= toCandle;
    candleTime += periodSeconds
  ) {
    priceData.push({
      time: candleTime,
      open: 1,
      close: 1,
      high: 1,
      low: 1,
    })
  }
  return priceData
    .filter((candle: any) => candle.time >= from && candle.time <= to)
    .map((bar: any) => ({ ...bar, time: bar.time + TIMEZONE_OFFSET }))
}
