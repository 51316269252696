import Input from '@components/forms/Input'
import Button from '@components/shared/Button'
import { useDebounceFn } from '@components/shared/useDebounceFn'
import { IS_VERBOSE } from 'config/development'
import {
  setTraderReferralCodeByUser,
  validateReferralCodeExists,
} from 'domain/referrals/hooks'
import { usePendingTxns } from 'gmx/lib/usePendingTxns'
import useWallet from 'gmx/lib/wallets/useWallet'
import { usePaymaster } from 'hooks/usePaymaster'
import { useViewport } from 'hooks/useViewport'
import { useEffect, useState } from 'react'
import { REFERRAL_CODE_REGEX } from './referralsHelper'

export function ReferralCodeForm({
  callAfterSuccess,
  userReferralCodeString = '',
  type = 'join',
  defaultValue,
}: {
  callAfterSuccess?: () => void
  userReferralCodeString: string
  type: string
  defaultValue?: string
}) {
  const { isMobile } = useViewport()
  const { account, signer, chainId } = useWallet()
  const [referralCode, setReferralCode] = useState(
    defaultValue || userReferralCodeString,
  )
  const [isValidating, setIsValidating] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [referralCodeExists, setReferralCodeExists] = useState(true)
  const [pendingTxns, setPendingTxns] = usePendingTxns()
  const debouncedReferralCode = useDebounceFn(referralCode, 300)
  const { sendPaymasterTransaction } = usePaymaster()

  function getPrimaryText() {
    const isEdit = type === 'edit'
    if (isEdit && debouncedReferralCode === userReferralCodeString) {
      return `Same as current active code`
    }
    if (isEdit && isSubmitting) {
      return `Updating...`
    }

    if (isSubmitting) {
      return `Adding...`
    }
    if (debouncedReferralCode === '') {
      return `Enter Referral Code`
    }
    if (isValidating) {
      return `Checking code...`
    }
    if (!referralCodeExists) {
      return `Referral Code does not exist`
    }

    return isEdit ? `Update` : `Submit`
  }
  function isPrimaryEnabled() {
    if (
      debouncedReferralCode === '' ||
      isSubmitting ||
      isValidating ||
      !referralCodeExists ||
      debouncedReferralCode === userReferralCodeString
    ) {
      return false
    }
    return true
  }

  async function handleSubmit(event: any) {
    const isEdit = type === 'edit'
    event.preventDefault()
    setIsSubmitting(true)

    try {
      const tx = await setTraderReferralCodeByUser(
        chainId,
        referralCode,
        signer,
        sendPaymasterTransaction,
        {
          account,
          successMsg: isEdit
            ? `Referral code updated!`
            : `Referral code added!`,
          failMsg: isEdit
            ? `Referral code updated failed.`
            : `Adding referral code failed.`,
          setPendingTxns,
          pendingTxns,
        },
      )
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.error(tx)

      if (callAfterSuccess) {
        callAfterSuccess()
      }
      setReferralCode('')
    } catch (error) {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.error(error)
    } finally {
      setIsSubmitting(false)
      setIsValidating(false)
    }
  }

  useEffect(() => {
    let cancelled = false
    async function checkReferralCode() {
      if (
        debouncedReferralCode === '' ||
        !REFERRAL_CODE_REGEX.test(debouncedReferralCode)
      ) {
        setIsValidating(false)
        setReferralCodeExists(false)
        return
      }

      setIsValidating(true)
      const codeExists = await validateReferralCodeExists(
        debouncedReferralCode,
        chainId,
      )
      if (!cancelled) {
        setReferralCodeExists(codeExists)
        setIsValidating(false)
      }
    }
    checkReferralCode()
    return () => {
      cancelled = true
    }
  }, [debouncedReferralCode, chainId])

  return (
    <form
      onSubmit={handleSubmit}
      className="flex h-full flex-col justify-between"
    >
      <div>
        <div className="mb-2 text-sm font-medium text-th-fgd-2">
          Enter a Code
        </div>

        <Input
          disabled={isSubmitting}
          type="text"
          placeholder="Enter referral code"
          className="text-input w-full rounded-md border-th-input-border bg-th-bkg-2 px-4 py-3 text-base font-bold text-th-fgd-2 outline-none hover:outline-none"
          value={referralCode}
          onChange={({ target }) => {
            const { value } = target
            setReferralCode(value)
          }}
        />
      </div>
      <Button
        type="submit"
        className="App-cta Exchange-swap-button !bg-th-active !py-3 uppercase !text-th-fgd-1"
        disabled={!isPrimaryEnabled()}
        size={isMobile ? 'medium' : 'large'}
      >
        {getPrimaryText()}
      </Button>
    </form>
  )
}
