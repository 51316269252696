import { useEffect, useMemo } from 'react'
import { formatAmountFree, parseValue } from 'gmx/lib/numbers'
import { BigNumber } from 'ethers'
import {
  TokenData,
  TokensRatio,
  convertToUsd,
  getTokensRatioByPrice,
  parseContractPrice,
} from 'domain/synthetics/tokens'
import {
  SwapAmounts,
  SwapRoutesResult,
  TradeFeesType,
  getDecreasePositionAmounts,
  getIncreasePositionAmounts,
  getMarkPrice,
  getNextPositionValuesForDecreaseTrade,
  getNextPositionValuesForIncreaseTrade,
  getSwapAmountsByFromValue,
  getSwapAmountsByToValue,
  getTradeFees,
} from 'domain/synthetics/trade'
import { TradeBoxProps } from './TradeBox'
import { UserReferralInfo } from 'domain/referrals'
import { USD_DECIMALS } from 'gmx/lib/legacy'
import {
  estimateExecuteDecreaseOrderGasLimit,
  estimateExecuteIncreaseOrderGasLimit,
  estimateExecuteSwapOrderGasLimit,
  getExecutionFee,
  useGasLimits,
  useGasPrice,
} from 'domain/synthetics/fees'
import { DecreasePositionSwapType } from 'domain/synthetics/orders'
import tradeboxStore from '@store/tradeboxStore'
import { usePrevious } from 'react-use'
import useTokenPrice from 'hooks/gmx/usePyth/useTokenPrice'
import { getAvailableUsdLiquidityForPosition } from 'domain/synthetics/markets'
import commonStore from '@store/commonStore'

type Props = Pick<
  TradeBoxProps,
  | 'tradeFlags'
  | 'fromToken'
  | 'toToken'
  | 'marketInfo'
  | 'collateralToken'
  | 'savedIsPnlInLeverage'
  | 'existingPosition'
  | 'acceptablePriceImpactBpsForLimitOrders'
  | 'isWrapOrUnwrap'
  | 'tokensData'
> & {
  fromTokenInputValue: string
  toTokenInputValue: string
  leverage: BigNumber
  triggerPrice: BigNumber | undefined
  swapRoute: SwapRoutesResult
  userReferralInfo: UserReferralInfo | undefined
  isLeverageEnabled: boolean | undefined
  focusedInput: 'from' | 'to' | undefined
  closeSizeUsd: BigNumber
  minCollateralUsd: BigNumber | undefined
  minPositionSizeUsd: BigNumber | undefined
  keepLeverage: boolean | undefined
  triggerRatioInputValue: string
  chainId: number
  setFocusedInput: (focusedInput: Props['focusedInput']) => void
  setFromTokenInputValue: (input: string) => void
  setToTokenInputValue: (input: string) => void
}

const AmountsProcessor = (p: Props) => {
  const {
    tradeFlags,
    fromToken: _fromToken,
    toToken: _toToken,
    marketInfo,
    collateralToken: _collateralToken,
    savedIsPnlInLeverage,
    existingPosition,
    acceptablePriceImpactBpsForLimitOrders,
    isWrapOrUnwrap,
    tokensData,
    // extra Props
    fromTokenInputValue,
    toTokenInputValue,
    leverage,
    triggerPrice,
    swapRoute,
    userReferralInfo,
    isLeverageEnabled,
    focusedInput,
    closeSizeUsd,
    minCollateralUsd,
    minPositionSizeUsd,
    keepLeverage,
    triggerRatioInputValue,
    chainId,
    setFocusedInput,
    setFromTokenInputValue,
    setToTokenInputValue,
  } = p
  const { isIncrease, isLong, isLimit, isTrigger, isPosition, isSwap } =
    tradeFlags
  const updateProp = tradeboxStore((store) => store.updateProp)
  const prices = commonStore((store) => store.prices)

  const _toTokenPrice = useTokenPrice(
    _toToken?.pythId || '',
    _toToken?.quoteAddress || _toToken?.address || '',
    'maxPrice',
  )
  const _fromTokenPrice = useTokenPrice(
    _fromToken?.pythId || '',
    _fromToken?.address || '',
    'maxPrice',
  )
  const _collateralTokenPrice = useTokenPrice(
    _collateralToken?.pythId || '',
    _collateralToken?.address || '',
    'maxPrice',
  )

  const { toToken, fromToken, collateralToken } = useMemo(() => {
    let toToken: TokenData | undefined = undefined,
      fromToken: TokenData | undefined = undefined,
      collateralToken: TokenData | undefined = undefined
    if (_toToken) {
      const basePrice =
        _toToken?.baseId &&
        parseContractPrice(BigNumber.from(1), 30)
          .mul(_toTokenPrice)
          ?.div(prices[_toToken?.baseAddress || '']?.maxPrice)

      toToken = {
        ..._toToken,
        prices: {
          minPrice: basePrice ? basePrice : _toTokenPrice,
          maxPrice: basePrice ? basePrice : _toTokenPrice,
        },
      }
    }
    if (_fromToken) {
      fromToken = {
        ..._fromToken,
        prices: { minPrice: _fromTokenPrice, maxPrice: _fromTokenPrice },
      }
    }

    if (_collateralToken) {
      collateralToken = {
        ..._collateralToken,
        prices: {
          minPrice: _collateralTokenPrice,
          maxPrice: _collateralTokenPrice,
        },
      }
    }

    return {
      toToken,
      fromToken,
      collateralToken,
    }
  }, [
    _toToken,
    _toTokenPrice,
    _fromToken,
    _fromTokenPrice,
    _collateralToken,
    _collateralTokenPrice,
  ])

  const { fromTokenAmount, fromTokenPrice, fromUsd } = useMemo(() => {
    const fromTokenAmount = fromToken
      ? parseValue(fromTokenInputValue || '0', fromToken.decimals)!
      : BigNumber.from(0)

    const fromTokenPrice = fromToken?.prices.minPrice
    const fromUsd = convertToUsd(
      fromTokenAmount,
      fromToken?.decimals,
      fromTokenPrice,
    )

    return { fromTokenAmount, fromTokenPrice, fromUsd }
  }, [fromToken?.decimals, fromTokenInputValue])

  useEffect(() => {
    updateProp('fromTokenAmount', fromTokenAmount)
    updateProp('fromUsd', fromUsd)
  }, [fromUsd, fromTokenAmount])

  const toTokenAmount = toToken
    ? parseValue(toTokenInputValue || '0', toToken.decimals)!
    : BigNumber.from(0)

  const markPrice = useMemo(() => {
    if (!toToken) {
      return undefined
    }

    if (isSwap) {
      return toToken.prices.minPrice
    }

    return getMarkPrice({ prices: toToken.prices, isIncrease, isLong })
  }, [isIncrease, isLong, isSwap, toToken])

  useEffect(() => {
    updateProp('markPrice', markPrice)
  }, [updateProp, markPrice])

  const { markRatio, triggerRatio } = useMemo(() => {
    if (!isSwap || !fromToken || !toToken || !fromTokenPrice || !markPrice) {
      return {}
    }

    const markRatio = getTokensRatioByPrice({
      fromToken,
      toToken,
      fromPrice: fromTokenPrice,
      toPrice: markPrice,
    })

    const triggerRatioValue = parseValue(triggerRatioInputValue, USD_DECIMALS)

    if (!triggerRatioValue) {
      return { markRatio }
    }

    const triggerRatio: TokensRatio = {
      ratio: triggerRatioValue?.gt(0) ? triggerRatioValue : markRatio.ratio,
      largestToken: markRatio.largestToken,
      smallestToken: markRatio.smallestToken,
    }

    return {
      markRatio,
      triggerRatio,
    }
  }, [
    fromToken,
    fromTokenPrice,
    isSwap,
    markPrice,
    toToken,
    triggerRatioInputValue,
  ])

  useEffect(() => {
    updateProp('markRatio', markRatio)
    updateProp('triggerRatio', triggerRatio)
  }, [updateProp, markRatio, triggerRatio])

  const swapAmounts = useMemo(() => {
    if (!isSwap || !fromToken || !toToken || !fromTokenPrice) {
      return undefined
    }

    if (isWrapOrUnwrap) {
      const tokenAmount =
        focusedInput === 'from' ? fromTokenAmount : toTokenAmount
      const usdAmount = convertToUsd(
        tokenAmount,
        fromToken.decimals,
        fromTokenPrice,
      )!
      const price = fromTokenPrice

      const swapAmounts: SwapAmounts = {
        amountIn: tokenAmount,
        usdIn: usdAmount!,
        amountOut: tokenAmount,
        usdOut: usdAmount!,
        swapPathStats: undefined,
        priceIn: price,
        priceOut: price,
        minOutputAmount: tokenAmount,
      }

      return swapAmounts
    } else if (focusedInput === 'from') {
      return getSwapAmountsByFromValue({
        tokenIn: fromToken,
        tokenOut: toToken,
        amountIn: fromTokenAmount,
        triggerRatio: triggerRatio || markRatio,
        isLimit,
        findSwapPath: swapRoute.findSwapPath,
      })
    } else {
      return getSwapAmountsByToValue({
        tokenIn: fromToken,
        tokenOut: toToken,
        amountOut: toTokenAmount,
        triggerRatio: triggerRatio || markRatio,
        isLimit,
        findSwapPath: swapRoute.findSwapPath,
      })
    }
  }, [
    focusedInput,
    fromToken,
    fromTokenAmount,
    fromTokenPrice,
    isLimit,
    isSwap,
    isWrapOrUnwrap,
    markRatio,
    swapRoute.findSwapPath,
    toToken,
    toTokenAmount,
    triggerRatio,
  ])

  useEffect(() => {
    updateProp('swapAmounts', swapAmounts)
  }, [updateProp, swapAmounts])

  const increaseAmounts = useMemo(() => {
    let increaseAmounts
    if (
      !isIncrease ||
      !toToken ||
      !fromToken ||
      !collateralToken ||
      !marketInfo
    ) {
      increaseAmounts = undefined
    } else {
      increaseAmounts = getIncreasePositionAmounts({
        marketInfo,
        indexToken: toToken,
        initialCollateralToken: fromToken,
        collateralToken,
        isLong,
        initialCollateralAmount: fromTokenAmount,
        indexTokenAmount: toTokenAmount,
        leverage,
        triggerPrice: isLimit ? triggerPrice : undefined,
        position: existingPosition,
        savedAcceptablePriceImpactBps: acceptablePriceImpactBpsForLimitOrders,
        findSwapPath: swapRoute.findSwapPath,
        userReferralInfo,
        strategy: isLeverageEnabled
          ? focusedInput === 'from'
            ? 'leverageByCollateral'
            : 'leverageBySize'
          : 'independent',
      })
    }
    return increaseAmounts
  }, [
    acceptablePriceImpactBpsForLimitOrders,
    collateralToken,
    existingPosition,
    focusedInput,
    fromToken,
    fromTokenAmount,
    isIncrease,
    isLeverageEnabled,
    isLimit,
    isLong,
    leverage,
    marketInfo,
    swapRoute.findSwapPath,
    toToken,
    toTokenAmount,
    triggerPrice,
    userReferralInfo,
    updateProp,
  ])

  useEffect(() => {
    updateProp('increaseAmounts', increaseAmounts)
  }, [increaseAmounts])

  const decreaseAmounts = useMemo(() => {
    if (
      !isTrigger ||
      !closeSizeUsd ||
      !marketInfo ||
      !collateralToken ||
      !minCollateralUsd ||
      !minPositionSizeUsd
    ) {
      return undefined
    }

    return getDecreasePositionAmounts({
      marketInfo,
      collateralToken,
      isLong,
      position: existingPosition,
      closeSizeUsd: closeSizeUsd,
      keepLeverage: keepLeverage!,
      triggerPrice,
      savedAcceptablePriceImpactBps: acceptablePriceImpactBpsForLimitOrders,
      userReferralInfo,
      minCollateralUsd,
      minPositionSizeUsd,
    })
  }, [
    acceptablePriceImpactBpsForLimitOrders,
    closeSizeUsd,
    collateralToken,
    existingPosition,
    isLong,
    isTrigger,
    keepLeverage,
    marketInfo,
    minCollateralUsd,
    minPositionSizeUsd,
    triggerPrice,
    userReferralInfo,
    markPrice,
  ])

  useEffect(() => {
    updateProp('decreaseAmounts', decreaseAmounts)
  }, [updateProp, decreaseAmounts])

  const nextPositionValues = useMemo(() => {
    if (!isPosition || !minCollateralUsd || !marketInfo || !collateralToken) {
      return undefined
    }

    if (
      isIncrease &&
      increaseAmounts?.acceptablePrice &&
      fromTokenAmount.gt(0)
    ) {
      return getNextPositionValuesForIncreaseTrade({
        marketInfo,
        collateralToken,
        existingPosition,
        isLong,
        collateralDeltaUsd: increaseAmounts.collateralDeltaUsd,
        collateralDeltaAmount: increaseAmounts.collateralDeltaAmount,
        sizeDeltaUsd: increaseAmounts.sizeDeltaUsd,
        sizeDeltaInTokens: increaseAmounts.sizeDeltaInTokens,
        indexPrice: increaseAmounts.indexPrice,
        showPnlInLeverage: savedIsPnlInLeverage,
        minCollateralUsd,
        userReferralInfo,
      })
    }

    if (isTrigger && decreaseAmounts?.acceptablePrice && closeSizeUsd.gt(0)) {
      return getNextPositionValuesForDecreaseTrade({
        existingPosition,
        marketInfo,
        collateralToken,
        sizeDeltaUsd: decreaseAmounts.sizeDeltaUsd,
        sizeDeltaInTokens: decreaseAmounts.sizeDeltaInTokens,
        estimatedPnl: decreaseAmounts.estimatedPnl,
        realizedPnl: decreaseAmounts.realizedPnl,
        collateralDeltaUsd: decreaseAmounts.collateralDeltaUsd,
        collateralDeltaAmount: decreaseAmounts.collateralDeltaAmount,
        payedRemainingCollateralUsd:
          decreaseAmounts.payedRemainingCollateralUsd,
        payedRemainingCollateralAmount:
          decreaseAmounts.payedRemainingCollateralAmount,
        showPnlInLeverage: savedIsPnlInLeverage,
        isLong,
        minCollateralUsd,
        userReferralInfo,
      })
    }
  }, [
    closeSizeUsd,
    collateralToken,
    decreaseAmounts,
    existingPosition,
    fromTokenAmount,
    increaseAmounts,
    isIncrease,
    isLong,
    isPosition,
    isTrigger,
    marketInfo,
    minCollateralUsd,
    savedIsPnlInLeverage,
    userReferralInfo,
  ])

  useEffect(() => {
    updateProp('nextPositionValues', nextPositionValues)
  }, [updateProp, nextPositionValues])

  const { gasPrice } = useGasPrice(chainId)
  const { gasLimits } = useGasLimits(chainId)

  const { fees, feesType, executionFee } = useMemo(() => {
    if (!gasLimits || !gasPrice || !tokensData) {
      return {}
    }
    if (isSwap && swapAmounts?.swapPathStats) {
      const estimatedGas = estimateExecuteSwapOrderGasLimit(gasLimits, {
        swapsCount: swapAmounts.swapPathStats.swapPath.length,
        callbackGasLimit: BigNumber.from(0),
      })

      return {
        fees: getTradeFees({
          isIncrease: false,
          initialCollateralUsd: swapAmounts.usdIn,
          sizeDeltaUsd: BigNumber.from(0),
          swapSteps: swapAmounts.swapPathStats.swapSteps,
          positionFeeUsd: BigNumber.from(0),
          swapPriceImpactDeltaUsd:
            swapAmounts.swapPathStats.totalSwapPriceImpactDeltaUsd,
          positionPriceImpactDeltaUsd: BigNumber.from(0),
          borrowingFeeUsd: BigNumber.from(0),
          fundingFeeUsd: BigNumber.from(0),
          feeDiscountUsd: BigNumber.from(0),
          swapProfitFeeUsd: BigNumber.from(0),
        }),
        executionFee: getExecutionFee(
          chainId,
          gasLimits,
          tokensData,
          estimatedGas,
          gasPrice,
          BigNumber.from(swapAmounts.swapPathStats.swapPath.length ?? 3),
        ),
        feesType: 'swap' as TradeFeesType,
      }
    }

    if (isIncrease && increaseAmounts) {
      const estimatedGas = estimateExecuteIncreaseOrderGasLimit(gasLimits, {
        swapsCount: increaseAmounts.swapPathStats?.swapPath.length,
      })

      return {
        fees: getTradeFees({
          isIncrease: true,
          initialCollateralUsd: increaseAmounts.initialCollateralUsd,
          sizeDeltaUsd: increaseAmounts.sizeDeltaUsd,
          swapSteps: increaseAmounts.swapPathStats?.swapSteps || [],
          positionFeeUsd: increaseAmounts.positionFeeUsd,
          swapPriceImpactDeltaUsd:
            increaseAmounts.swapPathStats?.totalSwapPriceImpactDeltaUsd ||
            BigNumber.from(0),
          positionPriceImpactDeltaUsd:
            increaseAmounts.positionPriceImpactDeltaUsd,
          borrowingFeeUsd:
            existingPosition?.pendingBorrowingFeesUsd || BigNumber.from(0),
          fundingFeeUsd:
            existingPosition?.pendingFundingFeesUsd || BigNumber.from(0),
          feeDiscountUsd: increaseAmounts.feeDiscountUsd,
          swapProfitFeeUsd: BigNumber.from(0),
        }),
        executionFee: getExecutionFee(
          chainId,
          gasLimits,
          tokensData,
          estimatedGas,
          gasPrice,
          BigNumber.from(increaseAmounts.swapPathStats?.swapPath.length ?? 0),
        ),
        feesType: 'increase' as TradeFeesType,
      }
    }

    if (isTrigger && decreaseAmounts) {
      const swapsCount =
        decreaseAmounts.decreaseSwapType === DecreasePositionSwapType.NoSwap
          ? 0
          : 1
      const estimatedGas = estimateExecuteDecreaseOrderGasLimit(gasLimits, {
        callbackGasLimit: BigNumber.from(0),
        decreaseSwapType: decreaseAmounts.decreaseSwapType,
        swapsCount: 0,
      })

      return {
        fees: getTradeFees({
          isIncrease: false,
          initialCollateralUsd:
            existingPosition?.collateralUsd || BigNumber.from(0),
          sizeDeltaUsd: decreaseAmounts.sizeDeltaUsd,
          swapSteps: [],
          positionFeeUsd: decreaseAmounts.positionFeeUsd,
          swapPriceImpactDeltaUsd: BigNumber.from(0),
          positionPriceImpactDeltaUsd:
            decreaseAmounts.positionPriceImpactDeltaUsd,
          borrowingFeeUsd: decreaseAmounts.borrowingFeeUsd,
          fundingFeeUsd: decreaseAmounts.fundingFeeUsd,
          feeDiscountUsd: decreaseAmounts.feeDiscountUsd,
          swapProfitFeeUsd: decreaseAmounts.swapProfitFeeUsd,
        }),
        executionFee: getExecutionFee(
          chainId,
          gasLimits,
          tokensData,
          estimatedGas,
          gasPrice,
          BigNumber.from(swapsCount ?? 3),
        ),
        feesType: 'decrease' as TradeFeesType,
      }
    }

    return {}
  }, [
    chainId,
    decreaseAmounts,
    existingPosition,
    gasLimits,
    gasPrice,
    increaseAmounts,
    isIncrease,
    isSwap,
    isTrigger,
    swapAmounts,
    tokensData,
  ])

  useEffect(() => {
    updateProp('fees', fees)
    updateProp('feesType', feesType)
    updateProp('executionFee', executionFee)
  }, [updateProp, fees, feesType, executionFee])

  const prevIsISwap = usePrevious(isSwap)

  useEffect(
    function updateInputAmounts() {
      if (!fromToken || !toToken || (!isSwap && !isIncrease)) {
        return
      }

      // reset input values when switching between swap and position tabs
      if (isSwap !== prevIsISwap) {
        setFocusedInput('from')
        setFromTokenInputValue('')
        return
      }

      if (isSwap && swapAmounts) {
        if (focusedInput === 'from') {
          setToTokenInputValue(
            swapAmounts.amountOut.gt(0)
              ? formatAmountFree(swapAmounts.amountOut, toToken.decimals)
              : '',
          )
        } else {
          setFromTokenInputValue(
            swapAmounts.amountIn.gt(0)
              ? formatAmountFree(swapAmounts.amountIn, fromToken.decimals)
              : '',
          )
        }
      }

      if (isIncrease && increaseAmounts) {
        if (focusedInput === 'from') {
          setToTokenInputValue(
            increaseAmounts.indexTokenAmount?.gt(0)
              ? formatAmountFree(
                  increaseAmounts.indexTokenAmount,
                  toToken.decimals,
                )
              : '',
          )
        } else {
          setFromTokenInputValue(
            increaseAmounts.initialCollateralAmount.gt(0)
              ? formatAmountFree(
                  increaseAmounts.initialCollateralAmount,
                  fromToken.decimals,
                )
              : '',
          )
        }
      }
    },
    [
      focusedInput,
      fromToken,
      increaseAmounts,
      isIncrease,
      isSwap,
      prevIsISwap,
      setFromTokenInputValue,
      setToTokenInputValue,
      swapAmounts,
      toToken,
    ],
  )

  const sizeDeltaUsd = increaseAmounts?.sizeDeltaUsd

  useEffect(() => {
    if (!marketInfo || !isIncrease) {
      return
    }
    const longLiquidity = getAvailableUsdLiquidityForPosition(marketInfo, true)
    const shortLiquidity = getAvailableUsdLiquidityForPosition(
      marketInfo,
      false,
    )

    const isOutPositionLiquidity = isLong
      ? longLiquidity.lt(sizeDeltaUsd || 0)
      : shortLiquidity.lt(sizeDeltaUsd || 0)

    updateProp('longLiquidity', longLiquidity)
    updateProp('shortLiquidity', shortLiquidity)
    updateProp('isOutPositionLiquidity', isOutPositionLiquidity)

    // return {
    //   longLiquidity,
    //   shortLiquidity,
    //   isOutPositionLiquidity,
    // }
  }, [sizeDeltaUsd, isIncrease, isLong, marketInfo])

  return null
}

export default AmountsProcessor
