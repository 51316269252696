import tradeboxStore from '@store/tradeboxStore'
import { TradeBoxProps } from '../TradeBox/TradeBox'
import { getIsEquivalentTokens } from 'domain/tokens'

type Props = Pick<TradeBoxProps, 'collateralToken'> & {
  isMarket: boolean
}
const DifferentCollateralWarning = (p: Props) => {
  const { collateralToken, isMarket } = p

  const marketsOptions = tradeboxStore((store) => store.marketsOptions)

  const isOrphanOrder =
    marketsOptions?.collateralWithPosition &&
    collateralToken &&
    !getIsEquivalentTokens(
      marketsOptions.collateralWithPosition,
      collateralToken,
    )

  if (!isOrphanOrder) {
    return null
  }

  if (isMarket) {
    return (
      <div className="Confirmation-box-warning">
        <>
          You have an existing position with{' '}
          {marketsOptions?.collateralWithPosition?.symbol} as collateral. This
          action will not apply for that position.
        </>
      </div>
    )
  }

  return (
    <div className="Confirmation-box-warning">
      <>
        You have an existing position with{' '}
        {marketsOptions?.collateralWithPosition?.symbol} as collateral. This
        Order will not be valid for that Position.
      </>
    </div>
  )
}

export default DifferentCollateralWarning
