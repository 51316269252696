import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import { PositionInfo } from 'domain/synthetics/positions'
import { formatTokenAmountWithUsd } from 'gmx/lib/numbers'

import { TokenData } from 'domain/synthetics/tokens'
import { tradeboxStoreBigNumberSelector } from '@store/tradeboxStore'

type Props = {
  isTrigger: boolean
  existingPosition: PositionInfo | undefined
  collateralToken: TokenData | undefined
}

const ReceiveRow: React.FC<Props> = ({
  isTrigger,
  existingPosition,
  collateralToken,
}) => {
  const receiveUsd = tradeboxStoreBigNumberSelector(
    (store) => store.decreaseAmounts?.receiveUsd,
  )
  const receiveTokenAmount = tradeboxStoreBigNumberSelector(
    (store) => store.decreaseAmounts?.receiveTokenAmount,
  )

  return isTrigger && existingPosition && receiveUsd ? (
    <ExchangeInfoRow
      className="mb-1"
      label={`Receive`}
      value={formatTokenAmountWithUsd(
        receiveTokenAmount,
        receiveUsd,
        collateralToken?.symbol,
        collateralToken?.decimals,
      )}
    />
  ) : null
}

export default ReceiveRow
