import { formatUsd } from '@components/trade/gmx/tradingview/lib/numbers'
import tradeboxStore from '@store/tradeboxStore'
import BuyInputSection from 'components/BuyInputSection/BuyInputSection'
import TokenSelector from 'components/TokenSelector/TokenSelector'
import { Token } from 'config/tokens'
import { formatLeverage } from 'domain/synthetics/positions'
import { AvailableTokenOptions, TradeType } from 'domain/synthetics/trade'
import { memo } from 'react'
import { tradeTypeLabels } from './TradeBox'
import { getLeverageFromOption } from './utils'

type Props = {
  toTokenInputValue: string
  onInputValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  toTokenAddress: string | undefined
  chainId: number
  onSelectTokenAddress: (token: Token) => void
  availableTokenOptions: AvailableTokenOptions
  tradeType: TradeType
  isLeverageEnabled: boolean | undefined
  leverageOption: number | undefined
}

const IncreaseComponent = (p: Props) => {
  const {
    toTokenInputValue,
    onInputValueChange,
    toTokenAddress,
    chainId,
    onSelectTokenAddress,
    availableTokenOptions,
    tradeType,
    isLeverageEnabled,
    leverageOption,
  } = p

  const { infoTokens, sortedIndexTokensWithPoolValue, indexTokens } =
    availableTokenOptions

  const sizeDeltaUsd = tradeboxStore((store) => {
    const sizeDeltaUsd = store.increaseAmounts?.sizeDeltaUsd
    return sizeDeltaUsd?.gt(0)
      ? formatUsd(sizeDeltaUsd, {
          fallbackToZero: true,
        })
      : ''
  })

  const leverageValueToDisplay = tradeboxStore((store) => {
    if (isLeverageEnabled) {
      const leverage = getLeverageFromOption(leverageOption)
      return formatLeverage(leverage)
    }
    const estimatedLeverage = store.increaseAmounts?.estimatedLeverage
    return formatLeverage(estimatedLeverage) || '-'
  })

  return (
    <BuyInputSection
      topLeftLabel={tradeTypeLabels[tradeType!]}
      topLeftValue={sizeDeltaUsd}
      topRightLabel={`Leverage`}
      topRightValue={leverageValueToDisplay}
      inputValue={toTokenInputValue}
      onInputValueChange={onInputValueChange}
      showMaxButton={false}
    >
      {toTokenAddress && (
        <TokenSelector
          label={tradeTypeLabels[tradeType!]}
          chainId={chainId}
          tokenAddress={toTokenAddress}
          onSelectToken={onSelectTokenAddress}
          tokens={indexTokens}
          infoTokens={infoTokens}
          showSymbolImage={true}
          showBalances={false}
          showTokenImgInDropdown={true}
          extendedSortSequence={sortedIndexTokensWithPoolValue}
          inputFieldValue={toTokenInputValue}
        />
      )}
    </BuyInputSection>
  )
}

export default memo(IncreaseComponent)
