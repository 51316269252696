import Tooltip from '@components/shared/Tooltip'
import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import StatsTooltipRow from 'components/StatsTooltip/StatsTooltipRow'
import {
  TokenData,
  TokensRatio,
  convertToTokenAmount,
  getTokensRatioByPrice,
} from 'domain/synthetics/tokens'
import { BigNumber } from 'ethers'

import { USD_DECIMALS } from 'gmx/lib/legacy'
import { formatAmount, formatTokenAmount, formatUsd } from 'gmx/lib/numbers'
import { useMemo } from 'react'

export type Props = {
  maxLiquidityUsd?: BigNumber
  fromToken?: TokenData
  toToken?: TokenData
  markRatio?: TokensRatio
}

export function SwapCard(p: Props) {
  const { fromToken, toToken, maxLiquidityUsd } = p

  const maxLiquidityAmount = convertToTokenAmount(
    maxLiquidityUsd,
    toToken?.decimals,
    toToken?.prices?.maxPrice,
  )

  const ratioStr = useMemo(() => {
    if (!fromToken || !toToken) {
      return '...'
    }

    const markRatio = getTokensRatioByPrice({
      fromToken,
      toToken,
      fromPrice: fromToken.prices.minPrice,
      toPrice: toToken.prices.maxPrice,
    })

    const smallest = markRatio.smallestToken
    const largest = markRatio.largestToken

    return `${formatAmount(markRatio.ratio, USD_DECIMALS, 4)} ${
      smallest.symbol
    } / ${largest.symbol}`
  }, [fromToken, toToken])

  return (
    <div className="space-y-1 pb-16">
      <ExchangeInfoRow
        label={`${fromToken?.symbol} Price`}
        value={
          formatUsd(fromToken?.prices?.minPrice, {
            displayDecimals: fromToken?.priceDecimals,
          }) || '...'
        }
      />
      <ExchangeInfoRow
        label={`${toToken?.symbol} Price`}
        value={
          formatUsd(toToken?.prices?.maxPrice, {
            displayDecimals: toToken?.priceDecimals,
          }) || '...'
        }
      />
      <ExchangeInfoRow
        label={`Available Liquidity`}
        value={
          <Tooltip
            content={
              <div>
                <StatsTooltipRow
                  className="al-swap"
                  label={`Max ${toToken?.symbol} out`}
                  value={[
                    formatTokenAmount(
                      maxLiquidityAmount,
                      toToken?.decimals,
                      toToken?.symbol,
                      {
                        useCommas: true,
                        displayDecimals: 0,
                      },
                    ),
                    `(${formatUsd(maxLiquidityUsd, { displayDecimals: 0 })})`,
                  ]}
                  showDollar={false}
                />
              </div>
            }
          >
            {formatUsd(maxLiquidityUsd) || '...'}
          </Tooltip>
        }
      />

      <ExchangeInfoRow label={`Price`} value={ratioStr} />
    </div>
  )
}
