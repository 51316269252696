import FavoriteMarketButton from '@components/shared/FavoriteMarketButton'
import MarketChange from '@components/shared/MarketChange'
import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import TableRowAccordian from '@components/TableRowAccordian'
import LiveMarketPrice from '@components/trade/gmx/LiveMarketPrice'
import { USD_DECIMALS } from '@components/trade/gmx/MarketHeader'
import { CHART_PERIODS } from '@components/trade/gmx/tradingview/lib/legacy'
import {
  formatAmount,
  formatUsd,
} from '@components/trade/gmx/tradingview/lib/numbers'
import clsx from 'clsx'
import { getFundingFactorPerPeriod } from 'domain/synthetics/fees/utils'
import { use24hPriceDelta } from 'domain/synthetics/tokens/use24PriceDelta'
import { useChainId } from 'gmx/lib/chains'
import { IndexTokenStats } from 'hooks/gmx/useIndexTokenStats'
import { useViewport } from 'hooks/useViewport'
import { keyBy } from 'lodash'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { DEFAULT_FAVORITE_MKTS, FAVORITE_MARKETS_KEY } from 'utils/constants'
import { PriceChart } from './PriceChart'
import { useMarketInfoVolume } from 'hooks/useMarketInfoVolume'
import { getVolumeInfo } from '@components/markets/MarketsTable'

interface PerpMarketsTableProps {
  perpsMarketData: IndexTokenStats[]
}

const PerpMarketsTable = ({ perpsMarketData }: PerpMarketsTableProps) => {
  const { t } = useTranslation(['common', 'trade'])
  const { chainId } = useChainId()
  const { isMobile } = useViewport()

  const { priceDeltas } = use24hPriceDelta(chainId, 'ETH')
  const keyByPriceDeltas = keyBy(priceDeltas, 'tokenSymbol')
  const { marketsVolumeInfos } = useMarketInfoVolume(chainId)

  const keyBymarketsVolumeInfos = keyBy(marketsVolumeInfos, (marketInfo) =>
    marketInfo.marketToken.toLowerCase(),
  )

  const { push } = useRouter()

  return (
    <div className="h-full">
      {isMobile ? (
        <div className="w-full">
          {perpsMarketData.map((market) => {
            const fundingRate = getFundingFactorPerPeriod(
              market.marketsStats[0].marketInfo,
              true,
              CHART_PERIODS['1h'],
            ).mul(100)

            return (
              <div key={market.token.symbol.toString()}>
                <TableRowAccordian
                  VisibleContent={
                    <div className="flex h-16 w-full items-center">
                      <div className="flex flex-1 flex-col gap-1">
                        <span className="text-xxs font-normal text-th-fgd-2">
                          MARKET
                        </span>
                        <div className="flex items-center gap-2">
                          <RenderTokenIcon
                            size="smallest"
                            symbol={market.token.symbol}
                          />
                          <p className="flex flex-col text-sm">
                            <span className="font-bold text-th-fgd-1">
                              {market.token.symbol}
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-1">
                        <div className="flex w-3/5 flex-col gap-1">
                          <span className="text-xxs font-normal text-th-fgd-2">
                            PRICE
                          </span>
                          <div className="text-sm font-medium text-th-fgd-1">
                            $
                            {market?.token.prices.maxPrice &&
                              formatAmount(
                                market?.token.prices.maxPrice,
                                USD_DECIMALS,
                                4,
                                true,
                              )}
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col gap-1">
                          <span className="text-xxs font-normal text-th-fgd-2">
                            24H VOLUME
                          </span>
                          <p className="text-sm font-medium text-th-fgd-1">-</p>
                        </div>
                      </div>
                    </div>
                  }
                  HiddenContent={
                    <div className="flex flex-col gap-4 pb-4">
                      <div className="flex items-start justify-between py-3">
                        <div className="flex flex-1 flex-col">
                          <span className="text-xxs font-normal text-th-fgd-2">
                            1H AVG. FUNDING RATE
                          </span>
                          <div className="text-sm font-medium text-th-fgd-1">
                            {fundingRate && fundingRate?.gt(0) ? '+' : '-'}
                            {fundingRate &&
                              formatAmount(fundingRate.abs(), 30, 4)}
                            %
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col">
                          <span className="text-xxs font-normal text-th-fgd-2">
                            OPEN INTEREST
                          </span>
                          <p className="flex items-center gap-2 text-sm font-medium text-th-fgd-1">
                            <span className="text-th-success">
                              {market &&
                                formatUsd(
                                  market.marketsStats[0].marketInfo
                                    .longInterestUsd,
                                  {
                                    displayDecimals: 0,
                                  },
                                )}
                            </span>
                            <span className="text-th-error">
                              {market &&
                                formatUsd(
                                  market.marketsStats[0].marketInfo
                                    .shortInterestUsd,
                                  {
                                    displayDecimals: 0,
                                  },
                                )}
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col">
                        <span className="text-xxs font-normal text-th-fgd-2">
                          24H CHANGE
                        </span>

                        <div className="flex items-center gap-1">
                          <MarketChange
                            deltaPercentage={
                              keyByPriceDeltas[market.token.symbol]
                                ?.deltaPercentage
                            }
                            size="small"
                          />
                          <div className="h-[28px] w-24">
                            <PriceChart market={market} />
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                  handleOnClick={() =>
                    push({
                      pathname: '/trade',
                      query: {
                        symbol: market.token.symbol,
                      },
                    })
                  }
                />
              </div>
            )
          })}
        </div>
      ) : (
        <table className="h-full w-full border-collapse text-left">
          <thead className="table w-full table-fixed">
            <tr className="h-10 border-b border-th-input-border text-xs text-th-fgd-2">
              <th className="pl-6 font-normal uppercase">{t('market')}</th>
              <th className="font-normal uppercase" align="left">
                {t('price')}
              </th>
              <th className="font-normal uppercase" align="left">
                {t('rolling-change')}
              </th>
              <th className="font-normal uppercase" align="left">
                {t('trade:24h-volume')}
              </th>
              <th className="font-normal uppercase" align="left">
                {t('trade:funding-rate')}
              </th>
              <th className="font-normal uppercase" align="center">
                {t('trade:open-interest')}
              </th>
              <th className="w-16" />
            </tr>
          </thead>
          <tbody className="block h-[calc(100%-40px)] overflow-y-scroll">
            {perpsMarketData.map((market, idx) => {
              const fundingRate = getFundingFactorPerPeriod(
                market.marketsStats[0].marketInfo,
                true,
                CHART_PERIODS['1h'],
              ).mul(100)

              return (
                <tr
                  key={market.token.symbol.toString() + idx}
                  className="gmlist-row table h-12 w-full table-fixed cursor-pointer transition-all duration-150"
                  onClick={() =>
                    push({
                      pathname: '/trade',
                      query: {
                        symbol: market.token.symbol,
                      },
                    })
                  }
                >
                  <td className="pl-6">
                    <div className="flex items-center gap-2">
                      <div className="min-w-fit">
                        <RenderTokenIcon symbol={market.token.symbol} />
                      </div>

                      <p className="text-sm font-bold text-th-fgd-1">
                        {market.token.symbol}
                      </p>
                    </div>
                  </td>
                  <td
                    className="text-sm font-medium text-th-fgd-1"
                    align="left"
                  >
                    <LiveMarketPrice
                      basePythId={market?.token.baseId}
                      quotePythId={market?.token.pythId || ''}
                      tokenAddress={market?.token.address}
                      textContentOnly={true}
                    />
                  </td>
                  <td align="left">
                    <MarketChange
                      deltaPercentage={
                        keyByPriceDeltas[market.token.symbol]?.deltaPercentage
                      }
                    />
                  </td>
                  <td align="left">
                    $
                    {getVolumeInfo(
                      market.marketsStats[0].marketInfo.marketTokenAddress.toLowerCase(),
                      keyBymarketsVolumeInfos,
                    )}
                  </td>
                  <td align="left">
                    <div
                      className={clsx(
                        'flex items-start justify-start',
                        fundingRate.eq(0)
                          ? 'text-th-fgd-2'
                          : fundingRate.gt(0)
                            ? 'text-th-up'
                            : 'text-th-down',
                      )}
                    >
                      <span>
                        {fundingRate && fundingRate?.gt(0) ? '+' : '-'}
                      </span>
                      <span>
                        {fundingRate && formatAmount(fundingRate.abs(), 30, 4)}%
                      </span>
                    </div>
                  </td>
                  <td>
                    <div className="flex w-full gap-2">
                      <div className="flex-1 text-end text-th-success">
                        {market &&
                          formatUsd(
                            market.marketsStats[0].marketInfo.longInterestUsd,
                            {
                              displayDecimals: 0,
                            },
                          )}
                      </div>
                      <div className="flex-1 text-start text-th-error">
                        {market &&
                          formatUsd(
                            market.marketsStats[0].marketInfo.shortInterestUsd,
                            {
                              displayDecimals: 0,
                            },
                          )}
                      </div>
                    </div>
                  </td>
                  <td className="w-16">
                    <div
                      className="flex items-center justify-center"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <FavoriteMarketButton
                        market={market.token.symbol}
                        localStorageKey={FAVORITE_MARKETS_KEY}
                        DefaultLocalStorageValue={DEFAULT_FAVORITE_MKTS}
                      />
                    </div>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
    </div>
  )
}

export default PerpMarketsTable
