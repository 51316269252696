import WETH from 'abis/WETH.json'
import { NATIVE_TOKEN_ADDRESS, getToken, getWrappedToken } from 'config/tokens'
import { BigNumber, Signer, ethers } from 'ethers'
import { getGasLimit } from 'gmx/lib/contracts'
import { formatTokenAmount } from 'gmx/lib/numbers'
import {
  PendingTransaction,
  SendPaymasterTransactionFn,
} from 'hooks/usePaymaster'

type WrapOrUnwrapParams = {
  account: string
  amount: BigNumber
  isWrap: boolean
  setPendingTxns: (txns: PendingTransaction[]) => void
}

export async function createWrapOrUnwrapTxn(
  chainId: number,
  signer: Signer,
  sendPaymasterTransaction: SendPaymasterTransactionFn,
  p: WrapOrUnwrapParams,
) {
  const wrappedToken = getWrappedToken(chainId)
  const nativeToken = getToken(chainId, NATIVE_TOKEN_ADDRESS)

  const contract = new ethers.Contract(wrappedToken.address, WETH.abi, signer)

  let call: any = {}
  let gasLimit: BigNumber | 8000000 = BigNumber.from('0')
  let messageOpts = {}

  if (p.isWrap) {
    messageOpts = {
      sentMsg: `Swap submitted.`,
      successMsg: `Swapped ${formatTokenAmount(
        p.amount,
        nativeToken.decimals,
        nativeToken.symbol,
      )} for ${formatTokenAmount(
        p.amount,
        wrappedToken.decimals,
        wrappedToken.symbol,
      )}`,
      failMsg: `Swap failed.`,
    }

    gasLimit = await getGasLimit(contract, 'multicall', [])

    call = {
      gas: BigInt(gasLimit.toString()),
      value: p.amount,
    }
  } else {
    gasLimit = await getGasLimit(contract, 'withdraw', [p.amount])

    call = {
      gas: BigInt(gasLimit.toString()),
    }

    messageOpts = {
      sentMsg: `Swap submitted.`,
      successMsg: `Swapped ${formatTokenAmount(
        p.amount,
        wrappedToken.decimals,
        wrappedToken.symbol,
      )} for ${formatTokenAmount(
        p.amount,
        nativeToken.decimals,
        nativeToken.symbol,
      )}`,
      failMsg: `Swap failed.`,
    }
  }

  return sendPaymasterTransaction({
    call,
    account: p.account,
    messageOpts,
    setPendingTxns: p.setPendingTxns,
    router: contract,
    payload: [p.amount],
    method: 'withdraw',
  })
}
