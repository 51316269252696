import { CHART_PERIODS } from '@components/trade/gmx/tradingview/lib/legacy'
import commonStore from '@store/commonStore'
import tradeboxStore from '@store/tradeboxStore'
import {
  getBorrowingFactorPerPeriod,
  getFundingFactorPerPeriod,
} from 'domain/synthetics/fees'
import {
  MarketInfo,
  getAvailableUsdLiquidityForPosition,
  getMaxOpenInterestUsd,
  getMaxReservedUsd,
  getOpenInterestUsd,
  getReservedUsd,
} from 'domain/synthetics/markets'
import { parseContractPrice } from 'domain/synthetics/tokens'
import { BigNumber } from 'ethers'
import useTokenPrice from 'hooks/gmx/usePyth/useTokenPrice'
import { useEffect } from 'react'
import { TradeBoxProps } from '../TradeBox/TradeBox'

type Props = Pick<TradeBoxProps, 'marketInfo'> & {
  isLong: boolean
}

export type MarketCardInfo = {
  longLiquidity: BigNumber
  shortLiquidity: BigNumber
  maxReservedUsd: BigNumber
  reservedUsd: BigNumber
  borrowingRate: BigNumber
  fundingRateLong: BigNumber
  fundingRateShort: BigNumber
  totalInterestUsd: BigNumber
  priceDecimals: number | undefined
  currentOpenInterest: BigNumber
  maxOpenInterest: BigNumber
}

const MarketCardPricesUpdater = (p: Props) => {
  const { marketInfo, isLong } = p
  const { indexToken, longToken, shortToken } = marketInfo || {}
  const prices = commonStore((store) => store.prices)
  const indexTokenPrice = useTokenPrice(
    indexToken?.pythId || '',
    indexToken?.quoteAddress || indexToken?.address || '',
    'minPrice',
  )

  const XbyYPrice =
    indexToken?.baseId &&
    indexToken?.baseAddress &&
    prices[indexToken?.baseAddress]

  const longTokenPrice = useTokenPrice(
    longToken?.pythId || '',
    longToken?.address || '',
    'minPrice',
  )
  const shortTokenPrice = useTokenPrice(
    shortToken?.pythId || '',
    shortToken?.address || '',
    'minPrice',
  )
  const updateProp = tradeboxStore((store) => store.updateProp)

  useEffect(
    function updateMarketCardInfo() {
      if (!marketInfo) {
        return
      }

      const updatedMarketInfoWithPrices: MarketInfo = {
        ...marketInfo,
        indexToken: {
          ...marketInfo.indexToken,
          prices: {
            minPrice: XbyYPrice
              ? parseContractPrice(BigNumber.from(1), 30)
                  .mul(indexTokenPrice)
                  .div(XbyYPrice.maxPrice)
              : indexTokenPrice || BigNumber.from(0),
            maxPrice: XbyYPrice
              ? parseContractPrice(BigNumber.from(1), 30)
                  .mul(indexTokenPrice)
                  .div(XbyYPrice.maxPrice)
              : indexTokenPrice || BigNumber.from(0),
          },
        },
        longToken: {
          ...marketInfo.longToken,
          prices: {
            minPrice: longTokenPrice,
            maxPrice: longTokenPrice,
          },
        },
        shortToken: {
          ...marketInfo.shortToken,
          prices: {
            minPrice: shortTokenPrice,
            maxPrice: shortTokenPrice,
          },
        },
      }
      const marketCardInfo: MarketCardInfo = {
        longLiquidity: getAvailableUsdLiquidityForPosition(
          updatedMarketInfoWithPrices,
          true,
        ),
        shortLiquidity: getAvailableUsdLiquidityForPosition(
          updatedMarketInfoWithPrices,
          false,
        ),
        maxReservedUsd: getMaxReservedUsd(updatedMarketInfoWithPrices, isLong),
        reservedUsd: getReservedUsd(updatedMarketInfoWithPrices, isLong),
        borrowingRate: getBorrowingFactorPerPeriod(
          updatedMarketInfoWithPrices,
          isLong,
          CHART_PERIODS['1h'],
        ).mul(100),
        fundingRateLong: getFundingFactorPerPeriod(
          updatedMarketInfoWithPrices,
          true,
          CHART_PERIODS['1h'],
        ).mul(100),
        fundingRateShort: getFundingFactorPerPeriod(
          updatedMarketInfoWithPrices,
          false,
          CHART_PERIODS['1h'],
        ).mul(100),
        currentOpenInterest: getOpenInterestUsd(
          updatedMarketInfoWithPrices,
          isLong,
        ),
        totalInterestUsd: updatedMarketInfoWithPrices.longInterestUsd.add(
          updatedMarketInfoWithPrices.shortInterestUsd,
        ),
        priceDecimals: updatedMarketInfoWithPrices.indexToken.priceDecimals,
        maxOpenInterest: getMaxOpenInterestUsd(
          updatedMarketInfoWithPrices,
          isLong,
        ),
      }

      updateProp('marketCardInfo', marketCardInfo)
    },
    [
      marketInfo,
      isLong,
      indexTokenPrice,
      longTokenPrice,
      shortTokenPrice,
      updateProp,
    ],
  )

  return null
}

export default MarketCardPricesUpdater
