import Tooltip from '@components/shared/Tooltip'
import LiveMarketPrice from '@components/trade/gmx/LiveMarketPrice'
import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import { MarketInfo, getMarketIndexName } from 'domain/synthetics/markets'
import { formatUsd } from 'gmx/lib/numbers'
import { PriceType } from 'hooks/gmx/usePyth/useTokenPrice'
import { memo, useCallback } from 'react'
import AvailableLiquidityRow from './AvailableLiquidityRow'
import BorrowFeeRow from './BorrowFeeRow'
import FundingFeeRow from './FundingFeeRow'
import OpenInterestBalanceRow from './OpenInterestBalanceRow'

export type Props = {
  marketInfo?: MarketInfo
  allowedSlippage?: number
  isLong: boolean
  isIncrease: boolean
}

function MarketCard({ marketInfo, allowedSlippage, isLong }: Props) {
  const { indexToken } = marketInfo || {}

  const indexName = marketInfo && getMarketIndexName(marketInfo)
  const priceDecimals = marketInfo?.indexToken.priceDecimals

  const LivePriceComponent = useCallback(
    ({ priceType }: { priceType: PriceType }) => {
      return indexToken?.pythId ? (
        <LiveMarketPrice
          formatter={(price) =>
            formatUsd(price, { displayDecimals: priceDecimals }) || '...'
          }
          basePythId={indexToken.baseId}
          quotePythId={indexToken.pythId}
          tokenAddress={indexToken.address}
          priceType={priceType}
          className="text-right"
        />
      ) : (
        '...'
      )
    },
    [
      indexToken?.pythId,
      indexToken?.baseId,
      indexToken?.address,
      priceDecimals,
    ],
  )
  // priceType={isLong ? 'maxPrice' : 'minPrice'}

  const entryPriceType = isLong ? 'maxPrice' : 'minPrice',
    exitPriceType = isLong ? 'minPrice' : 'maxPrice'

  return (
    <div className="mx-4 md:mx-4">
      <div className="space-y-1">
        <ExchangeInfoRow
          label={`Market`}
          value={
            <div className="items-top">
              <span>{indexName && indexName}</span>
              {/* <span className="subtext text-th-fgd-3">
                {poolName && `[${poolName}]`}
              </span> */}
            </div>
          }
        />
        <ExchangeInfoRow
          label="Entry Price"
          value={
            <Tooltip
              placement="top"
              content={
                <>
                  The position will be opened at a reference price of{' '}
                  <LivePriceComponent priceType={entryPriceType} />, not
                  accounting for price impact, with a max slippage of{' '}
                  {allowedSlippage
                    ? (allowedSlippage / 100.0).toFixed(2)
                    : '...'}
                  %.
                  <br />
                  <br />
                  The slippage amount can be configured under Settings, found by
                  clicking on your address at the top right of the page after
                  connecting your wallet.
                  <br />
                  <br />
                  {/* <ExternalLink href="https://docs.gmx.io/docs/trading/v2#opening-a-position">
                    More Info
                  </ExternalLink> */}
                </>
              }
            >
              <LivePriceComponent priceType={entryPriceType} />
              {/* {formatUsd(entryPrice, { displayDecimals: priceDecimals }) || '...'} */}
            </Tooltip>
          }
        />

        <ExchangeInfoRow
          label={`Exit Price`}
          value={
            <Tooltip
              placement="top"
              content={
                <>
                  If you have an existing position, the position will be closed
                  at a reference price of{' '}
                  <LivePriceComponent priceType={entryPriceType} />, not
                  accounting for price impact.
                  <br />
                  <br />
                  This exit price will change with the price of the asset.
                  <br />
                  <br />
                  {/* <ExternalLink href="https://docs.gmx.io/docs/trading/v2#opening-a-position">
                    More Info
                  </ExternalLink> */}
                </>
              }
            >
              <LivePriceComponent priceType={exitPriceType} />
              {/* {formatUsd(exitPrice, {
                displayDecimals: priceDecimals,
              }) || '...'} */}
            </Tooltip>
          }
        />

        <BorrowFeeRow />

        <FundingFeeRow isLong={isLong} marketInfo={marketInfo} />

        <AvailableLiquidityRow
          isLong={isLong}
          indexTokenSymbol={indexToken?.symbol}
        />

        <OpenInterestBalanceRow marketInfo={marketInfo} />

        <div className="h-8" />
      </div>
    </div>
  )
}

export default memo(MarketCard)
