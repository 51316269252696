import {
  formatTokenAmount,
  formatUsd,
} from '@components/trade/gmx/tradingview/lib/numbers'
import tradeboxStore from '@store/tradeboxStore'
import BuyInputSection from 'components/BuyInputSection/BuyInputSection'
import TokenSelector from 'components/TokenSelector/TokenSelector'
import { Token } from 'config/tokens'
import { TokenData } from 'domain/synthetics/tokens'
import { AvailableTokenOptions } from 'domain/synthetics/trade'
import { memo } from 'react'

type Props = {
  toToken: TokenData | undefined
  toTokenInputValue: string
  onInputValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  toTokenAddress: string | undefined
  chainId: number
  onSelectTokenAddress: (token: Token) => void
  availableTokenOptions: AvailableTokenOptions
}

const SwapComponent = (p: Props) => {
  const {
    toToken,
    toTokenInputValue,
    onInputValueChange,
    toTokenAddress,
    chainId,
    onSelectTokenAddress,
    availableTokenOptions,
  } = p

  const { swapTokens, infoTokens, sortedLongAndShortTokens } =
    availableTokenOptions

  const usdOut = tradeboxStore((store) => {
    const usdOut = store.swapAmounts?.usdOut
    return usdOut?.gt(0) ? formatUsd(usdOut) : ''
  })

  return (
    <BuyInputSection
      topLeftLabel={`Receive`}
      topLeftValue={usdOut}
      topRightLabel={`Balance`}
      topRightValue={formatTokenAmount(
        toToken?.balance,
        toToken?.decimals,
        '',
        {
          useCommas: true,
        },
      )}
      inputValue={toTokenInputValue}
      onInputValueChange={onInputValueChange}
      showMaxButton={false}
    >
      {toTokenAddress && (
        <TokenSelector
          label={`Receive`}
          chainId={chainId}
          tokenAddress={toTokenAddress}
          onSelectToken={onSelectTokenAddress}
          tokens={swapTokens}
          infoTokens={infoTokens}
          showSymbolImage={true}
          showBalances={true}
          showTokenImgInDropdown={true}
          extendedSortSequence={sortedLongAndShortTokens}
          inputFieldValue={toTokenInputValue}
        />
      )}
    </BuyInputSection>
  )
}

export default memo(SwapComponent)
