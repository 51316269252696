import RenderSwapOnlyTokens from '@components/shared/RenderSwapOnlyTokens'
import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import cx from 'classnames'
import SearchInput from 'components/SearchInput/SearchInput'
import { CLOUD_FRONT_URL } from 'config/constants'
import {
  MarketInfo,
  MarketsInfoData,
  getMarketIndexName,
  getMarketPoolName,
} from 'domain/synthetics/markets'
import { TokensData, convertToUsd } from 'domain/synthetics/tokens'
import { BigNumber } from 'ethers'
import { formatTokenAmount, formatUsd } from 'gmx/lib/numbers'
import { getByKey } from 'gmx/lib/objects'
import { useViewport } from 'hooks/useViewport'
import Image from 'next/image'
import { useRouter } from 'next/router'
import ArrowDown from 'public/icons/arrow-down.svg'
import CloseIcon from 'public/icons/close-icon-modal.svg'
import { KeyboardEvent, useMemo, useState } from 'react'
import { isMarketDisabled } from 'utils/markets'
import TooltipWithPortal from '../Tooltip/TooltipWithPortal'

type Props = {
  label?: string
  className?: string
  selectedMarketAddress?: string
  selectedIndexName?: string
  markets: MarketInfo[]
  marketsInfoData?: MarketsInfoData
  marketTokensData?: TokensData
  disabled?: boolean
  showBalances?: boolean
  isSideMenu?: boolean
  getMarketState?: (market: MarketInfo) => MarketState | undefined
  onSelectMarket: (market: MarketInfo) => void
  showAllPools?: boolean
  showIndexIcon?: boolean
  inputFeildValue?: string
}

type MarketState = {
  disabled?: boolean
  message?: string
}

type MarketOption = {
  indexName: string
  poolName: string
  name: string
  marketInfo: MarketInfo
  balance: BigNumber
  balanceUsd: BigNumber
  state?: MarketState
}

export function PoolSelector({
  selectedMarketAddress,
  selectedIndexName,
  label,
  markets,
  marketTokensData,
  showBalances,
  onSelectMarket,
  getMarketState,
  showAllPools = false,
  showIndexIcon = false,
  inputFeildValue,
}: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')
  const router = useRouter()
  const { isMobile } = useViewport()

  const marketsOptions: MarketOption[] = useMemo(() => {
    const allMarkets = markets
      .filter(
        (market) =>
          !isMarketDisabled(market) &&
          (showAllPools || getMarketIndexName(market) === selectedIndexName),
      )
      .map((marketInfo) => {
        const indexName = getMarketIndexName(marketInfo)
        const poolName = getMarketPoolName(marketInfo)
        const marketToken = getByKey(
          marketTokensData,
          marketInfo.marketTokenAddress,
        )
        const gmBalance = marketToken?.balance
        const gmBalanceUsd = convertToUsd(
          marketToken?.balance,
          marketToken?.decimals,
          marketToken?.prices.minPrice,
        )
        const state = getMarketState?.(marketInfo)

        return {
          indexName,
          poolName,
          name: marketInfo.name,
          marketInfo,
          balance: gmBalance || BigNumber.from(0),
          balanceUsd: gmBalanceUsd || BigNumber.from(0),
          state,
        }
      })
    const marketsWithBalance: MarketOption[] = []
    const marketsWithoutBalance: MarketOption[] = []

    for (const market of allMarkets) {
      if (market.balance.gt(0)) {
        marketsWithBalance.push(market)
      } else {
        marketsWithoutBalance.push(market)
      }
    }

    const sortedMartketsWithBalance = marketsWithBalance.sort((a, b) => {
      return b.balanceUsd?.gt(a.balanceUsd || 0) ? 1 : -1
    })

    return [...sortedMartketsWithBalance, ...marketsWithoutBalance]
  }, [
    getMarketState,
    marketTokensData,
    markets,
    selectedIndexName,
    showAllPools,
  ])

  const marketInfo = marketsOptions.find(
    (option) => option.marketInfo.marketTokenAddress === selectedMarketAddress,
  )?.marketInfo

  const poolName = marketsOptions.find(
    (option) => option.marketInfo.marketTokenAddress === selectedMarketAddress,
  )?.poolName

  const lowercaseSearchKeyword = searchKeyword.toLowerCase()
  const filteredOptions = marketsOptions.filter((option) => {
    const name = option.name.toLowerCase()
    return name.includes(lowercaseSearchKeyword)
  })

  function onSelectOption(option: MarketOption) {
    onSelectMarket(option.marketInfo)
    router.push({
      pathname: '/earn',
      search: `?market=${option.marketInfo.marketTokenAddress}`,
    })
    setIsModalVisible(false)
  }

  const _handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && filteredOptions.length > 0) {
      onSelectOption(filteredOptions[0])
    }
  }

  function displayPoolLabel(marketInfo: MarketInfo | undefined) {
    if (!marketInfo) {
      return '...'
    }
    const name = showAllPools
      ? `RM: ${getMarketIndexName(marketInfo)}`
      : getMarketPoolName(marketInfo)

    if (filteredOptions?.length > 1) {
      return (
        <div
          className="flex cursor-pointer items-center gap-1 text-right"
          onClick={() => setIsModalVisible(true)}
        >
          {name ? name : '...'}
          <ArrowDown />
        </div>
      )
    }

    return <div>{name ? name : '...'}</div>
  }

  return (
    <div>
      {isModalVisible && (
        <>
          <div
            className="fixed inset-0 z-40 block bg-black/40 backdrop-blur-[5px] sm:hidden"
            onClick={() => setIsModalVisible(false)}
          />
          <div
            className="fixed left-1/2 top-1/2 z-50 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col rounded-md border border-th-input-border px-4 sm:absolute sm:left-0 sm:top-0 sm:z-30 sm:h-full sm:translate-x-0 sm:translate-y-0 sm:border-none sm:px-6"
            style={{
              height: isMobile ? '588px' : '100%',
              width: isMobile ? '361px' : '100%',
            }}
          >
            <div className="glassmorphism-bg rounded-md sm:rounded-none" />
            <div className="glassmorphism rounded-md sm:rounded-none" />
            <Image
              alt=""
              className="pointer-events-none absolute rounded-md sm:rounded-none"
              src={CLOUD_FRONT_URL + '/images/bg-noise-swap.png'}
              layout="fill"
            />
            <div className="relative z-30 pt-6">
              <div className="mb-4 flex flex-col border-b border-th-input-border-secondary pb-4">
                {!isMobile && (
                  <div
                    className="cursor-pointer self-end"
                    onClick={() => setIsModalVisible(false)}
                  >
                    <CloseIcon />
                  </div>
                )}
                <div className="text-base font-bold text-th-fgd-1 sm:text-2xl">
                  {label}
                </div>
              </div>
              <SearchInput
                className="mt-md mb-3 h-10 sm:h-11"
                inputBoxClasses="h-11"
                value={searchKeyword}
                setValue={(e) => setSearchKeyword(e.target.value)}
                placeholder={`Search Pool`}
                onKeyDown={_handleKeyDown}
              />
            </div>
            <div className="TokenSelector-tokens hide-scroll flex flex-col overflow-y-auto">
              {filteredOptions.map((option, marketIndex) => {
                const {
                  marketInfo,
                  balance,
                  balanceUsd,
                  indexName,
                  poolName,
                  name,
                  state = {},
                } = option

                const marketToken = getByKey(
                  marketTokensData,
                  marketInfo.marketTokenAddress,
                )

                return (
                  <div
                    key={name}
                    className={cx('TokenSelector-token-row', {
                      disabled: state.disabled,
                    })}
                    onClick={() => !state.disabled && onSelectOption(option)}
                  >
                    {state.disabled && state.message && (
                      <TooltipWithPortal
                        className="TokenSelector-tooltip"
                        handle={
                          <div className="TokenSelector-tooltip-backing" />
                        }
                        position={
                          marketIndex < filteredOptions.length / 2
                            ? 'center-bottom'
                            : 'center-top'
                        }
                        disableHandleStyle
                        closeOnDoubleClick
                        fitHandleWidth
                        renderContent={() => state.message}
                      />
                    )}
                    <div className="Token-info">
                      <div>
                        {showAllPools ? (
                          <div>
                            {marketInfo.isSpotOnly ? (
                              <RenderSwapOnlyTokens
                                indexTokenSymbol={indexName}
                                marketPoolName={poolName}
                                size={22}
                              />
                            ) : (
                              <RenderTokenIcon
                                symbol={marketInfo.indexToken.symbol}
                                size="largest"
                              />
                            )}
                          </div>
                        ) : (
                          <div className="flex">
                            <RenderTokenIcon
                              symbol={marketInfo.longToken.symbol}
                            />

                            {marketInfo.shortToken.symbol && (
                              <div className="-ml-1 mt-3">
                                <RenderTokenIcon
                                  symbol={marketInfo.shortToken.symbol}
                                />
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="Token-symbol">
                        <div className="Token-text">
                          {showAllPools ? (
                            <div className="lh-1 flex flex-col items-start">
                              <span className="text-sm font-semibold text-th-fgd-1">
                                {indexName && indexName}
                              </span>
                              <span className="subtext text-sm font-normal text-th-fgd-3">
                                {poolName && `[${poolName}]`}
                              </span>
                            </div>
                          ) : (
                            <div className="Token-text text-sm font-semibold text-th-fgd-1">
                              {poolName}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="Token-balance !text-sm">
                      {showBalances && balance && (
                        <div className="Token-text !text-sm">
                          {balance.gt(0) &&
                            formatTokenAmount(
                              balance,
                              marketToken?.decimals,
                              'RM',
                              {
                                useCommas: true,
                              },
                            )}
                          {balance.eq(0) && '-'}
                        </div>
                      )}
                      <span className="text-accent !text-sm">
                        {showBalances && balanceUsd && balanceUsd.gt(0) && (
                          <div>{formatUsd(balanceUsd)}</div>
                        )}
                      </span>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </>
      )}

      {marketInfo && (
        <div className="flex items-center gap-1">
          {showIndexIcon &&
            (marketInfo.isSpotOnly ? (
              <RenderSwapOnlyTokens
                marketPoolName={poolName}
                indexTokenSymbol="swap"
                size={20}
                state={inputFeildValue ? 'active' : 'default'}
              />
            ) : (
              <RenderTokenIcon
                symbol={marketInfo.indexToken.symbol}
                size="small"
                state={inputFeildValue ? 'active' : 'default'}
              />
            ))}
          {displayPoolLabel(marketInfo)}
        </div>
      )}
    </div>
  )
}
