import cx from 'classnames'
import tradeboxStore, {
  tradeboxStoreBigNumberSelector,
} from '@store/tradeboxStore'
import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import { ValueTransition } from 'components/ValueTransition/ValueTransition'
import {
  PositionInfo,
  formatAcceptablePrice,
  formatLeverage,
  formatLiquidationPrice,
} from 'domain/synthetics/positions'
import { formatPercentage, formatUsd } from 'gmx/lib/numbers'
import { TokenData } from 'domain/synthetics/tokens'
import { TradeFlags } from 'domain/synthetics/trade/useTradeFlags'
import Edit from 'public/icons/edit.svg'
import { BigNumber } from 'ethers'
import { applySlippageToPrice } from 'domain/synthetics/trade'

type Props = {
  isLeverageEnabled: boolean | undefined
  leverage: BigNumber
  existingPosition: PositionInfo | undefined
  toToken: TokenData | undefined
  tradeFlags: TradeFlags
  setIsEditingAcceptablePriceImpact: (t: boolean) => void
  acceptablePriceImpactBpsForLimitOrders: BigNumber
  allowedSlippage: number
  isLong: boolean
}

const IncreaseOrderInfo = (p: Props) => {
  const {
    isLeverageEnabled,
    leverage,
    existingPosition,
    toToken,
    tradeFlags,
    setIsEditingAcceptablePriceImpact,
    acceptablePriceImpactBpsForLimitOrders,
    allowedSlippage,
    isLong,
  } = p

  const leverageValueToDisplay = tradeboxStore((store) => {
    if (isLeverageEnabled) {
      return formatLeverage(leverage)
    }
    const estimatedLeverage = store.increaseAmounts?.estimatedLeverage
    return formatLeverage(estimatedLeverage) || '-'
  })

  const formattedNextLeverage = tradeboxStore((store) => {
    const nextLeverage = store.nextPositionValues?.nextLeverage
    return nextLeverage ? formatLeverage(nextLeverage) || '-' : '-'
  })

  const nextEntryPrice = tradeboxStoreBigNumberSelector(
    (store) => store.nextPositionValues?.nextEntryPrice,
  )

  const nextLiqPrice = tradeboxStoreBigNumberSelector(
    (store) => store.nextPositionValues?.nextLiqPrice,
  )

  const sizeDeltaUsd = tradeboxStoreBigNumberSelector(
    (store) => store.increaseAmounts?.sizeDeltaUsd,
  )

  const markPrice = tradeboxStoreBigNumberSelector((store) => store.markPrice)
  const acceptablePriceDeltaBps = tradeboxStoreBigNumberSelector(
    (store) => store.increaseAmounts?.acceptablePriceDeltaBps,
  )
  const acceptablePrice = tradeboxStoreBigNumberSelector(
    (store) => store.increaseAmounts?.acceptablePrice,
  )

  const { isLimit, isMarket } = tradeFlags

  const sizeDeltaGreaterThanZero = sizeDeltaUsd?.gt(0)

  return (
    <div className="mb-1 space-y-1">
      <ExchangeInfoRow
        label={`Leverage`}
        value={
          formattedNextLeverage && sizeDeltaGreaterThanZero ? (
            <ValueTransition
              from={formatLeverage(existingPosition?.leverage)}
              to={formattedNextLeverage}
            />
          ) : (
            leverageValueToDisplay
          )
        }
      />

      <ExchangeInfoRow
        label={`Entry Price`}
        value={
          nextEntryPrice || existingPosition?.entryPrice ? (
            <ValueTransition
              from={formatUsd(existingPosition?.entryPrice, {
                displayDecimals: toToken?.priceDecimals,
              })}
              to={formatUsd(nextEntryPrice, {
                displayDecimals: toToken?.priceDecimals,
              })}
            />
          ) : (
            formatUsd(markPrice, {
              displayDecimals: toToken?.priceDecimals,
            })
          )
        }
      />

      {isMarket && (
        <ExchangeInfoRow
          label={`Price Impact`}
          value={
            <span
              className={cx({
                positive: acceptablePriceDeltaBps?.gt(0),
              })}
            >
              {formatPercentage(acceptablePriceDeltaBps, {
                signed: true,
              }) || '-'}
            </span>
          }
        />
      )}

      {isLimit && (
        <ExchangeInfoRow
          label={`Acceptable Price Impact`}
          value={
            <p
              className="flex cursor-pointer items-center gap-1.5 text-th-fgd-1"
              onClick={() => setIsEditingAcceptablePriceImpact(true)}
            >
              <span>
                {formatPercentage(
                  acceptablePriceImpactBpsForLimitOrders.mul(-1),
                  { signed: true },
                )}
              </span>

              <span className="opacity-70" onClick={() => null}>
                <Edit width={16} height={16} />
              </span>
            </p>
          }
        />
      )}

      <ExchangeInfoRow
        label={`Acceptable Price`}
        value={
          sizeDeltaGreaterThanZero
            ? formatAcceptablePrice(
                applySlippageToPrice(
                  allowedSlippage,
                  acceptablePrice || BigNumber.from(0),
                  true,
                  isLong,
                ),
                {
                  displayDecimals: toToken?.priceDecimals,
                },
              )
            : '-'
        }
      />

      <ExchangeInfoRow
        label={`Liquidation Price`}
        value={
          <ValueTransition
            from={
              existingPosition
                ? formatLiquidationPrice(existingPosition?.liquidationPrice, {
                    displayDecimals:
                      existingPosition?.indexToken?.priceDecimals,
                  })
                : undefined
            }
            to={
              sizeDeltaGreaterThanZero
                ? formatLiquidationPrice(nextLiqPrice, {
                    displayDecimals: toToken?.priceDecimals,
                  })
                : existingPosition
                  ? undefined
                  : '-'
            }
          />
        }
      />
    </div>
  )
}

export default IncreaseOrderInfo
