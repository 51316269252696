import TradeAdvancedPage from '@components/trade/TradeAdvancedPage'
import type { NextPage } from 'next'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'

export async function getStaticProps({ locale }: { locale: string }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'account',
        'close-account',
        'common',
        'notifications',
        'onboarding',
        'onboarding-tours',
        'profile',
        'settings',
        'swap',
        'trade',
        'tv-chart',
        'errors',
      ])),
    },
  }
}

const Trade: NextPage = () => {
  return (
    <div className="max-lg:pb-20">
      <TradeAdvancedPage />
    </div>
  )
}

export default Trade
