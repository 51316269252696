import PerpMarketsTable from '@components/explore/PerpMarketsTable'
import EmptyState from '@components/shared/EmptyState'
import { Tab } from 'components/Common/Tab'
import useIndexTokenStats from 'hooks/gmx/useIndexTokenStats'
import useLocalStorageState from 'hooks/useLocalStorageState'
import { useMemo, useState } from 'react'
import { DEFAULT_FAVORITE_MKTS, FAVORITE_MARKETS_KEY } from 'utils/constants'

export enum Tabs {
  ALL = 'All Markets',
  Favs = 'Favourites',
}

const MarketTable = () => {
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.ALL)
  const indexesData = useIndexTokenStats()

  const [favoriteMarkets] = useLocalStorageState<string[]>(
    FAVORITE_MARKETS_KEY,
    DEFAULT_FAVORITE_MKTS,
  )

  const markets = useMemo(() => {
    return activeTab === Tabs.Favs
      ? indexesData.filter((market) =>
          favoriteMarkets.includes(market.token.symbol),
        )
      : indexesData
  }, [indexesData, activeTab, favoriteMarkets])

  return (
    <div className="h-full">
      <div className="flex h-12 items-center gap-6 border-b border-th-input-border px-6">
        {Object.values(Tabs).map((tab) => (
          <Tab
            key={tab}
            label={tab}
            activeValue={activeTab}
            handleSelect={() => setActiveTab(tab)}
          />
        ))}
      </div>
      <div className="h-[calc(100%-48px)]">
        {markets.length ? (
          <PerpMarketsTable perpsMarketData={markets} />
        ) : (
          <div className="flex h-full w-full items-center justify-center">
            <EmptyState text="No Markets Found" />
          </div>
        )}
      </div>
    </div>
  )
}

export default MarketTable
