import { BigNumber } from 'ethers'
import { BASIS_POINTS_DIVISOR } from 'config/factors'
import { parseValue } from 'gmx/lib/numbers'
import { USD_DECIMALS } from 'gmx/lib/legacy'

export const getLeverageFromOption = (leverageOption: number | undefined) => {
  return BigNumber.from(
    parseInt(String(Number(leverageOption!) * BASIS_POINTS_DIVISOR)),
  )
}

export const getCloseSizeInput = (closeSizeInputValue: string) =>
  parseValue(closeSizeInputValue || '0', USD_DECIMALS)!
