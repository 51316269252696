import Modal from '@components/shared/Modal'
import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import cx from 'classnames'
import SearchInput from 'components/SearchInput/SearchInput'
import {
  MarketInfo,
  MarketsInfoData,
  getMarketIndexName,
} from 'domain/synthetics/markets'
import { TokensData } from 'domain/synthetics/tokens'
import { convertToUsd } from 'domain/synthetics/tokens/utils'
import { BigNumber } from 'ethers'
import { formatTokenAmount, formatUsd } from 'gmx/lib/numbers'
import { getByKey } from 'gmx/lib/objects'
import ArrowDown from 'public/icons/arrow-down.svg'
import { KeyboardEvent, ReactNode, useMemo, useState } from 'react'
import { isMarketDisabled } from 'utils/markets'
import TooltipWithPortal from '../Tooltip/TooltipWithPortal'

type Props = {
  label?: string
  className?: string
  selectedIndexName?: string
  markets: MarketInfo[]
  marketsInfoData?: MarketsInfoData
  marketTokensData?: TokensData
  disabled?: boolean
  showBalances?: boolean
  selectedMarketLabel?: ReactNode | string
  isSideMenu?: boolean
  getMarketState?: (market: MarketInfo) => MarketState | undefined
  onSelectMarket: (indexName: string, market: MarketInfo) => void
}

type MarketState = {
  disabled?: boolean
  message?: string
}

type MarketOption = {
  indexName: string
  marketInfo: MarketInfo
  balance: BigNumber
  balanceUsd: BigNumber
  state?: MarketState
}

export function MarketSelector({
  selectedIndexName,
  selectedMarketLabel,
  label,
  markets,
  marketTokensData,
  showBalances,
  onSelectMarket,
  getMarketState,
}: Props) {
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState('')

  const marketsOptions: MarketOption[] = useMemo(() => {
    const optionsByIndexName: { [indexName: string]: MarketOption } = {}

    markets
      .filter((market) => !isMarketDisabled(market))
      .forEach((marketInfo) => {
        const indexName = getMarketIndexName(marketInfo)
        const marketToken = getByKey(
          marketTokensData,
          marketInfo.marketTokenAddress,
        )

        const gmBalance = marketToken?.balance
        const gmBalanceUsd = convertToUsd(
          marketToken?.balance,
          marketToken?.decimals,
          marketToken?.prices.minPrice,
        )
        const state = getMarketState?.(marketInfo)

        const option = optionsByIndexName[indexName]

        if (option) {
          option.balance = option.balance.add(gmBalance || BigNumber.from(0))
          option.balanceUsd = option.balanceUsd.add(
            gmBalanceUsd || BigNumber.from(0),
          )
        }

        optionsByIndexName[indexName] = optionsByIndexName[indexName] || {
          indexName,
          marketInfo,
          balance: gmBalance || BigNumber.from(0),
          balanceUsd: gmBalanceUsd || BigNumber.from(0),
          state,
        }
      })

    return Object.values(optionsByIndexName)
  }, [getMarketState, marketTokensData, markets])

  const marketInfo = marketsOptions.find(
    (option) => option.indexName === selectedIndexName,
  )?.marketInfo

  const filteredOptions = marketsOptions.filter((option) => {
    return (
      option.indexName.toLowerCase().indexOf(searchKeyword.toLowerCase()) >
        -1 ||
      (!option.marketInfo.isSpotOnly &&
        option.marketInfo.indexToken.name
          .toLowerCase()
          .indexOf(searchKeyword.toLowerCase()) > -1)
    )
  })

  function onSelectOption(option: MarketOption) {
    onSelectMarket(option.indexName, option.marketInfo)
    setIsModalVisible(false)
  }

  const _handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && filteredOptions.length > 0) {
      onSelectOption(filteredOptions[0])
    }
  }

  return (
    <div>
      <Modal
        isOpen={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        title={label}
      >
        <div className="TokenSelector-tokens pt-4">
          <SearchInput
            className="mt-md mb-4 h-11"
            inputBoxClasses="h-11"
            value={searchKeyword}
            setValue={(e) => setSearchKeyword(e.target.value)}
            placeholder={`Search Market`}
            onKeyDown={_handleKeyDown}
          />
          {filteredOptions.map((option, marketIndex) => {
            const {
              marketInfo,
              balance,
              balanceUsd,
              indexName,
              state = {},
            } = option

            const marketToken = getByKey(
              marketTokensData,
              marketInfo.marketTokenAddress,
            )

            return (
              <div
                key={indexName}
                className={cx('TokenSelector-token-row', {
                  disabled: state.disabled,
                })}
                onClick={() => !state.disabled && onSelectOption(option)}
              >
                {state.disabled && state.message && (
                  <TooltipWithPortal
                    className="TokenSelector-tooltip"
                    handle={<div className="TokenSelector-tooltip-backing" />}
                    position={
                      marketIndex < filteredOptions.length / 2
                        ? 'center-bottom'
                        : 'center-top'
                    }
                    disableHandleStyle
                    closeOnDoubleClick
                    fitHandleWidth
                    renderContent={() => state.message}
                  />
                )}
                <div className="Token-info">
                  <RenderTokenIcon
                    symbol={marketInfo.indexToken.symbol}
                    size="largest"
                  />
                  <div className="Token-symbol">
                    <div className="Token-text">{indexName}</div>
                  </div>
                </div>
                <div className="Token-balance">
                  {showBalances && balance && (
                    <div className="Token-text">
                      {balance.gt(0) &&
                        formatTokenAmount(balance, marketToken?.decimals, '', {
                          useCommas: true,
                        })}
                      {balance.eq(0) && '-'}
                    </div>
                  )}
                  <span className="text-accent">
                    {showBalances && balanceUsd && balanceUsd.gt(0) && (
                      <div>{formatUsd(balanceUsd)}</div>
                    )}
                  </span>
                </div>
              </div>
            )
          })}
        </div>
      </Modal>
      {selectedMarketLabel ? (
        <div
          className="flex cursor-pointer items-center gap-1 text-right"
          onClick={() => setIsModalVisible(true)}
        >
          {selectedMarketLabel}
          <ArrowDown />
        </div>
      ) : (
        <div
          className="flex cursor-pointer items-center gap-1 text-right"
          onClick={() => setIsModalVisible(true)}
        >
          {marketInfo ? getMarketIndexName(marketInfo) : '...'}
          <ArrowDown />
        </div>
      )}
    </div>
  )
}
