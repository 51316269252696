import Tooltip from '@components/shared/Tooltip'
import { tradeboxStoreBigNumberSelector } from '@store/tradeboxStore'
import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import { formatAmount } from 'gmx/lib/numbers'
import { useCallback } from 'react'
import { TradeBoxProps } from '../TradeBox/TradeBox'

type Props = Pick<TradeBoxProps, 'marketInfo'> & {
  isLong: boolean
}

const FundingFeeRow = (p: Props) => {
  const { marketInfo, isLong } = p
  const fundingRateLong = tradeboxStoreBigNumberSelector(
    (store) => store.marketCardInfo.fundingRateLong,
  )
  const fundingRateShort = tradeboxStoreBigNumberSelector(
    (store) => store.marketCardInfo.fundingRateShort,
  )

  const renderFundingFeeTooltipContent = useCallback(() => {
    if (!fundingRateLong || !fundingRateShort) {
      return []
    }
    const isMarketWithAdaptiveFundingRate =
      marketInfo?.fundingIncreaseFactorPerSecond.gt(0)

    const isLongPositive = fundingRateLong?.gt(0)
    const long = (
      <>
        Long positions {isLongPositive ? `receive` : `pay`} a Funding Fee of{' '}
        <span className={isLongPositive ? 'text-th-success' : 'text-th-error'}>
          {isLongPositive ? '+' : '-'}
          {formatAmount(fundingRateLong.abs(), 30, 4)}%
        </span>{' '}
        per hour.
      </>
    )

    const isShortPositive = fundingRateShort?.gt(0)
    const short = (
      <>
        Short positions {isShortPositive ? `receive` : `pay`} a Funding Fee of{' '}
        <span className={isShortPositive ? 'text-th-success' : 'text-th-error'}>
          {isShortPositive ? '+' : '-'}
          {formatAmount(fundingRateShort.abs(), 30, 4)}%
        </span>{' '}
        per hour.
      </>
    )

    const [currentFeeElement, oppositeFeeElement] = isLong
      ? [long, short]
      : [short, long]

    return (
      <div>
        {currentFeeElement}
        <br />
        <br />
        {oppositeFeeElement}
        {isMarketWithAdaptiveFundingRate && (
          <>
            <br />
            <br />
            <>
              This market uses an Adaptive Funding Rate. The Funding Rate will
              adjust over time depending on the ratio of longs and shorts.
              {/* <br />
              <br />
              <ExternalLink href="https://docs.gmx.io/docs/trading/v2/#adaptive-funding">
                Read more
              </ExternalLink>
              . */}
            </>
          </>
        )}
      </div>
    )
  }, [fundingRateLong, fundingRateShort, isLong, marketInfo])

  const fundingRate = isLong ? fundingRateLong : fundingRateShort

  return (
    <ExchangeInfoRow
      label={`Funding Fee`}
      value={
        <Tooltip
          className="al-swap"
          placement="top"
          content={renderFundingFeeTooltipContent()}
        >
          {fundingRate
            ? `${fundingRate.gt(0) ? '+' : '-'}${formatAmount(
                fundingRate.abs(),
                30,
                4,
              )}% / 1h`
            : '...'}
        </Tooltip>
      }
    />
  )
}

export default FundingFeeRow
