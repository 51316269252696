import cx from 'classnames'
import { ApproveTokenButton } from 'components/ApproveTokenButton/ApproveTokenButton'
import Checkbox from 'components/Checkbox/Checkbox'
import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import StatsTooltipRow from 'components/StatsTooltip/StatsTooltipRow'
import { ValueTransition } from 'components/ValueTransition/ValueTransition'
import { getContract } from 'config/contracts'
import {
  BASIS_POINTS_DIVISOR,
  DEFAULT_SLIPPAGE_AMOUNT,
  HIGH_SPREAD_THRESHOLD,
} from 'config/factors'
import { useSyntheticsEvents } from 'context/SyntheticsEvents'
import { useUserReferralCode } from 'domain/referrals/hooks'
import {
  ExecutionFee,
  getBorrowingFactorPerPeriod,
  getFundingFactorPerPeriod,
  getIsHighPriceImpact,
} from 'domain/synthetics/fees'
import { MarketInfo } from 'domain/synthetics/markets'
import {
  OrderType,
  OrdersInfoData,
  PositionOrderInfo,
  createDecreaseOrderTxn,
  createIncreaseOrderTxn,
  createSwapOrderTxn,
  isLimitOrderType,
  isOrderForPosition,
  isTriggerDecreaseOrderType,
} from 'domain/synthetics/orders'
import { cancelOrdersTxn } from 'domain/synthetics/orders/cancelOrdersTxn'
import { createWrapOrUnwrapTxn } from 'domain/synthetics/orders/createWrapOrUnwrapTxn'
import {
  PositionInfo,
  formatAcceptablePrice,
  formatLeverage,
  formatLiquidationPrice,
  getPositionKey,
  getTriggerNameByOrderType,
} from 'domain/synthetics/positions'
import {
  TokenData,
  TokensData,
  TokensRatio,
  convertToTokenAmount,
  formatTokensRatio,
  getNeedTokenApprove,
  useTokensAllowanceData,
} from 'domain/synthetics/tokens'
import {
  DecreasePositionAmounts,
  IncreasePositionAmounts,
  NextPositionValues,
  SwapAmounts,
  TradeFees,
  TriggerThresholdType,
  applySlippageToMinOut,
  applySlippageToPrice,
} from 'domain/synthetics/trade'
import { TradeFlags } from 'domain/synthetics/trade/useTradeFlags'
import { getIsEquivalentTokens, getSpread } from 'domain/tokens'
import { BigNumber } from 'ethers'
import { CHART_PERIODS, USD_DECIMALS } from 'gmx/lib/legacy'

import Button from '@components/shared/Button'
import RenderTokenIcon from '@components/shared/RenderTokenIcon'
import Tooltip from '@components/shared/Tooltip'
import { useConnectModal } from '@rainbow-me/rainbowkit'
import tradeboxStore, {
  tradeboxStoreBigNumberSelector,
} from '@store/tradeboxStore'
import clsx from 'clsx'
import SlippageInput, {
  getSlippageText,
} from 'components/SlippageInput/SlippageInput'
import { CLOUD_FRONT_URL } from 'config/constants'
import { useSubaccount } from 'context/SubaccountContext/SubaccountContext'
import { AvailableMarketsOptions } from 'domain/synthetics/trade/useAvailableMarketsOptions'
import { useChainId } from 'gmx/lib/chains'
import { helperToast } from 'gmx/lib/helperToast'
import {
  formatAmount,
  formatDeltaUsd,
  formatPercentage,
  formatTokenAmount,
  formatTokenAmountWithUsd,
  formatUsd,
} from 'gmx/lib/numbers'
import { usePrevious } from 'gmx/lib/usePrevious'
import useWallet from 'gmx/lib/wallets/useWallet'
import { PendingTransaction, usePaymaster } from 'hooks/usePaymaster'
import Image from 'next/image'
import AllowedSlippageIcon from 'public/icons/edit-slippage-icon.svg'
import RefreshIcon from 'public/icons/refresh-icon.svg'
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import { GoArrowRight } from 'react-icons/go'
import { RiArrowLeftRightLine } from 'react-icons/ri'
import { useKey } from 'react-use'
import { TradeFeesRow } from '../TradeFeesRow/TradeFeesRow'
import DifferentCollateralWarning from './DifferentCollateralWarning'

export type Props = {
  isVisible: boolean
  tradeFlags: TradeFlags
  isWrapOrUnwrap: boolean
  fromToken?: TokenData
  toToken?: TokenData
  // markPrice?: BigNumber
  // markRatio?: TokensRatio
  triggerPrice?: BigNumber
  fixedTriggerThresholdType?: TriggerThresholdType
  fixedTriggerOrderType?: OrderType.LimitDecrease | OrderType.StopLossDecrease
  fixedTriggerAcceptablePrice?: BigNumber
  triggerRatio?: TokensRatio
  marketInfo?: MarketInfo
  collateralToken?: TokenData
  swapAmounts?: SwapAmounts
  marketsOptions?: AvailableMarketsOptions
  increaseAmounts?: IncreasePositionAmounts
  decreaseAmounts?: DecreasePositionAmounts
  nextPositionValues?: NextPositionValues
  keepLeverage?: boolean
  swapLiquidityUsd?: BigNumber
  longLiquidityUsd?: BigNumber
  shortLiquidityUsd?: BigNumber
  fees?: TradeFees
  executionFee?: ExecutionFee
  error?: string
  existingPosition?: PositionInfo
  shouldDisableValidation: boolean
  isHigherSlippageAllowed?: boolean
  ordersData?: OrdersInfoData
  tokensData?: TokensData
  setIsHigherSlippageAllowed: (isHigherSlippageAllowed: boolean) => void
  setKeepLeverage: (keepLeverage: boolean) => void
  onClose: () => void
  onSubmitted: () => void
  setPendingTxns: (txns: PendingTransaction[]) => void
  PayComponent?: ReactNode
  IsIncreaseComponent?: ReactNode
  IsSwapComponent?: ReactNode
  fromTokenInputValue?: string | number
  toTokenInputValue?: string | number
  setStage?: Dispatch<SetStateAction<'trade' | 'confirmation' | 'processing'>>
}

export function ConfirmationBox(p: Props) {
  const {
    tradeFlags,
    isWrapOrUnwrap,
    fromToken,
    toToken,
    // markPrice,
    // markRatio,
    triggerPrice,
    fixedTriggerThresholdType,
    fixedTriggerOrderType,
    fixedTriggerAcceptablePrice,
    // triggerRatio,
    marketInfo,
    collateralToken,
    // swapAmounts,
    // increaseAmounts,
    // decreaseAmounts,
    // nextPositionValues,
    swapLiquidityUsd,
    // longLiquidityUsd,
    // shortLiquidityUsd,
    keepLeverage,
    // fees,
    // executionFee,
    // error,
    existingPosition,
    shouldDisableValidation,
    // marketsOptions,
    ordersData,
    tokensData,
    setKeepLeverage,
    onSubmitted,
    setPendingTxns,
    PayComponent,
    // IsIncreaseComponent,
    // IsSwapComponent,
    isVisible,
    fromTokenInputValue,
    toTokenInputValue,
    setStage,
  } = p

  const {
    isLong,
    isShort,
    isPosition,
    isSwap,
    isMarket,
    isLimit,
    isTrigger,
    isIncrease,
  } = tradeFlags
  const { indexToken } = marketInfo || {}
  const { signer, account } = useWallet()
  const { chainId } = useChainId()
  const { openConnectModal } = useConnectModal()
  const { setPendingPosition, setPendingOrder } = useSyntheticsEvents()
  const markPrice = tradeboxStoreBigNumberSelector((store) => store.markPrice)
  const longLiquidityUsd = tradeboxStoreBigNumberSelector(
    (store) => store.longLiquidity,
  )
  const shortLiquidityUsd = tradeboxStoreBigNumberSelector(
    (store) => store.shortLiquidity,
  )
  const markRatio = tradeboxStore((store) => store.markRatio)
  const [
    triggerRatio,
    swapAmounts,
    increaseAmounts,
    decreaseAmounts,
    nextPositionValues,
    fees,
    executionFee,
    error,
  ] = tradeboxStore((store) => [
    store.triggerRatio,
    store.swapAmounts,
    store.increaseAmounts,
    store.decreaseAmounts,
    store.nextPositionValues,
    store.fees,
    store.executionFee,
    store.formError,
  ])

  const prevIsVisible = usePrevious(p.isVisible)

  const { referralCodeForTxn } = useUserReferralCode(signer, chainId, account)

  const [isTriggerWarningAccepted, setIsTriggerWarningAccepted] =
    useState(false)
  const [isHighPriceImpactAccepted, setIsHighPriceImpactAccepted] =
    useState(false)
  const [isLimitOrdersVisible, setIsLimitOrdersVisible] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const allowedSlippage = tradeboxStore((store) => store.slippage)

  const submitButtonRef = useRef<null | HTMLDivElement>(null)

  const [editAllowedSlippage, setEditAllowedSlippage] = useState(false)

  const payAmount = useMemo(() => {
    if (isSwap && !isWrapOrUnwrap) {
      return swapAmounts?.amountIn
    }
    if (isIncrease) {
      return increaseAmounts?.initialCollateralAmount
    }
  }, [
    increaseAmounts?.initialCollateralAmount,
    isIncrease,
    isSwap,
    isWrapOrUnwrap,
    swapAmounts?.amountIn,
  ])

  const { tokensAllowanceData } = useTokensAllowanceData(chainId, {
    spenderAddress: getContract(chainId, 'SyntheticsRouter'),
    tokenAddresses: fromToken ? [fromToken.address] : [],
    skip: !p.isVisible,
  })

  const needPayTokenApproval =
    !isWrapOrUnwrap &&
    tokensAllowanceData &&
    fromToken &&
    payAmount &&
    getNeedTokenApprove(tokensAllowanceData, fromToken.address, payAmount)

  const isHighPriceImpact = getIsHighPriceImpact(
    fees?.positionPriceImpact,
    fees?.swapPriceImpact,
  )

  const positionKey = useMemo(() => {
    if (!account || !marketInfo || !collateralToken) {
      return undefined
    }

    return getPositionKey(
      account,
      marketInfo.marketTokenAddress,
      collateralToken.address,
      isLong,
    )
  }, [account, collateralToken, isLong, marketInfo])

  const positionOrders = useMemo(() => {
    if (!positionKey || !ordersData) {
      return []
    }

    return Object.values(ordersData).filter((order) =>
      isOrderForPosition(order, positionKey),
    ) as PositionOrderInfo[]
  }, [ordersData, positionKey])

  const existingLimitOrders = useMemo(
    () => positionOrders.filter((order) => isLimitOrderType(order.orderType)),
    [positionOrders],
  )

  const existingTriggerOrders = useMemo(
    () =>
      positionOrders.filter((order) =>
        isTriggerDecreaseOrderType(order.orderType),
      ),
    [positionOrders],
  )

  const decreaseOrdersThatWillBeExecuted = useMemo(() => {
    if (!existingPosition || !markPrice) {
      return []
    }

    return existingTriggerOrders.filter((order) => {
      return order.triggerThresholdType === TriggerThresholdType.Above
        ? markPrice.gt(order.triggerPrice)
        : markPrice.lt(order.triggerPrice)
    })
  }, [existingPosition, existingTriggerOrders, markPrice])

  const swapSpreadInfo = useMemo(() => {
    let spread = BigNumber.from(0)

    if (isSwap && fromToken && toToken) {
      const fromSpread = getSpread(fromToken.prices)
      const toSpread = getSpread(toToken.prices)

      spread = fromSpread.add(toSpread)
    } else if (isIncrease && fromToken && indexToken) {
      const fromSpread = getSpread(fromToken.prices)
      const toSpread = getSpread(indexToken.prices)

      spread = fromSpread.add(toSpread)

      if (isLong) {
        spread = fromSpread
      }
    }

    const isHigh = spread.gt(HIGH_SPREAD_THRESHOLD)

    const showSpread = isMarket

    return { spread, showSpread, isHigh }
  }, [isSwap, fromToken, toToken, isIncrease, indexToken, isMarket, isLong])

  const collateralSpreadInfo = useMemo(() => {
    if (!indexToken || !collateralToken) {
      return undefined
    }

    let totalSpread = getSpread(indexToken.prices)

    if (getIsEquivalentTokens(collateralToken, indexToken)) {
      return {
        spread: totalSpread,
        isHigh: totalSpread.gt(HIGH_SPREAD_THRESHOLD),
      }
    }

    totalSpread = totalSpread.add(getSpread(collateralToken!.prices!))

    return {
      spread: totalSpread,
      isHigh: totalSpread.gt(HIGH_SPREAD_THRESHOLD),
    }
  }, [collateralToken, indexToken])

  const submitButtonState = useMemo(() => {
    if (isSubmitting) {
      return {
        text: `Creating Order...`,
        disabled: true,
      }
    }

    if (error) {
      return {
        text: error,
        disabled: true,
      }
    }

    if (needPayTokenApproval) {
      return {
        text: `Pending ${fromToken?.assetSymbol ?? fromToken?.symbol} approval`,
        disabled: true,
      }
    }

    if (isHighPriceImpact && !isHighPriceImpactAccepted) {
      return { text: `Price Impact not yet acknowledged`, disabled: true }
    }

    if (
      isIncrease &&
      decreaseOrdersThatWillBeExecuted.length > 0 &&
      !isTriggerWarningAccepted
    ) {
      return {
        text: `Accept confirmation of trigger orders`,
        disabled: true,
      }
    }

    let text = ''

    if (isMarket) {
      if (isSwap) {
        text = `Swap`
      } else {
        text = isLong ? `Place Long Order` : `Place Short Order`
      }
    } else if (isLimit) {
      text = `Confirm Limit Order`
    } else {
      text = `Confirm ${getTriggerNameByOrderType(fixedTriggerOrderType)} Order`
    }

    return {
      text,
      disabled: false,
    }
  }, [
    decreaseOrdersThatWillBeExecuted.length,
    error,
    fixedTriggerOrderType,
    fromToken?.symbol,
    fromToken?.assetSymbol,
    isHighPriceImpact,
    isHighPriceImpactAccepted,
    isIncrease,
    isLimit,
    isLong,
    isMarket,
    isSubmitting,
    isSwap,
    isTriggerWarningAccepted,
    needPayTokenApproval,
  ])

  useKey(
    'Enter',
    () => {
      if (p.isVisible && !submitButtonState.disabled) {
        submitButtonRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        })
        onSubmit()
      }
    },
    {},
    [p.isVisible, submitButtonState.disabled],
  )

  const { account: address } = useWallet()
  const { sendPaymasterTransaction } = usePaymaster()
  const subaccount = useSubaccount(p.executionFee?.feeTokenAmount ?? null)

  function onCancelOrderClick(key: string): void {
    if (!signer) {
      return
    }
    cancelOrdersTxn(chainId, signer, subaccount, sendPaymasterTransaction, {
      orderKeys: [key],
      setPendingTxns: p.setPendingTxns,
      detailsMsg: '',
      account: address,
    })
  }

  function onSubmitWrapOrUnwrap() {
    if (!account || !swapAmounts || !fromToken || !signer) {
      return Promise.resolve()
    }

    return createWrapOrUnwrapTxn(chainId, signer, sendPaymasterTransaction, {
      amount: swapAmounts.amountIn,
      isWrap: Boolean(fromToken.isNative),
      setPendingTxns,
      account,
    })
  }

  function onSubmitSwap() {
    if (
      !account ||
      !tokensData ||
      !swapAmounts?.swapPathStats ||
      !fromToken ||
      !toToken ||
      !executionFee ||
      !signer ||
      typeof allowedSlippage !== 'number'
    ) {
      helperToast.error(`Error submitting order`)
      return Promise.resolve()
    }

    return createSwapOrderTxn(
      chainId,
      signer,
      subaccount,
      sendPaymasterTransaction,
      {
        account,
        fromTokenAddress: fromToken.address,
        fromTokenAmount: swapAmounts.amountIn,
        swapPath: swapAmounts.swapPathStats?.swapPath,
        toTokenAddress: toToken.address,
        orderType: isLimit ? OrderType.LimitSwap : OrderType.MarketSwap,
        minOutputAmount: swapAmounts.minOutputAmount,
        referralCode: referralCodeForTxn,
        executionFee: executionFee.feeTokenAmount,
        allowedSlippage: allowedSlippage || 50,
        tokensData,
        setPendingTxns,
        setPendingOrder,
      },
    )
  }

  function onSubmitIncreaseOrder() {
    if (
      !tokensData ||
      !account ||
      !fromToken ||
      !collateralToken ||
      !increaseAmounts?.acceptablePrice ||
      !executionFee ||
      !marketInfo ||
      !signer ||
      typeof allowedSlippage !== 'number'
    ) {
      helperToast.error(`Error submitting order`)
      return Promise.resolve()
    }

    return createIncreaseOrderTxn({
      chainId,
      signer,
      subaccount,
      sendPaymasterTransaction,
      createIncreaseOrderParams: {
        account,
        marketAddress: marketInfo.marketTokenAddress,
        initialCollateralAddress: fromToken?.address,
        initialCollateralAmount: increaseAmounts.initialCollateralAmount,
        targetCollateralAddress: collateralToken.address,
        collateralDeltaAmount: increaseAmounts.collateralDeltaAmount,
        swapPath: increaseAmounts.swapPathStats?.swapPath || [],
        sizeDeltaUsd: increaseAmounts.sizeDeltaUsd,
        sizeDeltaInTokens: increaseAmounts.sizeDeltaInTokens,
        triggerPrice: isLimit ? triggerPrice : undefined,
        acceptablePrice: increaseAmounts.acceptablePrice,
        isLong,
        orderType: isLimit ? OrderType.LimitIncrease : OrderType.MarketIncrease,
        executionFee: executionFee.feeTokenAmount,
        allowedSlippage,
        referralCode: referralCodeForTxn,
        indexToken: marketInfo.indexToken,
        tokensData,
        skipSimulation: false,
        setPendingTxns: p.setPendingTxns,
        setPendingOrder,
        setPendingPosition,
      },
    })
  }

  function onSubmitDecreaseOrder() {
    if (
      !account ||
      !marketInfo ||
      !collateralToken ||
      fixedTriggerOrderType === undefined ||
      fixedTriggerThresholdType === undefined ||
      !fixedTriggerAcceptablePrice ||
      !decreaseAmounts?.triggerPrice ||
      !executionFee ||
      !tokensData ||
      !signer ||
      typeof allowedSlippage !== 'number'
    ) {
      helperToast.error(`Error submitting order`)
      return Promise.resolve()
    }

    return createDecreaseOrderTxn(
      chainId,
      signer,
      subaccount,
      sendPaymasterTransaction,
      {
        account,
        marketAddress: marketInfo.marketTokenAddress,
        swapPath: [],
        initialCollateralDeltaAmount: decreaseAmounts.collateralDeltaAmount,
        initialCollateralAddress: collateralToken.address,
        receiveTokenAddress: collateralToken.address,
        triggerPrice: decreaseAmounts.triggerPrice,
        acceptablePrice: fixedTriggerAcceptablePrice,
        sizeDeltaUsd: decreaseAmounts.sizeDeltaUsd,
        sizeDeltaInTokens: decreaseAmounts.sizeDeltaInTokens,
        minOutputUsd: BigNumber.from(0),
        isLong,
        decreasePositionSwapType: decreaseAmounts.decreaseSwapType,
        orderType: fixedTriggerOrderType,
        executionFee: executionFee.feeTokenAmount,
        allowedSlippage: allowedSlippage || 0.3,
        referralCode: referralCodeForTxn,
        skipSimulation: true,
        indexToken: marketInfo.indexToken,
        tokensData,
      },
      {
        setPendingTxns,
        setPendingOrder,
        setPendingPosition,
      },
    )
  }

  function onSubmit() {
    setIsSubmitting(true)

    let txnPromise: Promise<string> | Promise<void>

    if (!account) {
      openConnectModal?.()
      return
    } else if (isWrapOrUnwrap) {
      txnPromise = onSubmitWrapOrUnwrap()
    } else if (isSwap) {
      txnPromise = onSubmitSwap()
    } else if (isIncrease) {
      txnPromise = onSubmitIncreaseOrder()
    } else {
      txnPromise = onSubmitDecreaseOrder()
    }

    if (subaccount) {
      onSubmitted()
      setIsSubmitting(false)

      return
    }

    txnPromise
      .then(() => {
        onSubmitted()
      })
      .finally(() => {
        setIsSubmitting(false)
      })
  }

  useEffect(
    function reset() {
      if (p.isVisible !== prevIsVisible) {
        setIsTriggerWarningAccepted(false)
        setIsHighPriceImpactAccepted(false)
      }
    },
    [p.isVisible, prevIsVisible],
  )

  function renderMain() {
    if (isSwap) {
      return (
        <div className="Confirmation-box-main">
          <div className="flex justify-between pt-4">
            <span
              className="cursor-pointer"
              onClick={() => setStage && setStage('trade')}
            >
              <FaChevronLeft fontSize={16} color="#909096" />
            </span>
            <RefreshIcon />
          </div>
          <div className="flex flex-col items-center justify-center gap-[10px] border-b border-th-input-border py-3">
            <div className="flex items-center gap-1">
              <RenderTokenIcon symbol={fromToken?.symbol} />
              <RenderTokenIcon symbol={toToken?.symbol} />
            </div>
            <div className="flex items-center justify-center gap-2 text-base font-medium text-th-fgd-1">
              <span>
                {fromTokenInputValue}{' '}
                <span className="text-th-fgd-3">{fromToken?.symbol}</span>
              </span>{' '}
              <GoArrowRight fontSize={16} />
              <span>
                {toTokenInputValue}
                <span className="text-th-fgd-3">{toToken?.symbol}</span>
              </span>
            </div>
          </div>
        </div>
      )
    }

    if (isIncrease) {
      return (
        <div className="Confirmation-box-main">
          <div className="flex justify-between pt-4">
            <span
              className="cursor-pointer"
              onClick={() => setStage && setStage('trade')}
            >
              <FaChevronLeft fontSize={16} color="#909096" />
            </span>
          </div>
          <span
            className="mb-3 !text-sm"
            style={{
              fontSize: 14,
            }}
          >
            {PayComponent}
          </span>
        </div>
      )
    }

    if (isTrigger) {
      return (
        <div className="Confirmation-box-main">
          <div className="flex justify-between pb-4">
            <span
              className="cursor-pointer"
              onClick={() => setStage && setStage('trade')}
            >
              <FaChevronLeft fontSize={16} color="#909096" />
            </span>
          </div>
        </div>
      )
    }

    return null
  }

  function renderOrderItem(order: PositionOrderInfo) {
    return (
      <li key={order.key} className="font-sm flex items-center justify-between">
        <p>
          {isLimitOrderType(order.orderType) ? `Increase` : `Decrease`}{' '}
          {order.indexToken?.symbol} {formatUsd(order.sizeDeltaUsd)}{' '}
          {order.isLong ? `Long` : `Short`} &nbsp;
          {order.triggerThresholdType}{' '}
          {formatUsd(order.triggerPrice, {
            displayDecimals: toToken?.priceDecimals,
          })}
        </p>
        <button type="button" onClick={() => onCancelOrderClick(order.key)}>
          <span className="border-b border-th-fgd-1 text-sm font-normal leading-[18px] text-th-button">
            CANCEL
          </span>
        </button>
      </li>
    )
  }

  const longShortText = isLong ? `Long` : `Short`

  function renderDifferentTokensWarning() {
    if (!isPosition || !fromToken || !toToken) {
      return null
    }
    const isCollateralTokenNonStable = !collateralToken?.isStable
    const collateralTokenSymbol =
      collateralToken?.[collateralToken?.isWrapped ? 'baseSymbol' : 'symbol']
    const indexTokenSymbol =
      indexToken?.[indexToken?.isWrapped ? 'baseSymbol' : 'symbol']

    if (
      isCollateralTokenNonStable &&
      collateralTokenSymbol !== indexTokenSymbol
    ) {
      return (
        <div className="Confirmation-box-info">
          <>
            You have selected {collateralTokenSymbol} as Collateral, the
            Liquidation Price will vary based on the price of{' '}
            {collateralTokenSymbol}.
          </>
        </div>
      )
    }

    if (
      isLong &&
      isCollateralTokenNonStable &&
      collateralTokenSymbol === indexTokenSymbol
    ) {
      return (
        <div className="Confirmation-box-info">
          <>
            You have selected {collateralTokenSymbol} as collateral, the
            Liquidation Price is higher compared to using a stablecoin as
            collateral since the worth of the collateral will change with its
            price. If required, you can change the collateral type using the
            Collateral In option in the trade box.
          </>
        </div>
      )
    }

    if (
      isShort &&
      isCollateralTokenNonStable &&
      collateralTokenSymbol === indexTokenSymbol
    ) {
      return (
        <div className="Confirmation-box-info">
          <>
            You have selected {collateralTokenSymbol} as collateral to short{' '}
            {indexTokenSymbol}.
          </>
        </div>
      )
    }
  }

  function renderDifferentCollateralWarning() {
    return (
      <DifferentCollateralWarning
        collateralToken={collateralToken}
        isMarket={isMarket}
      />
    )
  }

  function renderExistingLimitOrdersWarning() {
    if (!existingLimitOrders?.length || !toToken) {
      return
    }

    if (existingLimitOrders.length === 1) {
      const order = existingLimitOrders[0]

      const sizeText = formatUsd(order.sizeDeltaUsd)

      return (
        <div className="Confirmation-box-info">
          <>
            You have an active Limit Order to Increase {longShortText}{' '}
            {order.indexToken?.symbol} {sizeText} at price{' '}
            {formatUsd(order.triggerPrice, {
              displayDecimals: toToken.priceDecimals,
            })}
            .
          </>
        </div>
      )
    } else {
      return (
        <div>
          <div className="Confirmation-box-info">
            <span>
              <>
                You have multiple existing Increase {longShortText}{' '}
                {toToken.symbol} limit orders{' '}
              </>
            </span>
            <span
              onClick={() => setIsLimitOrdersVisible((p) => !p)}
              className="view-orders"
            >
              ({isLimitOrdersVisible ? `hide` : `view`})
            </span>
          </div>
          {isLimitOrdersVisible && (
            <ul className="order-list">
              {existingLimitOrders.map(renderOrderItem)}
            </ul>
          )}
        </div>
      )
    }
  }

  function renderExistingTriggerErrors() {
    if (!decreaseOrdersThatWillBeExecuted?.length) {
      return
    }

    return (
      <>
        <p className="text-sm font-normal text-th-error">
          You have active trigger that might execute immediately after you open
          this position. Please cancel the orders or accept the confirmation to
          continue
        </p>
        <ul className="order-list">
          {decreaseOrdersThatWillBeExecuted.map(renderOrderItem)}
        </ul>
      </>
    )
  }

  function renderExistingTriggerWarning() {
    if (
      !existingTriggerOrders?.length ||
      decreaseOrdersThatWillBeExecuted.length > 0 ||
      renderExistingLimitOrdersWarning()
    ) {
      return
    }

    return (
      <div className="Confirmation-box-info">
        You have an active trigger order that could impact this position.
      </div>
    )
  }

  function renderAvailableLiquidity() {
    const riskThresholdBps = 5000
    let availableLiquidityUsd: BigNumber | undefined = undefined
    let availableLiquidityAmount: BigNumber | undefined = undefined
    let isLiquidityRisk = false

    let tooltipContent = ''

    if (isSwap && swapAmounts) {
      availableLiquidityUsd = swapLiquidityUsd

      availableLiquidityAmount = convertToTokenAmount(
        availableLiquidityUsd,
        toToken?.decimals,
        toToken?.prices.maxPrice,
      )

      isLiquidityRisk = availableLiquidityUsd!
        .mul(riskThresholdBps)
        .div(BASIS_POINTS_DIVISOR)
        .lt(swapAmounts.usdOut)

      tooltipContent = isLiquidityRisk
        ? `There may not be sufficient liquidity to execute your order when the Min. Receive are met.`
        : `The order will only execute if the Min. Receive is met and there is sufficient liquidity.`
    }

    if (isIncrease && increaseAmounts) {
      availableLiquidityUsd = isLong ? longLiquidityUsd : shortLiquidityUsd

      isLiquidityRisk = availableLiquidityUsd!
        .mul(riskThresholdBps)
        .div(BASIS_POINTS_DIVISOR)
        .lt(increaseAmounts.sizeDeltaUsd)

      tooltipContent = isLiquidityRisk
        ? `There may not be sufficient liquidity to execute your order when the price conditions are met.`
        : `The order will only execute if the price conditions are met and there is sufficient liquidity.`
    }

    return (
      <ExchangeInfoRow label={`Available Liquidity`}>
        <Tooltip
          className={isLiquidityRisk ? 'negative' : ''}
          content={tooltipContent}
        >
          {isSwap
            ? formatTokenAmount(
                availableLiquidityAmount,
                toToken?.decimals,
                toToken?.symbol,
              )
            : formatUsd(availableLiquidityUsd)}
        </Tooltip>
      </ExchangeInfoRow>
    )
  }

  function renderSwapSpreadWarining() {
    if (!isMarket) {
      return null
    }

    if (swapSpreadInfo.spread && swapSpreadInfo.isHigh) {
      return (
        <div className="Confirmation-box-warning">
          The spread is {`>`} 1%, please ensure the trade details are acceptable
          before comfirming
        </div>
      )
    }
  }

  const renderCollateralSpreadWarning = useCallback(() => {
    if (collateralSpreadInfo && collateralSpreadInfo.isHigh) {
      return (
        <div className="Confirmation-box-warning">
          Transacting with a depegged stable coin is subject to spreads
          reflecting the worse of current market price or $1.00, with
          transactions involving multiple stablecoins may have multiple spreads.
        </div>
      )
    }
  }, [collateralSpreadInfo])

  function renderAllowedSlippage() {
    return (
      <ExchangeInfoRow
        className="items-center"
        label={
          <Tooltip
            content={
              <div className="text-th-fgd-1">
                You can edit the default Allowed Slippage in the settings menu
                on the top right of the page.
                <br />
                <br />
                Note that a low allowed slippage, e.g. less than{' '}
                {formatPercentage(BigNumber.from(DEFAULT_SLIPPAGE_AMOUNT), {
                  signed: false,
                })}
                , may result in failed orders if prices are volatile.
              </div>
            }
          >
            {`Allowed Slippage`}
          </Tooltip>
        }
      >
        {editAllowedSlippage ? (
          <SlippageInput defaultSlippage={allowedSlippage} />
        ) : (
          <div className="flex items-center gap-2">
            <span>{getSlippageText(allowedSlippage)}%</span>
            <div onClick={() => setEditAllowedSlippage(true)}>
              <AllowedSlippageIcon />
            </div>
          </div>
        )}
      </ExchangeInfoRow>
    )
  }

  function renderHighPriceImpactWarning() {
    return (
      <div className="PositionEditor-allow-higher-slippage">
        <Checkbox
          isChecked={isHighPriceImpactAccepted}
          setIsChecked={setIsHighPriceImpactAccepted}
        >
          <p className="text-sm font-normal text-th-fgd-3">
            Acknowledge high Price Impact
          </p>
        </Checkbox>
      </div>
    )
  }

  function renderIncreaseOrderSection() {
    if (!marketInfo || !fromToken || !collateralToken || !toToken) {
      return null
    }

    const borrowingRate = getBorrowingFactorPerPeriod(
      marketInfo,
      isLong,
      CHART_PERIODS['1h'],
    ).mul(100)
    const fundigRate = getFundingFactorPerPeriod(
      marketInfo,
      isLong,
      CHART_PERIODS['1h'],
    ).mul(100)
    const isCollateralSwap = !getIsEquivalentTokens(fromToken, collateralToken)
    const existingPriceDecimals = p.existingPosition?.indexToken?.priceDecimals
    const toTokenPriceDecimals = toToken?.priceDecimals

    return (
      <div className="mb-1 space-y-2">
        {renderMain()}
        {renderDifferentCollateralWarning()}
        {renderCollateralSpreadWarning()}
        {/* {renderExistingLimitOrdersWarning()} */}
        {renderExistingTriggerErrors()}
        {renderExistingTriggerWarning()}
        {renderDifferentTokensWarning()}

        {isLimit && renderAvailableLiquidity()}

        <ExchangeInfoRow
          label={`Leverage`}
          value={
            <ValueTransition
              from={formatLeverage(existingPosition?.leverage)}
              to={formatLeverage(nextPositionValues?.nextLeverage) || '-'}
            />
          }
        />

        {isMarket && renderAllowedSlippage()}

        {isMarket && collateralSpreadInfo?.spread && (
          <ExchangeInfoRow
            label={`Collateral Spread`}
            isWarning={swapSpreadInfo.isHigh}
            isTop={true}
          >
            {formatAmount(
              collateralSpreadInfo.spread.mul(100),
              USD_DECIMALS,
              2,
              true,
            )}
            %
          </ExchangeInfoRow>
        )}

        {isMarket && (
          <ExchangeInfoRow
            label={`Entry Price`}
            value={
              <ValueTransition
                from={formatUsd(p.existingPosition?.entryPrice, {
                  displayDecimals: existingPriceDecimals,
                })}
                to={formatUsd(nextPositionValues?.nextEntryPrice, {
                  displayDecimals: toTokenPriceDecimals,
                })}
              />
            }
          />
        )}

        {isLimit && (
          <ExchangeInfoRow
            isTop
            label={`Mark Price`}
            value={
              formatUsd(markPrice, {
                displayDecimals: toTokenPriceDecimals,
              }) || '-'
            }
          />
        )}

        {isLimit && (
          <ExchangeInfoRow
            label={`Limit Price`}
            value={
              formatUsd(triggerPrice, {
                displayDecimals: toTokenPriceDecimals,
              }) || '-'
            }
          />
        )}

        <ExchangeInfoRow
          label={isMarket ? `Price Impact` : `Acceptable Price Impact`}
          value={
            <span
              className={cx({
                positive:
                  isMarket && increaseAmounts?.acceptablePriceDeltaBps?.gt(0),
              })}
            >
              {formatPercentage(increaseAmounts?.acceptablePriceDeltaBps, {
                signed: true,
              }) || '-'}
            </span>
          }
        />

        <ExchangeInfoRow
          label={`Acceptable Price`}
          value={
            formatAcceptablePrice(
              applySlippageToPrice(
                allowedSlippage,
                increaseAmounts?.acceptablePrice || BigNumber.from(0),
                true,
                isLong,
              ),
              {
                displayDecimals: toTokenPriceDecimals,
              },
            ) || '-'
          }
        />

        <ExchangeInfoRow
          label={`Liquidation Price`}
          value={
            <ValueTransition
              from={
                p.existingPosition
                  ? formatLiquidationPrice(
                      p.existingPosition?.liquidationPrice,
                      {
                        displayDecimals: existingPriceDecimals,
                      },
                    )
                  : undefined
              }
              to={
                formatLiquidationPrice(nextPositionValues?.nextLiqPrice, {
                  displayDecimals: toTokenPriceDecimals,
                }) || '-'
              }
            />
          }
        />

        <div className="flex items-center justify-between">
          <div>
            {isCollateralSwap ? (
              <Tooltip
                content={
                  <div>
                    {fromToken?.symbol} will be swapped to{' '}
                    {collateralToken?.symbol} on order execution.{' '}
                    {isLimit && (
                      <>
                        Collateral value may differ due to different Price
                        Impact at the time of execution.
                      </>
                    )}
                  </div>
                }
              >
                <span
                  className="!text-sm font-normal text-th-fgd-3"
                  style={{
                    fontSize: 14,
                  }}
                >
                  Collateral ({collateralToken?.symbol})
                </span>
              </Tooltip>
            ) : (
              <span className="text-sm font-normal text-th-fgd-3">
                <>Collateral ({collateralToken?.symbol})</>
              </span>
            )}
          </div>
          <div className="align-right">
            <Tooltip
              content={
                <>
                  <>Your position's collateral after deducting fees.</>
                  <br />
                  <br />
                  <StatsTooltipRow
                    label={`Pay Amount`}
                    value={
                      formatUsd(increaseAmounts?.initialCollateralUsd) || '-'
                    }
                    showDollar={false}
                  />
                  <StatsTooltipRow
                    label={`Fees`}
                    value={
                      fees?.payTotalFees?.deltaUsd &&
                      !fees.payTotalFees.deltaUsd.eq(0)
                        ? `${
                            fees.payTotalFees.deltaUsd.gt(0) ? '+' : '-'
                          }${formatUsd(fees.payTotalFees.deltaUsd.abs())}`
                        : '0.00$'
                    }
                    showDollar={false}
                  />
                  <div className="Tooltip-divider" />
                  <StatsTooltipRow
                    label={`Collateral`}
                    value={
                      formatUsd(increaseAmounts?.collateralDeltaUsd) || '-'
                    }
                    showDollar={false}
                  />
                </>
              }
            >
              <span className="text-sm font-normal text-th-fgd-1">
                {formatUsd(increaseAmounts?.collateralDeltaUsd)}
              </span>
            </Tooltip>
          </div>
        </div>

        <TradeFeesRow
          {...fees}
          fundingFeeRateStr={
            fundigRate &&
            `${fundigRate.gt(0) ? '+' : '-'}${formatAmount(
              fundigRate.abs(),
              30,
              4,
            )}% / 1h`
          }
          borrowFeeRateStr={
            borrowingRate && `-${formatAmount(borrowingRate, 30, 4)}% / 1h`
          }
          // executionFee={p.executionFee}
          feesType="increase"
          // warning={p.executionFee?.warning}
        />

        {(decreaseOrdersThatWillBeExecuted?.length > 0 ||
          isHighPriceImpact) && <div className="line-divider" />}

        {decreaseOrdersThatWillBeExecuted?.length > 0 && (
          <div className="PositionEditor-allow-higher-slippage">
            <Checkbox
              isChecked={isTriggerWarningAccepted}
              setIsChecked={setIsTriggerWarningAccepted}
            >
              <span className="text-sm font-normal text-th-fgd-3">
                <>I am aware of the trigger orders</>
              </span>
            </Checkbox>
          </div>
        )}

        {isHighPriceImpact && renderHighPriceImpactWarning()}
      </div>
    )
  }

  function renderSwapSection() {
    return (
      <div className="pt-1">
        {renderMain()}
        <div className="mb-3" />
        {renderSwapSpreadWarining()}
        {/* {isLimit && renderLimitPriceWarning()} */}
        {swapSpreadInfo.showSpread && swapSpreadInfo.spread && (
          <ExchangeInfoRow label={`Spread`} isWarning={swapSpreadInfo.isHigh}>
            {formatAmount(
              swapSpreadInfo.spread.mul(100),
              USD_DECIMALS,
              2,
              true,
            )}
            %
          </ExchangeInfoRow>
        )}
        {isLimit && renderAvailableLiquidity()}
        {isMarket && renderAllowedSlippage()}
        <ExchangeInfoRow label={`Mark Price`} isTop>
          {formatTokensRatio(fromToken, toToken, markRatio)}
        </ExchangeInfoRow>
        {isLimit && (
          <ExchangeInfoRow label={`Limit Price`}>
            <Tooltip
              content={`Limit Order Price to guarantee Min. Receive amount is updated in real time in the Orders tab after the order has been created.`}
            >
              {formatTokensRatio(fromToken, toToken, triggerRatio)}
            </Tooltip>
          </ExchangeInfoRow>
        )}

        <ExchangeInfoRow label={`${fromToken?.symbol} Price`}>
          {formatUsd(swapAmounts?.priceIn, {
            displayDecimals: fromToken?.priceDecimals,
          })}
        </ExchangeInfoRow>

        <ExchangeInfoRow label={`${toToken?.symbol} Price`}>
          {formatUsd(swapAmounts?.priceOut, {
            displayDecimals: toToken?.priceDecimals,
          })}
        </ExchangeInfoRow>

        {!p.isWrapOrUnwrap && (
          <TradeFeesRow
            {...fees}
            isTop
            // executionFee={p.executionFee}
            feesType="swap"
            // warning={p.executionFee?.warning}
          />
        )}

        <ExchangeInfoRow label={`Min. Receive`} isTop>
          {isMarket && swapAmounts?.minOutputAmount
            ? formatTokenAmount(
                applySlippageToMinOut(
                  allowedSlippage,
                  swapAmounts.minOutputAmount,
                ),
                toToken?.decimals,
                toToken?.symbol,
              )
            : formatTokenAmount(
                swapAmounts?.minOutputAmount,
                toToken?.decimals,
                toToken?.symbol,
              )}
        </ExchangeInfoRow>

        {isHighPriceImpact && <div className="line-divider" />}

        {isHighPriceImpact && renderHighPriceImpactWarning()}
      </div>
    )
  }

  function renderTriggerDecreaseSection() {
    const existingPriceDecimals = p.existingPosition?.indexToken?.priceDecimals
    const toTokenPriceDecimals = toToken?.priceDecimals
    return (
      <div className={cx({ 'flex flex-col gap-2 py-4': isTrigger })}>
        {renderMain()}
        {renderDifferentCollateralWarning()}

        {isTrigger && existingPosition?.leverage && (
          <div className="PositionEditor-allow-higher-slippage">
            <Checkbox isChecked={keepLeverage} setIsChecked={setKeepLeverage}>
              <p className="text-sm font-normal text-th-fgd-3">
                Keep leverage at {formatLeverage(existingPosition.leverage)}
              </p>
            </Checkbox>
          </div>
        )}

        <ExchangeInfoRow
          label={`Trigger Price`}
          value={
            triggerPrice
              ? `${fixedTriggerThresholdType} ${formatUsd(triggerPrice, {
                  displayDecimals: toTokenPriceDecimals,
                })}`
              : '...'
          }
        />

        <ExchangeInfoRow
          isTop
          label={`Mark Price`}
          value={
            markPrice
              ? formatUsd(markPrice, {
                  displayDecimals: toTokenPriceDecimals,
                })
              : '...'
          }
        />

        {existingPosition && (
          <ExchangeInfoRow
            label={`Entry Price`}
            value={
              formatUsd(existingPosition?.entryPrice, {
                displayDecimals: indexToken?.priceDecimals,
              }) || '-'
            }
          />
        )}

        <ExchangeInfoRow
          label={`Acceptable Price Impact`}
          value={
            decreaseAmounts?.triggerOrderType === OrderType.StopLossDecrease
              ? 'NA'
              : formatPercentage(decreaseAmounts?.acceptablePriceDeltaBps) ||
                '-'
          }
        />

        <ExchangeInfoRow
          label={`Acceptable Price`}
          value={
            formatAcceptablePrice(fixedTriggerAcceptablePrice, {
              displayDecimals: toTokenPriceDecimals,
            }) || '-'
          }
        />

        {p.existingPosition && (
          <ExchangeInfoRow
            label={`Liquidation Price`}
            value={
              nextPositionValues?.nextSizeUsd?.gt(0) ? (
                <ValueTransition
                  from={
                    formatUsd(existingPosition?.liquidationPrice, {
                      displayDecimals: existingPriceDecimals,
                    })!
                  }
                  to={formatUsd(nextPositionValues.nextLiqPrice, {
                    displayDecimals: existingPriceDecimals,
                  })}
                />
              ) : (
                '-'
              )
            }
          />
        )}

        <ExchangeInfoRow
          label={p.existingPosition?.sizeInUsd ? `Size` : `Decrease size`}
          isTop
          value={
            p.existingPosition?.sizeInUsd ? (
              <ValueTransition
                from={formatUsd(p.existingPosition.sizeInUsd)!}
                to={formatUsd(nextPositionValues?.nextSizeUsd)}
              />
            ) : decreaseAmounts?.sizeDeltaUsd ? (
              formatUsd(decreaseAmounts.sizeDeltaUsd)
            ) : (
              '-'
            )
          }
        />

        {!p.existingPosition && (
          <ExchangeInfoRow
            label={`Collateral`}
            value={collateralToken?.symbol}
          />
        )}

        {p.existingPosition && (
          <ExchangeInfoRow
            label={`Collateral (${p.existingPosition?.collateralToken?.symbol})`}
            value={
              <ValueTransition
                from={formatUsd(existingPosition?.remainingCollateralUsd)!}
                to={formatUsd(nextPositionValues?.nextCollateralUsd)}
              />
            }
          />
        )}

        {!p.keepLeverage && p.existingPosition?.leverage && (
          <ExchangeInfoRow
            label={`Leverage`}
            value={
              nextPositionValues?.nextSizeUsd?.gt(0) ? (
                <ValueTransition
                  from={formatLeverage(p.existingPosition?.leverage)}
                  to={formatLeverage(nextPositionValues.nextLeverage) || '-'}
                />
              ) : (
                '-'
              )
            }
          />
        )}
        {existingPosition && (
          <ExchangeInfoRow
            label={`PnL`}
            value={
              <ValueTransition
                from={
                  <>
                    {formatDeltaUsd(decreaseAmounts?.estimatedPnl)} (
                    {formatPercentage(decreaseAmounts?.estimatedPnlPercentage, {
                      signed: true,
                    })}
                    )
                  </>
                }
                to={
                  <>
                    {formatDeltaUsd(nextPositionValues?.nextPnl)} (
                    {formatPercentage(nextPositionValues?.nextPnlPercentage, {
                      signed: true,
                    })}
                    )
                  </>
                }
              />
            }
          />
        )}

        <TradeFeesRow
          {...fees}
          // executionFee={p.executionFee}
          feesType="decrease"
          // warning={p.executionFee?.warning}
        />

        {existingPosition && decreaseAmounts?.receiveUsd && (
          <ExchangeInfoRow
            label={`Receive`}
            value={formatTokenAmountWithUsd(
              decreaseAmounts.receiveTokenAmount,
              decreaseAmounts.receiveUsd,
              collateralToken?.symbol,
              collateralToken?.decimals,
            )}
          />
        )}

        {isHighPriceImpact && <div className="line-divider" />}

        {isHighPriceImpact && renderHighPriceImpactWarning()}
      </div>
    )
  }

  return isVisible ? (
    <div className="Confirmation-box absolute left-0 top-0 z-30 h-full w-full bg-th-bkg-1 px-4">
      <Image
        alt=""
        className="pointer-events-none opacity-80"
        src={`${CLOUD_FRONT_URL}/images/bg-noise.png`}
        layout="fill"
      />
      <div className="flex h-full flex-col">
        {isSwap && renderSwapSection()}
        {isIncrease && renderIncreaseOrderSection()}
        {isTrigger && renderTriggerDecreaseSection()}

        {needPayTokenApproval && fromToken ? (
          <div className="mt-2">
            <ApproveTokenButton
              tokenAddress={fromToken.address}
              tokenSymbol={fromToken.assetSymbol ?? fromToken.symbol}
              spenderAddress={getContract(chainId, 'SyntheticsRouter')}
              size="medium"
            />
          </div>
        ) : (
          <div
            className="Exchange-swap-button-container flex flex-1 items-start py-4"
            ref={submitButtonRef}
          >
            <Button
              className={clsx(
                'w-full text-sm font-bold uppercase',
                isSwap ? 'swap' : isLong ? 'long' : 'short',
              )}
              type="submit"
              onClick={onSubmit}
              disabled={submitButtonState.disabled && !shouldDisableValidation}
            >
              {isSwap ? (
                <div className="flex items-center gap-1 text-sm !text-th-fgd-1">
                  <RiArrowLeftRightLine fontSize={20} />
                  {submitButtonState.text}
                </div>
              ) : (
                submitButtonState.text
              )}
            </Button>
          </div>
        )}
      </div>
    </div>
  ) : null
}
