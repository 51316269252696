import SimpleAreaChart from '@components/shared/SimpleAreaChart'
import { USD_DECIMALS } from '@components/trade/gmx/MarketHeader'
import { useQuery } from '@tanstack/react-query'
import { IS_VERBOSE } from 'config/development'
import { XbyYPairs } from 'config/XbyYPairs'
import { ethers } from 'ethers'
import { IndexTokenStats } from 'hooks/gmx/useIndexTokenStats'
import useThemeWrapper from 'hooks/useThemeWrapper'
import { COLORS } from 'mango/styles/colors'

export async function getHistoricalPrice(
  symbol: string,
  currency: string,
  limit: number,
  aggregate: number,
) {
  try {
    const url = `https://min-api.cryptocompare.com/data/v2/histominute?fsym=${symbol}&tsym=${currency}&limit=${limit}&aggregate=${aggregate}`
    const response = await fetch(url)
    const data = await response.json()

    if (data.Response === 'Success') {
      return data.Data.Data
    } else {
      // eslint-disable-next-line no-console
      IS_VERBOSE && console.error('Error:', data.Message)
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    IS_VERBOSE && console.error('Error:', error)
  }
}

export const PriceChart = ({ market }: { market: IndexTokenStats }) => {
  const { theme } = useThemeWrapper()

  const { data: cryptoCompareData, isLoading } = useQuery({
    queryKey: ['swap-chart-data', market.token.symbol],
    queryFn: () =>
      getHistoricalPrice(
        XbyYPairs.includes(market.token.symbol)
          ? market.token.symbol.split('/')[0]
          : market.token.symbol,
        XbyYPairs.includes(market.token.symbol)
          ? market.token.symbol.split('/')[1]
          : 'USD',
        30,
        1,
      ),
    staleTime: 1000 * 60 * 1,
    gcTime: 1000 * 60 * 15,
    refetchOnWindowFocus: false,
  })

  const isUp =
    cryptoCompareData && cryptoCompareData.length
      ? Number(
          ethers.utils.formatUnits(market?.token.prices.maxPrice, USD_DECIMALS),
        ) >= Number(cryptoCompareData[0].close)
      : false

  return (
    <>
      {!isLoading && (
        <SimpleAreaChart
          color={isUp ? COLORS.UP[theme] : COLORS.DOWN[theme]}
          data={cryptoCompareData || []}
          name={market.token.id || market.token.symbol}
          xKey="time"
          yKey="close"
        />
      )}
    </>
  )
}
