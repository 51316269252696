import useTokenPrice from 'hooks/gmx/usePyth/useTokenPrice'
import { memo, useEffect } from 'react'
import { USD_DECIMALS, getPageTitle } from 'gmx/lib/legacy'
import { formatPrice } from 'apis/birdeye/helpers'
import { ethers } from 'ethers'
import { TokenData } from 'domain/synthetics/tokens/types'

const LivePriceUpdater = ({
  address,
  pythId,
  symbol,
  isStable,
  // priceDecimals,
}: Pick<
  TokenData,
  'address' | 'pythId' | 'symbol' | 'isStable' | 'priceDecimals'
>) => {
  const price = useTokenPrice(pythId!, address, 'maxPrice')

  useEffect(() => {
    // const currentTokenPriceStr =
    //   formatUsd(price, {
    //     displayDecimals: priceDecimals,
    //   }) || '...'
    const currentTokenPriceStr =
      '$' +
      formatPrice(
        parseFloat(ethers.utils.formatUnits(price || 0, USD_DECIMALS)),
      )
    const title = getPageTitle(
      currentTokenPriceStr + ` | ${symbol}${isStable ? '' : '/USD'}`,
    )
    document.title = title
  }, [price])

  return null
}
export default memo(LivePriceUpdater)
