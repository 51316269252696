import Tooltip from '@components/shared/Tooltip'
import { formatUsd } from '@components/trade/gmx/tradingview/lib/numbers'
import { tradeboxStoreBigNumberSelector } from '@store/tradeboxStore'
import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import StatsTooltipRow from 'components/StatsTooltip/StatsTooltipRow'

type Props = {
  isLong: boolean
  indexTokenSymbol: string | undefined
}

const AvailableLiquidityRow = (p: Props) => {
  const { indexTokenSymbol, isLong } = p
  const reservedUsd = tradeboxStoreBigNumberSelector(
    (store) => store.marketCardInfo.reservedUsd,
  )
  const maxReservedUsd = tradeboxStoreBigNumberSelector(
    (store) => store.marketCardInfo.maxReservedUsd,
  )
  const currentOpenInterest = tradeboxStoreBigNumberSelector(
    (store) => store.marketCardInfo.currentOpenInterest,
  )
  const maxOpenInterest = tradeboxStoreBigNumberSelector(
    (store) => store.marketCardInfo.maxOpenInterest,
  )

  const longLiquidity = tradeboxStoreBigNumberSelector(
    (store) => store.marketCardInfo.longLiquidity,
  )
  const shortLiquidity = tradeboxStoreBigNumberSelector(
    (store) => store.marketCardInfo.shortLiquidity,
  )

  const longShortText = isLong ? `Long` : `Short`

  return (
    <ExchangeInfoRow
      label={`Available Liquidity`}
      value={
        <Tooltip
          className="al-swap"
          placement="top"
          content={
            <div>
              <StatsTooltipRow
                label={`${longShortText} ${indexTokenSymbol} Reserve`}
                value={`${formatUsd(reservedUsd, {
                  displayDecimals: 0,
                })} / ${formatUsd(maxReservedUsd, {
                  displayDecimals: 0,
                })}`}
                showDollar={false}
              />
              <StatsTooltipRow
                label={`${longShortText} ${indexTokenSymbol} Open Interest`}
                value={`${formatUsd(currentOpenInterest, {
                  displayDecimals: 0,
                })} / ${formatUsd(maxOpenInterest, {
                  displayDecimals: 0,
                })}`}
                showDollar={false}
              />

              <br />
              {isLong && (
                <>
                  Reserve considers the PnL of Open Positions, while Open
                  Interest does not.
                </>
              )}
              <>
                The Available Liquidity will be the lesser of the difference
                between the maximum value and the current value for the Reserve
                and Open Interest.
              </>
            </div>
          }
        >
          <span className="mr-2 text-th-success">
            {' '}
            {formatUsd(longLiquidity) || '...'}
          </span>
          <span className="text-th-error">
            {formatUsd(shortLiquidity) || '...'}
          </span>
        </Tooltip>
      }
    />
  )
}

export default AvailableLiquidityRow
