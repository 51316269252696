import ButtonGroup from '@components/forms/ButtonGroup'
import TabUnderline from '@components/shared/TabUnderline'
import { TradeType } from 'domain/synthetics/trade'
import { memo } from 'react'
import { TradeBoxProps } from './TradeBox'

type Props = Pick<
  TradeBoxProps,
  | 'tradeType'
  | 'onSelectTradeType'
  | 'tradeMode'
  | 'onSelectTradeMode'
  | 'availableTradeModes'
> & { isMobile: boolean }

const TradeBoxHeader: React.FC<Props> = ({
  tradeType,
  onSelectTradeType,
  tradeMode,
  onSelectTradeMode,
  availableTradeModes,
  isMobile,
}) => {
  return (
    <>
      <div className={isMobile ? '' : 'pt-4'}>
        <TabUnderline
          activeValue={tradeType}
          values={[TradeType.Long, TradeType.Short, TradeType.Swap]}
          names={[TradeType.Long, TradeType.Short, TradeType.Swap]}
          onChange={onSelectTradeType}
          small
        />
      </div>
      {/* {tradeType !== 'Swap' && ( */}
      <div className="mt-1 md:mt-3">
        {/* <p className="mb-2 font-flexo text-xs font-medium text-th-fgd-3">
              Order Type
            </p> */}
        <ButtonGroup
          className="uppercase"
          activeValue={tradeMode}
          onChange={onSelectTradeMode}
          // disabledValues={['Trigger'] as TradeMode[]}
          values={availableTradeModes}
          large={true}
        />
      </div>
      {/* )} */}
    </>
  )
}

export default memo(TradeBoxHeader)
