import {
  HistoryCSV,
  TranscastionStatusFilter,
  transcastionStatusFilterOptions,
} from '@components/account/Tabs/History'
import DateRangePicker from '@components/shared/DateRangePicker'
import Modal from '@components/shared/Modal'
import TabButtons from '@components/shared/TabButtons'
import SwapInfoTabs from '@components/swap/SwapInfoTabs'
import tradeboxStore from '@store/tradeboxStore'
import clsx from 'clsx'
import Checkbox from 'components/Checkbox/Checkbox'
import { DownloadAsCsv } from 'components/DownloadAsCSV/DownloadAsCSV'
import MarketTable from 'components/Market/MarketTable'
import { ReferralCodeForm } from 'components/Referrals/JoinReferralCode'
import { TradeBox } from 'components/Synthetics/TradeBox/TradeBox'
import {
  DEFAULT_ACCEPABLE_PRICE_IMPACT_BPS,
  DEFAULT_HIGHER_SLIPPAGE_AMOUNT,
} from 'config/factors'
import {
  IS_PNL_IN_LEVERAGE_KEY,
  SHOULD_SHOW_POSITION_LINES_KEY,
  SHOW_PNL_AFTER_FEES_KEY,
  getAcceptablePriceImpactBpsKey,
  getSyntheticsListSectionKey,
} from 'config/localStorage'
import {
  NATIVE_TOKEN_ADDRESS,
  convertTokenAddress,
  getPriceDecimals,
  getToken,
} from 'config/tokens'
import { SUPPORTED_RESOLUTIONS_V2 } from 'config/tradingview'
import dayjs from 'dayjs'
import { getByKey, useMarketsInfo } from 'domain/synthetics/markets'
import {
  PositionOrderInfo,
  isIncreaseOrderType,
  isSwapOrderType,
} from 'domain/synthetics/orders'
import { useOrdersInfo } from 'domain/synthetics/orders/useOrdersInfo'
import { PositionInfo, usePositionsInfo } from 'domain/synthetics/positions'
import { useOracleKeeperFetcher } from 'domain/synthetics/tokens'
import { TradeMode, TradeType } from 'domain/synthetics/trade/types'
import { useSelectedTradeOption } from 'domain/synthetics/trade/useSelectedTradeOption'
import { BigNumber } from 'ethers'
import { useChainId } from 'gmx/lib/chains'
import { getPositionKey } from 'gmx/lib/legacy'
import { usePendingTxns } from 'gmx/lib/usePendingTxns'
import { downloadCSV } from 'gmx/lib/utils'
import { useViewport } from 'hooks/useViewport'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import useTradePageLayout from 'pages/trade/hooks/useTradePageLayout'
import LivePriceUpdater from 'pages/trade/LivePriceUpdater'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { useAccount } from 'wagmi'
import MarketHeader, {
  USD_DECIMALS,
  formatAmount,
  getTokenData,
} from './gmx/MarketHeader'
import { DEFAULT_PERIOD } from './gmx/tradingview/constants'
import { CHART_PERIODS } from './gmx/tradingview/lib/legacy'
import { useLocalStorageSerializeKey } from './gmx/tradingview/lib/localStorage'
import { SyntheticsTVDataProvider } from './gmx/tradingview/SyntheticsTVDataProvider'
import { ChartLine } from './gmx/tradingview/TVChartContainer'
import { useUserReferralCode } from 'domain/referrals'
import useWallet from 'gmx/lib/wallets/useWallet'

const DesktopRecentTrades = dynamic(
  () => import('components/RecentTrades/DesktopRecentTrades'),
)

const FavoriteMarketsBar = dynamic(() => import('./FavoriteMarketsBar'))
const TVChartContainer = dynamic(
  () => import('./gmx/tradingview/TVChartContainer'),
)
const PositionSeller = dynamic(() =>
  import('components/Synthetics/PositionSeller/PositionSeller').then(
    (module) => module.PositionSeller,
  ),
)

const PositionEditor = dynamic(() =>
  import('components/Synthetics/PositionEditor/PositionEditor').then(
    (module) => module.PositionEditor,
  ),
)
const TradeHistory = dynamic(() =>
  import('components/Synthetics/TradeHistory/TradeHistory').then(
    (module) => module.TradeHistory,
  ),
)
const PositionList = dynamic(() =>
  import('components/Synthetics/PositionList/PositionList').then(
    (module) => module.PositionList,
  ),
)

const OrderList = dynamic(() =>
  import('components/Synthetics/OrderList/OrderList').then(
    (module) => module.OrderList,
  ),
)

enum ListSection {
  Positions = 'Positions',
  Orders = 'Orders',
  Trades = 'Perp History',
  RecentTrades = 'Market Activity',
  Market = 'Markets',
  Claims = 'Claims',
}

const ResponsiveGridLayout = WidthProvider(Responsive)

const TradeAdvancedPage = () => {
  const { isMobile } = useViewport()
  const { chainId } = useChainId()
  const { signer } = useWallet()
  const { address: account } = useAccount()
  const [showReferralModal, setShowReferralModal] = useState(false)
  const { userReferralCodeString } = useUserReferralCode(
    signer,
    chainId,
    account,
  )
  // const signer = useEthersSigner()
  const [savedShouldShowPositionLines, setSavedShouldShowPositionLines] =
    useLocalStorageSerializeKey(
      [chainId, SHOULD_SHOW_POSITION_LINES_KEY],
      false,
    )
  const { query } = useRouter()

  const { marketsInfoData, tokensData, pricesUpdatedAt, marketsData } =
    useMarketsInfo(chainId)

  const oracleKeeperFetcher = useOracleKeeperFetcher(chainId)
  const [dataProvider, setDataProvider] = useState<SyntheticsTVDataProvider>()
  const [tradePageVersion, setTradePageVersion] = useState(2)
  const [_period, setPeriod] = useLocalStorageSerializeKey<string>(
    [chainId, 'Chart-period-v2'],
    DEFAULT_PERIOD,
  )

  const period = _period || DEFAULT_PERIOD
  const [, setPendingTxns] = usePendingTxns()
  const [listSection, setListSection] = useLocalStorageSerializeKey(
    getSyntheticsListSectionKey(chainId),
    ListSection.Positions,
  )

  const [closingPositionKey, setClosingPositionKey] = useState<string>()
  const [showPnlAfterFees] = useState(true)
  const [, setIsAcceptablePriceImpactEditing] = useState(false)

  const [editingPositionKey, setEditingPositionKey] = useState<string>()

  const savedIsPnlInLeverage = useLocalStorageSerializeKey(
    [chainId, IS_PNL_IN_LEVERAGE_KEY],
    false,
  )

  const [savedShowPnlAfterFees] = useLocalStorageSerializeKey(
    [chainId, SHOW_PNL_AFTER_FEES_KEY],
    true,
  )

  const [startDate, setStartDate] = useState<Date | null>(
    dayjs(new Date()).subtract(3, 'M').toDate(),
  )

  const [endDate, setEndDate] = useState<Date | null>(new Date())

  const [transcastionStatusFilters, setTranscastionStatusFilters] = useState(
    transcastionStatusFilterOptions.map((val) => {
      return {
        label: val,
        isChecked: true,
      }
    }),
  )

  const { positionsInfoData, isLoading: isPositionsLoading } = usePositionsInfo(
    chainId,
    {
      marketsInfoData,
      tokensData,
      pricesUpdatedAt,
      showPnlInLeverage: !savedIsPnlInLeverage,
      account,
      marketsData,
    },
  )

  const onPositionSellerClose = useCallback(() => {
    setClosingPositionKey(undefined)
  }, [])

  const onPositionEditorClose = useCallback(() => {
    setEditingPositionKey(undefined)
  }, [])

  const closingPosition = getByKey(positionsInfoData, closingPositionKey)

  const editingPosition = getByKey(positionsInfoData, editingPositionKey)

  const { ordersInfoData, isLoading: isOrdersLoading } = useOrdersInfo(
    chainId,
    {
      account,
      marketsInfoData,
      positionsInfoData,
      tokensData,
    },
  )

  const {
    tradeType,
    tradeMode,
    tradeFlags,
    isWrapOrUnwrap,
    fromTokenAddress,
    fromToken,
    toTokenAddress,
    toToken,
    marketAddress,
    marketInfo,
    collateralAddress,
    collateralToken,
    availableTokensOptions,
    avaialbleTradeModes,
    setTradeType,
    setTradeMode,
    setFromTokenAddress,
    setToTokenAddress,
    setMarketAddress,
    setCollateralAddress,
    setActivePosition,
    switchTokenAddresses,
  } = useSelectedTradeOption(chainId, { marketsInfoData, tokensData })

  const [csvData, setCsvData] = useState<HistoryCSV[]>()

  useEffect(() => {
    if (!_period || !(_period in CHART_PERIODS)) {
      setPeriod(DEFAULT_PERIOD)
    }
  }, [_period, setPeriod])

  useEffect(() => {
    if (query?.mode && query.mode === 'Swap') {
      setTradeType('Swap' as TradeType)
      return
    }
    setTradeType('Long' as TradeType)
  }, [])

  useEffect(() => {
    const decodedSymbol = decodeURIComponent(query.symbol as string)

    if (!query.symbol || !tokensData || decodedSymbol === toToken?.symbol) {
      return
    }

    const token = Object.values(tokensData).find(
      (token) => token.symbol === decodedSymbol,
    )
    if (token) {
      setToTokenAddress(token.address)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, tokensData])

  const { isSwap, isLong } = tradeFlags
  const {
    indexTokens,
    sortedIndexTokensWithPoolValue,
    swapTokens,
    sortedLongAndShortTokens,
  } = availableTokensOptions

  const { chartToken, availableChartTokens } = useMemo(() => {
    if (!fromTokenAddress || !toTokenAddress) {
      return {}
    }

    try {
      const fromToken = getToken(chainId, fromTokenAddress)
      const toToken = getToken(chainId, toTokenAddress)

      const chartToken =
        isSwap && toToken?.isStable && !fromToken?.isStable
          ? fromToken
          : toToken

      const availableChartTokens = isSwap ? swapTokens : indexTokens
      const sortedAvailableChartTokens = availableChartTokens.sort((a, b) => {
        if (sortedIndexTokensWithPoolValue || sortedLongAndShortTokens) {
          const currentSortReferenceList = isSwap
            ? sortedLongAndShortTokens
            : sortedIndexTokensWithPoolValue
          return (
            currentSortReferenceList.indexOf(a.address) -
            currentSortReferenceList.indexOf(b.address)
          )
        }
        return 0
      })

      return {
        chartToken,
        availableChartTokens: sortedAvailableChartTokens,
      }
    } catch (e) {
      return {}
    }
  }, [
    chainId,
    fromTokenAddress,
    indexTokens,
    isSwap,
    toTokenAddress,
    sortedIndexTokensWithPoolValue,
    swapTokens,
    sortedLongAndShortTokens,
  ])

  useEffect(() => {
    if (query?.ref) {
      setShowReferralModal(true)
    }
  }, [query?.ref])

  const selectedPositionKey = useMemo(() => {
    if (!account || !collateralAddress || !marketAddress || !tradeType) {
      return undefined
    }

    return getPositionKey(
      account,
      marketAddress,
      collateralAddress,
      isLong,
      NATIVE_TOKEN_ADDRESS,
    )
  }, [account, collateralAddress, marketAddress, tradeType, isLong])
  const selectedPosition = getByKey(positionsInfoData, selectedPositionKey)

  const [selectedOrdersKeys, setSelectedOrdersKeys] = useState<{
    [key: string]: boolean
  }>({})

  const existingOrder = useMemo(() => {
    if (!selectedPositionKey) {
      return undefined
    }

    return Object.values(ordersInfoData || {})
      .filter((order) => !isSwapOrderType(order.orderType))
      .find((order) => {
        if (isSwapOrderType(order.orderType)) {
          return false
        }

        return (
          getPositionKey(
            order.account,
            order.marketAddress,
            order.targetCollateralToken.address,
            order.isLong,
            NATIVE_TOKEN_ADDRESS,
          ) === selectedPositionKey
        )
      })
  }, [ordersInfoData, selectedPositionKey])

  const { positionsCount, ordersCount } = useMemo(() => {
    const positions = Object.values(positionsInfoData || {})
    const orders = Object.values(ordersInfoData || {})

    return {
      positionsCount: positions.length,
      ordersCount: orders.length,
      ordersErrorsCount: orders.filter((order) => order.errorLevel === 'error')
        .length,
      ordersWarningsCount: orders.filter(
        (order) => order.errorLevel === 'warning',
      ).length,
    }
  }, [ordersInfoData, positionsInfoData])

  const [savedAcceptablePriceImpactBps] = useLocalStorageSerializeKey(
    getAcceptablePriceImpactBpsKey(chainId),
    DEFAULT_ACCEPABLE_PRICE_IMPACT_BPS,
  )

  const acceptablePriceImpactBps = BigNumber.from(
    savedAcceptablePriceImpactBps || DEFAULT_ACCEPABLE_PRICE_IMPACT_BPS,
  )

  const onEditAcceptablePriceImpact = useCallback(() => {
    return setIsAcceptablePriceImpactEditing(true)
  }, [])

  const [isHigherSlippageAllowed, setIsHigherSlippageAllowed] = useState(false)
  const allowedSlippage = tradeboxStore((store) => store.slippage)
  const updateProp = tradeboxStore((store) => store.updateProp)

  if (isHigherSlippageAllowed) {
    updateProp('slippage', DEFAULT_HIGHER_SLIPPAGE_AMOUNT)
  }

  const chartLines = useMemo(() => {
    const chartTokenAddress = chartToken?.address
    if (!chartTokenAddress) {
      return []
    }

    const orderLines: ChartLine[] = Object.values(ordersInfoData || {})
      .filter((order) => {
        if (isSwapOrderType(order.orderType)) {
          return false
        }

        const positionOrder = order as PositionOrderInfo

        return (
          positionOrder.marketInfo &&
          positionOrder.triggerPrice &&
          convertTokenAddress(
            chainId,
            positionOrder.marketInfo.indexTokenAddress,
            'wrapped',
          ) === convertTokenAddress(chainId, chartTokenAddress, 'wrapped')
        )
      })
      .map((order) => {
        const positionOrder = order as PositionOrderInfo
        const priceDecimal = getPriceDecimals(
          chainId,
          positionOrder.indexToken.symbol,
        )

        const longOrShortText = order.isLong ? `Long` : `Short`
        const orderTypeText = isIncreaseOrderType(order.orderType)
          ? `Inc.`
          : `Dec.`
        const tokenSymbol = getTokenData(
          tokensData,
          positionOrder.marketInfo.indexTokenAddress,
          'native',
        )?.symbol

        return {
          title: `${longOrShortText} ${orderTypeText} ${tokenSymbol}`,
          price: parseFloat(
            formatAmount(
              positionOrder.triggerPrice,
              USD_DECIMALS,
              priceDecimal,
            ),
          ),
        }
      })

    const positionLines = Object.values(positionsInfoData || {}).reduce(
      (acc, position) => {
        const priceDecimal = getPriceDecimals(
          chainId,
          position.indexToken.symbol,
        )
        if (
          position.marketInfo &&
          convertTokenAddress(
            chainId,
            position.marketInfo.indexTokenAddress,
            'wrapped',
          ) === convertTokenAddress(chainId, chartTokenAddress, 'wrapped')
        ) {
          const longOrShortText = position.isLong ? `Long` : `Short`
          const tokenSymbol = getTokenData(
            tokensData,
            position.marketInfo?.indexTokenAddress,
            'native',
          )?.symbol
          const liquidationPrice = formatAmount(
            position?.liquidationPrice,
            USD_DECIMALS,
            priceDecimal,
          )

          acc.push({
            title: `Open ${longOrShortText} ${tokenSymbol}`,
            price: parseFloat(
              formatAmount(position.entryPrice, USD_DECIMALS, priceDecimal),
            ),
          })
          if (liquidationPrice && liquidationPrice !== 'NA') {
            acc.push({
              title: `Liq. ${longOrShortText} ${tokenSymbol}`,
              price: parseFloat(liquidationPrice),
            })
          }
        }

        return acc
      },
      [] as ChartLine[],
    )

    return orderLines.concat(positionLines)
  }, [
    chainId,
    chartToken?.address,
    ordersInfoData,
    positionsInfoData,
    tokensData,
  ])

  useEffect(() => {
    if (!SyntheticsTVDataProvider) {
      return
    }
    setDataProvider(
      new SyntheticsTVDataProvider({
        resolutions: SUPPORTED_RESOLUTIONS_V2,
        oracleKeeperFetcher,
      }),
    )
  }, [oracleKeeperFetcher])

  useEffect(() => {
    if (!period || !(period in CHART_PERIODS)) {
      setPeriod(DEFAULT_PERIOD)
    }
  }, [period, setPeriod])

  function onSelectPositionClick(key: string, tradeMode?: TradeMode) {
    setActivePosition(getByKey(positionsInfoData, key), tradeMode)
  }

  function handleSettlePositionFeesClick(_positionKey: PositionInfo['key']) {}

  const { layoutProps, tradeLayout } = useTradePageLayout()

  const tradeBox = useMemo(() => {
    return (
      <TradeBox
        tradeMode={tradeMode}
        tradeType={tradeType}
        availableTradeModes={avaialbleTradeModes}
        tradeFlags={tradeFlags}
        isWrapOrUnwrap={isWrapOrUnwrap}
        fromTokenAddress={fromTokenAddress}
        fromToken={fromToken}
        toTokenAddress={toTokenAddress}
        toToken={toToken}
        marketAddress={marketAddress}
        marketInfo={marketInfo}
        collateralAddress={collateralAddress}
        collateralToken={collateralToken}
        avaialbleTokenOptions={availableTokensOptions}
        savedIsPnlInLeverage={!savedIsPnlInLeverage}
        existingPosition={selectedPosition}
        existingOrder={existingOrder}
        shouldDisableValidation={false}
        acceptablePriceImpactBpsForLimitOrders={acceptablePriceImpactBps}
        allowedSlippage={allowedSlippage!}
        isHigherSlippageAllowed={isHigherSlippageAllowed}
        tokensData={tokensData}
        ordersInfo={ordersInfoData}
        positionsInfo={positionsInfoData}
        marketsInfoData={marketsInfoData}
        setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
        onSelectMarketAddress={setMarketAddress}
        onSelectCollateralAddress={setCollateralAddress}
        onSelectFromTokenAddress={setFromTokenAddress}
        onSelectToTokenAddress={setToTokenAddress}
        onSelectTradeMode={setTradeMode}
        onSelectTradeType={setTradeType}
        setIsEditingAcceptablePriceImpact={onEditAcceptablePriceImpact}
        setPendingTxns={setPendingTxns}
        switchTokenAddresses={switchTokenAddresses}
      />
    )
  }, [
    acceptablePriceImpactBps,
    allowedSlippage,
    avaialbleTradeModes,
    availableTokensOptions,
    collateralAddress,
    collateralToken,
    existingOrder,
    fromToken,
    fromTokenAddress,
    isHigherSlippageAllowed,
    isWrapOrUnwrap,
    marketAddress,
    marketInfo,
    marketsInfoData,
    onEditAcceptablePriceImpact,
    ordersInfoData,
    positionsInfoData,
    savedIsPnlInLeverage,
    selectedPosition,
    setCollateralAddress,
    setFromTokenAddress,
    setMarketAddress,
    setToTokenAddress,
    setTradeMode,
    setTradeType,
    switchTokenAddresses,
    toToken,
    toTokenAddress,
    tokensData,
    tradeFlags,
    tradeMode,
    tradeType,
  ])
  const tvChart = useMemo(() => {
    return (
      <TVChartContainer
        chartLines={chartLines}
        savedShouldShowPositionLines={!!savedShouldShowPositionLines}
        symbol={chartToken?.symbol || 'ETH'}
        chainId={chainId}
        onSelectToken={setToTokenAddress as any}
        dataProvider={dataProvider}
        period={period || '4h'}
        setPeriod={setPeriod}
        chartToken={{
          symbol: chartToken?.symbol || 'ETH',
          ...getTokenData(tokensData, chartToken?.address)?.prices,
        }}
        supportedResolutions={SUPPORTED_RESOLUTIONS_V2}
        tradePageVersion={tradePageVersion}
        setTradePageVersion={setTradePageVersion}
        basePythId={chartToken?.baseId}
        quotePythId={chartToken?.pythId}
      />
    )
  }, [
    chartToken?.symbol,
    chartToken?.address,
    chartToken?.baseId,
    chartToken?.pythId,
    chainId,
    setToTokenAddress,
    dataProvider,
    period,
    setPeriod,
    tokensData,
    tradePageVersion,
  ])

  return (
    <>
      {chartToken && (
        <LivePriceUpdater
          address={chartToken.address}
          pythId={chartToken.pythId}
          symbol={chartToken.symbol}
          isStable={chartToken.isStable}
          priceDecimals={chartToken.priceDecimals}
        />
      )}
      {isMobile ? (
        <>
          <FavoriteMarketsBar
            setToTokenAddress={setToTokenAddress}
            tokensData={tokensData}
          />
          <div key="market-header" className="z-10 w-full">
            <MarketHeader
              positionsInfo={positionsInfoData}
              avaialbleTokenOptions={availableTokensOptions}
              availableTokens={availableChartTokens}
              tradeFlags={tradeFlags}
              setToTokenAddress={setToTokenAddress}
              tradeType={tradeType}
              chartTokenAddress={chartToken?.address}
              marketInfo={marketInfo}
              isLong={tradeType === TradeType.Long}
            />
          </div>

          <div className="h-64 border-y border-th-input-border">{tvChart}</div>
          <div>{tradeBox}</div>

          <div>
            <div className="hide-scroll h-full overflow-y-scroll">
              <div className="hide-scroll flex items-center overflow-x-auto border-y border-th-input-border">
                {tradeType !== TradeType.Swap && (
                  <>
                    <div className="flex w-full items-center justify-between">
                      <TabButtons
                        activeValue={listSection as ListSection}
                        onChange={(section) =>
                          setListSection(section as ListSection)
                        }
                        values={[
                          [ListSection.Positions, positionsCount],
                          [ListSection.Orders, ordersCount],
                          [ListSection.Trades, 0],
                          [ListSection.Market, 0],
                        ]}
                        showBorders
                        fillWidth={true}
                      />
                    </div>
                    <div className="ml-2 flex h-12 items-center justify-center border-l border-th-input-border pl-4 pr-2">
                      <Checkbox
                        isChecked={savedShouldShowPositionLines}
                        setIsChecked={setSavedShouldShowPositionLines}
                        className="min-w-fit"
                        size="small"
                      />
                    </div>
                  </>
                )}
              </div>
              {tradeType === TradeType.Swap ? (
                <div className="h-auto min-h-[30vh]">
                  <SwapInfoTabs />
                </div>
              ) : (
                <>
                  {listSection === ListSection.Positions && (
                    <PositionList
                      positionsData={positionsInfoData}
                      ordersData={ordersInfoData}
                      isLoading={isPositionsLoading}
                      showPnlInLeverage={!savedIsPnlInLeverage}
                      onOrdersClick={() => setListSection(ListSection.Orders)}
                      onSettlePositionFeesClick={handleSettlePositionFeesClick}
                      onSelectPositionClick={onSelectPositionClick}
                      onClosePositionClick={setClosingPositionKey}
                      onEditCollateralClick={setEditingPositionKey}
                      showPnlAfterFees={showPnlAfterFees}
                      savedShowPnlAfterFees={!savedShowPnlAfterFees}
                      currentMarketAddress={marketAddress}
                      currentCollateralAddress={collateralAddress}
                      currentTradeType={tradeType}
                    />
                  )}
                  {listSection === ListSection.Orders && (
                    <OrderList
                      marketsInfoData={marketsInfoData}
                      tokensData={tokensData}
                      positionsData={positionsInfoData}
                      ordersData={ordersInfoData}
                      selectedOrdersKeys={selectedOrdersKeys}
                      setSelectedOrdersKeys={setSelectedOrdersKeys}
                      isLoading={isOrdersLoading}
                      setPendingTxns={setPendingTxns}
                    />
                  )}
                  {listSection === ListSection.Trades && (
                    <>
                      <div className="flex items-center gap-4 border-b border-th-input-border px-4 py-4">
                        <div className="hide-scroll overflow-x-auto">
                          <TranscastionStatusFilter
                            options={transcastionStatusFilters}
                            updateFiltersState={setTranscastionStatusFilters}
                          />
                        </div>
                        {csvData && (
                          <DownloadAsCsv
                            excludedFields={[]}
                            fileName={
                              'rfx_history_' +
                              listSection +
                              '_' +
                              dayjs().format()
                            }
                            data={csvData}
                            variant="small"
                            customDownloadFn={() =>
                              downloadCSV(
                                csvData,
                                'rfx_history_' +
                                  listSection +
                                  '_' +
                                  dayjs().format(),
                              )
                            }
                          />
                        )}
                      </div>
                      <TradeHistory
                        activeTab={tradeType}
                        account={account}
                        marketsInfoData={marketsInfoData}
                        tokensData={tokensData}
                        shouldShowPaginationButtons
                        hasFilters={false}
                        filterDates={{
                          endDate: endDate,
                          startDate: startDate,
                        }}
                        transactionStatusFilters={transcastionStatusFilters}
                        setCsvData={setCsvData}
                      />
                    </>
                  )}
                  {listSection === ListSection.Market && <MarketTable />}
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <FavoriteMarketsBar
            setToTokenAddress={setToTokenAddress}
            tokensData={tokensData}
          />
          <div key="market-header" className="z-10 w-full">
            <MarketHeader
              positionsInfo={positionsInfoData}
              avaialbleTokenOptions={availableTokensOptions}
              availableTokens={availableChartTokens}
              tradeFlags={tradeFlags}
              setToTokenAddress={setToTokenAddress}
              tradeType={tradeType}
              chartTokenAddress={chartToken?.address}
              marketInfo={marketInfo}
              isLong={tradeType === TradeType.Long}
            />
          </div>
          <div className="flex w-full">
            <div className="w-full">
              <ResponsiveGridLayout {...layoutProps}>
                <div
                  key="tv-chart"
                  className="relative h-full border border-x-0 border-r border-th-input-border"
                >
                  <div className={`relative h-full overflow-auto`}>
                    <div
                      className={clsx(
                        'relative h-full w-full',
                        'show-recent-order',
                      )}
                    >
                      {tvChart}
                    </div>
                  </div>
                </div>
                <div
                  className={`overflow-x-hidden ${
                    tradeLayout === 'chartLeft'
                      ? 'lg:border-r lg:border-th-input-border'
                      : ''
                  }`}
                  key="balances"
                >
                  <div className="h-[calc(100%-36px)]">
                    {tradeType === 'Swap' ? (
                      <SwapInfoTabs />
                    ) : (
                      <div className="h-full">
                        <div className="flex w-full items-center justify-between md:w-auto md:border-b md:border-th-input-border lg:w-full">
                          <TabButtons
                            activeValue={listSection as ListSection}
                            onChange={(section) =>
                              setListSection(section as ListSection)
                            }
                            values={[
                              [ListSection.Positions, positionsCount],
                              [ListSection.Orders, ordersCount],
                              [ListSection.Trades, 0],
                              [ListSection.RecentTrades, 0],
                              [ListSection.Market, 0],
                            ]}
                            showBorders
                            fillWidth={false}
                          />

                          <div className="flex h-12">
                            <Checkbox
                              isChecked={savedShouldShowPositionLines}
                              setIsChecked={setSavedShouldShowPositionLines}
                              className="w-max px-4"
                            >
                              <span>Chart Positions</span>
                            </Checkbox>
                          </div>
                        </div>
                        <div className="h-[calc(100%-48px)]">
                          {listSection === ListSection.Positions && (
                            <PositionList
                              positionsData={positionsInfoData}
                              ordersData={ordersInfoData}
                              isLoading={isPositionsLoading}
                              showPnlInLeverage={!savedIsPnlInLeverage}
                              onOrdersClick={() =>
                                setListSection(ListSection.Orders)
                              }
                              onSettlePositionFeesClick={
                                handleSettlePositionFeesClick
                              }
                              onSelectPositionClick={onSelectPositionClick}
                              onClosePositionClick={setClosingPositionKey}
                              onEditCollateralClick={setEditingPositionKey}
                              showPnlAfterFees={showPnlAfterFees}
                              savedShowPnlAfterFees={!savedShowPnlAfterFees}
                              currentMarketAddress={marketAddress}
                              currentCollateralAddress={collateralAddress}
                              currentTradeType={tradeType}
                            />
                          )}
                          {listSection === ListSection.Orders && (
                            <OrderList
                              marketsInfoData={marketsInfoData}
                              tokensData={tokensData}
                              positionsData={positionsInfoData}
                              ordersData={ordersInfoData}
                              selectedOrdersKeys={selectedOrdersKeys}
                              setSelectedOrdersKeys={setSelectedOrdersKeys}
                              isLoading={isOrdersLoading}
                              setPendingTxns={setPendingTxns}
                            />
                          )}
                          {listSection === ListSection.Trades && (
                            <div className="h-full">
                              <div className="flex h-12 items-center justify-between border-b border-th-input-border px-6">
                                <TranscastionStatusFilter
                                  options={transcastionStatusFilters}
                                  updateFiltersState={
                                    setTranscastionStatusFilters
                                  }
                                />
                                <div className="flex items-center gap-4">
                                  <DateRangePicker
                                    startDate={startDate}
                                    endDate={endDate}
                                    setStartDate={setStartDate}
                                    setEndDate={setEndDate}
                                  />
                                  {csvData && (
                                    <DownloadAsCsv
                                      excludedFields={[]}
                                      fileName={
                                        'rfx_history_' +
                                        listSection +
                                        '_' +
                                        dayjs().format()
                                      }
                                      data={csvData}
                                      variant="small"
                                      customDownloadFn={() =>
                                        downloadCSV(
                                          csvData,
                                          'rfx_history_' +
                                            listSection +
                                            '_' +
                                            dayjs().format(),
                                        )
                                      }
                                    />
                                  )}
                                </div>
                              </div>
                              <div className="h-[calc(100%-48px)]">
                                <TradeHistory
                                  activeTab={tradeType}
                                  account={account}
                                  marketsInfoData={marketsInfoData}
                                  tokensData={tokensData}
                                  shouldShowPaginationButtons
                                  hasFilters={false}
                                  filterDates={{
                                    endDate: endDate,
                                    startDate: startDate,
                                  }}
                                  transactionStatusFilters={
                                    transcastionStatusFilters
                                  }
                                  setCsvData={setCsvData}
                                />
                              </div>
                            </div>
                          )}

                          {listSection === ListSection.RecentTrades && (
                            <DesktopRecentTrades
                              marketsInfoData={marketsInfoData}
                            />
                          )}

                          {listSection === ListSection.Market && (
                            <MarketTable />
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </ResponsiveGridLayout>
            </div>
            <div
              className={`relative border-y border-l border-th-input-border lg:border-b-0 ${
                tradeLayout === 'chartMiddleOBRight'
                  ? 'lg:border-l-0 lg:border-r'
                  : ''
              } ${
                tradeLayout === 'chartRight' ? 'lg:border-l-0 lg:border-r' : ''
              } ${tradeLayout === 'chartLeft' ? 'lg:border-l-0' : ''}`}
              style={{ width: '396px' }}
            >
              {tradeBox}
            </div>
          </div>
        </>
      )}
      <PositionSeller
        position={closingPosition!}
        marketsInfoData={marketsInfoData}
        tokensData={tokensData}
        showPnlInLeverage={!savedIsPnlInLeverage}
        onClose={onPositionSellerClose}
        acceptablePriceImpactBps={acceptablePriceImpactBps}
        setPendingTxns={setPendingTxns}
        availableTokensOptions={availableTokensOptions}
        isHigherSlippageAllowed={isHigherSlippageAllowed}
        setIsHigherSlippageAllowed={setIsHigherSlippageAllowed}
        shouldDisableValidation={true}
        onEditAcceptablePriceImpact={onEditAcceptablePriceImpact}
        positionsData={positionsInfoData}
        ordersData={ordersInfoData}
      />

      <PositionEditor
        tokensData={tokensData}
        showPnlInLeverage={!savedIsPnlInLeverage}
        position={editingPosition}
        allowedSlippage={allowedSlippage!}
        onClose={onPositionEditorClose}
        setPendingTxns={setPendingTxns}
        shouldDisableValidation={true}
      />

      <Modal
        className="Connect-wallet-modal"
        isOpen={showReferralModal}
        onClose={() => setShowReferralModal(false)}
        title={`Join Referral Code`}
      >
        <div className="h-full pt-4">
          <ReferralCodeForm
            userReferralCodeString={userReferralCodeString || ''}
            defaultValue={(query?.ref as string) || ''}
            type="edit"
            callAfterSuccess={() => setShowReferralModal(false)}
          />
        </div>
      </Modal>
    </>
  )
}

export default TradeAdvancedPage
