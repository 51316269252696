import {
  formatTokenAmount,
  formatUsd,
} from '@components/trade/gmx/tradingview/lib/numbers'
import tradeboxStore from '@store/tradeboxStore'
import BuyInputSection from 'components/BuyInputSection/BuyInputSection'
import TokenSelector from 'components/TokenSelector/TokenSelector'
import { TokenData } from 'domain/synthetics/tokens'
import { AvailableTokenOptions } from 'domain/synthetics/trade'
import { Token } from 'config/tokens'
import { memo } from 'react'

type Props = {
  isIncrease: boolean
  fromToken: TokenData | undefined
  fromTokenInputValue: string
  onInputValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  onMaxClick: () => void
  fromTokenAddress: string | undefined
  chainId: number
  onSelectFromTokenAddress: (token: Token) => void
  availableTokenOptions: AvailableTokenOptions
}

const PayComponent = (p: Props) => {
  const {
    isIncrease,
    fromToken,
    fromTokenInputValue,
    onInputValueChange,

    onMaxClick,
    fromTokenAddress,
    chainId,
    onSelectFromTokenAddress,
    availableTokenOptions,
  } = p

  const { swapTokens, infoTokens, sortedLongAndShortTokens } =
    availableTokenOptions

  const initialCollateralUsd = tradeboxStore((store) => {
    const initialCollateralUsd = store.increaseAmounts?.initialCollateralUsd
    const fromUsd = store.fromUsd
    return fromUsd?.gt(0)
      ? formatUsd(isIncrease ? initialCollateralUsd : fromUsd)
      : ''
  })

  const isNotMatchAvailableBalance = tradeboxStore((store) => {
    const fromTokenAmount = store.fromTokenAmount
    return fromToken?.balance?.gt(0) && !fromToken.balance.eq(fromTokenAmount)
  })

  return (
    <BuyInputSection
      topLeftLabel={`Pay`}
      topLeftValue={initialCollateralUsd}
      topRightLabel={`Balance`}
      topRightValue={formatTokenAmount(
        fromToken?.balance,
        fromToken?.decimals,
        '',
        {
          useCommas: true,
        },
      )}
      // onClickTopRightLabel={onMaxClick}
      inputValue={fromTokenInputValue}
      onInputValueChange={onInputValueChange}
      showMaxButton={isNotMatchAvailableBalance}
      onClickMax={onMaxClick}
    >
      {fromTokenAddress && (
        <TokenSelector
          label={`Pay`}
          chainId={chainId}
          tokenAddress={fromTokenAddress}
          onSelectToken={onSelectFromTokenAddress}
          tokens={swapTokens}
          infoTokens={infoTokens}
          showSymbolImage={true}
          showTokenImgInDropdown={true}
          extendedSortSequence={sortedLongAndShortTokens}
          inputFieldValue={fromTokenInputValue}
        />
      )}
    </BuyInputSection>
  )
}

export default memo(PayComponent)
