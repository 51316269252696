import tradeboxStore from '@store/tradeboxStore'
import { TradeFeesRow } from '../TradeFeesRow/TradeFeesRow'

const WrappedTradeFeesRow = () => {
  const fees = tradeboxStore((store) => store.fees)
  const feesType = tradeboxStore((store) => store.feesType)

  // TODO: Refactor TradeFeesRow
  return (
    feesType && (
      <TradeFeesRow
        {...fees}
        // executionFee={executionFee}
        feesType={feesType}
      />
    )
  )
}

export default WrappedTradeFeesRow
