import useLocalStorageState from 'hooks/useLocalStorageState'
import { useViewport } from 'hooks/useViewport'
import { useCallback, useMemo, useState } from 'react'
import { Layout, Layouts } from 'react-grid-layout'
import { useIsomorphicLayoutEffect } from 'react-use'
import { SIDEBAR_COLLAPSE_KEY, TRADE_LAYOUT_KEY } from 'utils/constants'
import { breakpoints } from 'utils/theme'

export type TradeLayout =
  | 'chartLeft'
  | 'chartMiddleOBRight'
  | 'chartMiddleOBLeft'
  | 'chartRight'

const getHeight = (
  pageHeight: number,
  minHeight: number,
  remainingRowHeight: number,
) => {
  return Math.max(minHeight, pageHeight - remainingRowHeight)
}

const marketHeaderHeight = 48
const minPageHeight = 1000
const topnavbarHeight = 64
const statusBarHeight = 27
const totalCols = 24

const useTradePageLayout = () => {
  const { height } = useViewport()
  const [tradeLayout] = useLocalStorageState<TradeLayout>(
    TRADE_LAYOUT_KEY,
    'chartLeft',
  )
  const [isCollapsed] = useLocalStorageState(SIDEBAR_COLLAPSE_KEY, false)

  const innerHeight = useMemo(() => {
    return Math.max(height - topnavbarHeight - statusBarHeight, minPageHeight)
  }, [height])

  const defaultLayouts: ReactGridLayout.Layouts = useMemo(() => {
    const balancesXPos = {
      chartLeft: { xxxl: 0, xxl: 0, xl: 0, lg: 0 },
      chartMiddleOBRight: { xxxl: 4, xxl: 5, xl: 5, lg: 6 },
      chartMiddleOBLeft: { xxxl: 0, xxl: 0, xl: 0, lg: 0 },
      chartRight: { xxxl: 4, xxl: 5, xl: 5, lg: 5 },
    }

    const chartXPos = {
      chartLeft: { xxxl: 0, xxl: 0, xl: 0, lg: 0 },
      chartMiddleOBRight: { xxxl: 4, xxl: 5, xl: 5, lg: 5 },
      chartMiddleOBLeft: {
        xxxl: 4,
        xxl: 4,
        xl: 5,
        lg: 5,
      },
      chartRight: {
        xxxl: 9,
        xxl: 9,
        xl: 10,
        lg: 11,
      },
    }

    // const bookXPos = {
    //   chartLeft: {
    //     xxxl: 19,
    //     xxl: 19,
    //     xl: 19,
    //     lg: 18,
    //   },
    //   chartMiddleOBRight: {
    //     xxxl: 20,
    //     xxl: 20,
    //     xl: 19,
    //     lg: 19,
    //   },
    //   chartMiddleOBLeft: { xxxl: 0, xxl: 0, xl: 0, lg: 0 },
    //   chartRight: { xxxl: 4, xxl: 5, xl: 5, lg: 5 },
    // }

    // const formXPos = {
    //   chartLeft: { xxxl: 18, xxl: 19, xl: 19, lg: 19 },
    //   chartMiddleOBRight: { xxxl: 0, xxl: 0, xl: 0, lg: 0 },
    //   chartMiddleOBLeft: { xxxl: 20, xxl: 19, xl: 19, lg: 19 },
    //   chartRight: { xxxl: 0, xxl: 0, xl: 0, lg: 0 },
    // }

    return {
      xxxl: [
        { i: 'market-header', x: 0, y: 0, w: 24, h: marketHeaderHeight },
        {
          i: 'tv-chart',
          x: chartXPos[tradeLayout].xxxl,
          y: 1,
          w: 24,
          h: 640,
        },

        // {
        //   i: 'trade-form',
        //   x: formXPos[tradeLayout].xxxl,
        //   y: 1,
        //   w: 6,
        //   h: getHeight(innerHeight, 0, marketHeaderHeight),
        // },
        {
          i: 'balances',
          x: balancesXPos[tradeLayout].xxxl,
          y: 2,
          w: 24,
          h: getHeight(innerHeight, 0, 640 + marketHeaderHeight),
        },
      ],
      xxl: [
        { i: 'market-header', x: 0, y: 0, w: 24, h: marketHeaderHeight },
        {
          i: 'tv-chart',
          x: chartXPos[tradeLayout].xxl,
          y: 1,
          w: 24,
          h: 464,
        },

        // {
        //   i: 'trade-form',
        //   x: formXPos[tradeLayout].xxl,
        //   y: 1,
        //   w: 6.947,
        //   h: getHeight(innerHeight, 0, marketHeaderHeight),
        // },
        {
          i: 'balances',
          x: balancesXPos[tradeLayout].xxl,
          y: 2,
          w: 24,
          h: getHeight(innerHeight, 0, 464 + marketHeaderHeight),
        },
      ],
      xl: [
        { i: 'market-header', x: 0, y: 0, w: 24, h: marketHeaderHeight },
        {
          i: 'tv-chart',
          x: chartXPos[tradeLayout].xl,
          y: 1,
          w: 24,
          h: 464,
        },
        // {
        //   i: 'trade-form',
        //   x: formXPos[tradeLayout].xl,
        //   y: 1,
        //   w: 6.947,
        //   h: getHeight(innerHeight, 0, 0),
        // },
        {
          i: 'balances',
          x: balancesXPos[tradeLayout].xl,
          y: 2,
          w: 24,
          h: getHeight(innerHeight, 0, 464),
        },
      ],
      lg: [
        { i: 'market-header', x: 0, y: 0, w: 24, h: marketHeaderHeight },
        {
          i: 'tv-chart',
          x: chartXPos[tradeLayout].lg,
          y: 1,
          w: 24,
          h: 464,
        },
        // {
        //   i: 'trade-form',
        //   x: formXPos[tradeLayout].lg,
        //   y: 1,
        //   w: 6,
        //   h: getHeight(innerHeight, 0, 0),
        // },
        {
          i: 'balances',
          x: balancesXPos[tradeLayout].lg,
          y: 2,
          w: 24,
          h: getHeight(innerHeight, 0, 464),
        },
      ],
      md: [
        { i: 'market-header', x: 0, y: 0, w: 24, h: marketHeaderHeight },
        { i: 'tv-chart', x: 0, y: 1, w: 24, h: 552 },
        {
          i: 'balances',
          x: 0,
          y: 2,
          w: 24,
          h: 572,
        },
      ],
    }
  }, [innerHeight, tradeLayout])

  const [layouts, setLayouts] = useState<Layouts>(defaultLayouts)
  const [breakpoint, setBreakpoint] = useState('')

  const handleLayoutChange = useCallback(
    (layout: Layout[] | undefined, layouts: Layouts) => {
      setLayouts(layouts)
    },
    [setLayouts],
  )

  useIsomorphicLayoutEffect(() => {
    handleLayoutChange(undefined, defaultLayouts)
  }, [breakpoint, tradeLayout])

  const layoutProps = useMemo(() => {
    const sidebarWidth = isCollapsed ? 64 : 200
    const gridBreakpoints = {
      md: breakpoints.md - sidebarWidth,
      lg: breakpoints.lg - sidebarWidth,
      xl: breakpoints.xl - sidebarWidth,
      xxl: breakpoints['2xl'] - sidebarWidth,
      xxxl: breakpoints['3xl'] - sidebarWidth,
    }

    const cols = {
      xxxl: totalCols,
      xxl: totalCols,
      xl: totalCols,
      lg: totalCols,
      md: totalCols,
      sm: totalCols,
    }

    const layoutProps: ReactGridLayout.ResponsiveProps &
      ReactGridLayout.WidthProviderProps = {
      breakpoints: gridBreakpoints,
      layouts,
      onBreakpointChange: setBreakpoint,
      cols,
      onLayoutChange: handleLayoutChange,
      rowHeight: 1,
      isDraggable: false,
      isResizable: false,
      containerPadding: [0, 0],
      margin: [0, 0],
      useCSSTransforms: true,
    }

    return layoutProps
  }, [handleLayoutChange, isCollapsed, layouts])

  return {
    layoutProps,
    tradeLayout,
  }
}

export default useTradePageLayout
