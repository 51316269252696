import Button from '@components/shared/Button'
import tradeboxStore from '@store/tradeboxStore'
import { TradeType } from 'domain/synthetics/trade/types'
import { memo, useMemo } from 'react'
import { TradeBoxProps } from './TradeBox'
import { getTriggerNameByOrderType } from 'domain/synthetics/positions'

type Props = Pick<TradeBoxProps, 'tradeType'> & {
  account: string | undefined
  onSubmit: () => void
  shouldDisableValidation: boolean
  handleSubaccountClick: () => void
  oneClickTradingError: boolean
}

const TradeBoxFormSubmitButton = (p: Props) => {
  const {
    account,
    onSubmit,
    tradeType,
    shouldDisableValidation,
    oneClickTradingError,
  } = p
  const error = tradeboxStore((store) => store.formError)
  const decreaseAmounts = tradeboxStore((store) => store.decreaseAmounts)
  const submitButtonText = tradeboxStore((store) => store.submitButtonText)
  const isSubmitButtonDisabled = useMemo(() => {
    if (!account) {
      return false
    }
    if (oneClickTradingError) {
      return true
    }
    if (error) {
      return true
    }
  }, [error, account, oneClickTradingError])

  return (
    <Button
      className={`w-full text-sm font-bold uppercase ${
        !['Take-Profit', 'Stop-Loss'].includes(
          getTriggerNameByOrderType(decreaseAmounts?.triggerOrderType),
        )
          ? submitButtonText === 'Connect Wallet'
            ? 'connect-wallet'
            : tradeType === TradeType.Long
              ? 'long'
              : tradeType === TradeType.Short
                ? 'short'
                : 'swap'
          : tradeType === TradeType.Long &&
            (getTriggerNameByOrderType(decreaseAmounts?.triggerOrderType) ===
            'Take-Profit'
              ? 'long'
              : 'short')
      } ${
        tradeType === TradeType.Short &&
        (getTriggerNameByOrderType(decreaseAmounts?.triggerOrderType) ===
        'Stop-Loss'
          ? 'short'
          : 'long')
      } ${oneClickTradingError && '!border !border-th-warning !bg-transparent !text-th-warning'} `}
      onClick={() =>
        submitButtonText === 'Enable One Click Trading'
          ? p.handleSubaccountClick()
          : onSubmit()
      }
      disabled={
        (isSubmitButtonDisabled && !shouldDisableValidation) ||
        submitButtonText === 'Connect Wallet'
      }
    >
      {error || submitButtonText}
    </Button>
  )
}

export default memo(TradeBoxFormSubmitButton)
