import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import { formatDeltaUsd, formatPercentage, formatUsd } from 'gmx/lib/numbers'
import {
  formatAcceptablePrice,
  formatLeverage,
  formatLiquidationPrice,
} from 'domain/synthetics/positions'
import { ValueTransition } from 'components/ValueTransition/ValueTransition'
import { TradeBoxProps } from './TradeBox'
import { OrderType } from 'domain/synthetics/orders'
import Edit from 'public/icons/edit.svg'
import tradeboxStore, {
  tradeboxStoreBigNumberSelectorArr,
} from '@store/tradeboxStore'

type Props = Pick<
  TradeBoxProps,
  | 'toToken'
  | 'acceptablePriceImpactBpsForLimitOrders'
  | 'existingPosition'
  | 'setIsEditingAcceptablePriceImpact'
> & {}

function TriggerOrderInfo(p: Props) {
  const {
    toToken,
    acceptablePriceImpactBpsForLimitOrders,
    existingPosition,
    setIsEditingAcceptablePriceImpact,
  } = p

  const [
    triggerOrderType,
    sizeDeltaUsd,
    isFullClose,
    estimatedPnl,
    estimatedPnlPercentage,
  ] = tradeboxStoreBigNumberSelectorArr((store) =>
    store.decreaseAmounts
      ? [
          store.decreaseAmounts.triggerOrderType,
          store.decreaseAmounts.sizeDeltaUsd,
          store.decreaseAmounts.isFullClose,
          store.decreaseAmounts.estimatedPnl,
          store.decreaseAmounts.estimatedPnlPercentage,
        ]
      : [],
  )

  const [
    nextLiqPrice,
    nextSizeUsd,
    nextCollateralUsd,
    nextLeverage,
    nextPnl,
    nextPnlPercentage,
  ] = tradeboxStoreBigNumberSelectorArr((store) =>
    store.nextPositionValues
      ? [
          store.nextPositionValues.nextLiqPrice,
          store.nextPositionValues.nextSizeUsd,
          store.nextPositionValues.nextCollateralUsd,
          store.nextPositionValues.nextLeverage,
          store.nextPositionValues.nextPnl,
          store.nextPositionValues.nextPnlPercentage,
        ]
      : [],
  )

  const triggerPrice = tradeboxStore((store) => {
    if (store.decreaseAmounts) {
      const { triggerPrice, triggerThresholdType } = store.decreaseAmounts
      return `${triggerThresholdType || ''} ${
        formatUsd(triggerPrice, {
          displayDecimals: toToken?.priceDecimals,
        }) || '-'
      }`
    }
    return '-'
  })

  const acceptablePrice = tradeboxStore((store) => {
    if (store.decreaseAmounts) {
      const { acceptablePrice, sizeDeltaUsd } = store.decreaseAmounts
      return sizeDeltaUsd.gt(0)
        ? formatAcceptablePrice(acceptablePrice, {
            displayDecimals: toToken?.priceDecimals,
          })
        : '-'
    }
    return '-'
  })

  return (
    <>
      <ExchangeInfoRow
        className="mb-1"
        label={`Trigger Price`}
        value={triggerPrice}
      />

      <ExchangeInfoRow
        className="mb-1"
        label={`Acceptable Price Impact`}
        value={
          triggerOrderType === OrderType.StopLossDecrease ? (
            'NA'
          ) : (
            <p
              className="flex cursor-pointer items-center gap-1.5 text-th-fgd-1"
              onClick={() => setIsEditingAcceptablePriceImpact(true)}
            >
              <span>
                {formatPercentage(
                  acceptablePriceImpactBpsForLimitOrders.mul(-1),
                )}
              </span>
              <span className="opacity-70" onClick={() => null}>
                <Edit width={16} height={16} />
              </span>
            </p>
          )
        }
      />

      <ExchangeInfoRow
        className="mb-1"
        label={`Acceptable Price`}
        value={acceptablePrice}
      />

      {existingPosition && (
        <>
          <ExchangeInfoRow
            className="mb-1"
            label={`Liq. Price`}
            value={
              isFullClose ? (
                '-'
              ) : (
                <ValueTransition
                  from={formatLiquidationPrice(
                    existingPosition.liquidationPrice,
                    {
                      displayDecimals:
                        existingPosition.indexToken?.priceDecimals,
                    },
                  )}
                  to={
                    sizeDeltaUsd?.gt(0)
                      ? formatLiquidationPrice(nextLiqPrice, {
                          displayDecimals: toToken?.priceDecimals,
                        })
                      : undefined
                  }
                />
              )
            }
          />
          {existingPosition.sizeInUsd.gt(0) && (
            <ExchangeInfoRow
              className="mb-1"
              label={`Size`}
              value={
                <ValueTransition
                  from={formatUsd(existingPosition.sizeInUsd)!}
                  to={formatUsd(nextSizeUsd)}
                />
              }
            />
          )}
          <ExchangeInfoRow
            className="mb-1"
            label={`Collateral (${existingPosition?.collateralToken?.symbol})`}
            value={
              <ValueTransition
                from={formatUsd(existingPosition.collateralUsd)}
                to={formatUsd(nextCollateralUsd)}
              />
            }
          />
          <ExchangeInfoRow
            className="mb-1"
            label={`Leverage`}
            value={
              existingPosition.sizeInUsd.eq(sizeDeltaUsd || 0) ? (
                '-'
              ) : (
                <ValueTransition
                  from={formatLeverage(existingPosition.leverage)}
                  to={formatLeverage(nextLeverage)}
                />
              )
            }
          />
          <ExchangeInfoRow
            label={`PnL`}
            value={
              <ValueTransition
                from={
                  <>
                    {formatDeltaUsd(estimatedPnl)} (
                    {formatPercentage(estimatedPnlPercentage, {
                      signed: true,
                    })}
                    )
                  </>
                }
                to={
                  sizeDeltaUsd?.gt(0) ? (
                    <>
                      {formatDeltaUsd(nextPnl)} (
                      {formatPercentage(nextPnlPercentage, {
                        signed: true,
                      })}
                      )
                    </>
                  ) : undefined
                }
              />
            }
          />
        </>
      )}
    </>
  )
}

export default TriggerOrderInfo
