import { useAvailableMarketsOptions } from 'domain/synthetics/trade/useAvailableMarketsOptions'
import { TradeBoxProps } from './TradeBox'
import tradeboxStore, {
  tradeboxStoreBigNumberSelector,
} from '@store/tradeboxStore'
import { useEffect } from 'react'
import { usePrevious } from 'react-use'

type Props = Pick<
  TradeBoxProps,
  | 'tradeFlags'
  | 'fromToken'
  | 'toToken'
  | 'marketsInfoData'
  | 'positionsInfo'
  | 'ordersInfo'
  | 'marketAddress'
  | 'onSelectMarketAddress'
  | 'onSelectCollateralAddress'
> & { hasExistingOrder: boolean; hasExistingPosition: boolean; chainId: number }

const MarketOptionsUpdater = (p: Props) => {
  const {
    chainId,
    hasExistingOrder,
    hasExistingPosition,
    marketAddress,
    marketsInfoData,
    onSelectCollateralAddress,
    onSelectMarketAddress,
    ordersInfo,
    positionsInfo,
    tradeFlags,
    toToken,
  } = p
  const sizeDeltaUsd = tradeboxStoreBigNumberSelector(
    (store) => store.increaseAmounts?.sizeDeltaUsd,
  )
  const updateProp = tradeboxStore((store) => store.updateProp)

  const { isIncrease, isLong, isPosition } = tradeFlags
  const marketsOptions = useAvailableMarketsOptions({
    marketsInfoData,
    isIncrease,
    disable: !isPosition,
    indexToken: toToken,
    isLong,
    increaseSizeUsd: sizeDeltaUsd,
    positionsInfo,
    ordersInfo,
    hasExistingOrder,
    hasExistingPosition,
  })

  useEffect(() => {
    updateProp('marketsOptions', marketsOptions)
  }, [updateProp, marketsOptions])

  useEffect(
    function updatePositionMarket() {
      if (!isPosition || !marketsOptions.availableMarkets) {
        return
      }

      const needUpdateMarket =
        !marketAddress ||
        !marketsOptions.availableMarkets.find(
          (m) => m.marketTokenAddress === marketAddress,
        )

      if (needUpdateMarket && marketsOptions.marketWithPosition) {
        onSelectMarketAddress(
          marketsOptions.marketWithPosition.marketTokenAddress,
        )
        return
      }

      const optimalMarket =
        marketsOptions.minPriceImpactMarket ||
        marketsOptions.maxLiquidityMarket ||
        marketsOptions.availableMarkets[0]

      if (needUpdateMarket && optimalMarket) {
        onSelectMarketAddress(optimalMarket.marketTokenAddress)
        return
      }
    },
    [
      chainId,
      isLong,
      isPosition,
      marketAddress,
      marketsOptions.availableMarkets,
      marketsOptions.collateralWithPosition,
      marketsOptions.marketWithPosition,
      marketsOptions.maxLiquidityMarket,
      marketsOptions.minPriceImpactMarket,
      onSelectCollateralAddress,
      onSelectMarketAddress,
    ],
  )

  const prevMarketAddress = usePrevious(marketAddress)
  useEffect(
    function updatePositionCollateral() {
      if (!isPosition) {
        return
      }

      if (
        marketAddress &&
        prevMarketAddress !== marketAddress &&
        marketsOptions.collateralWithPosition
      ) {
        onSelectCollateralAddress(marketsOptions.collateralWithPosition.address)
      }
    },
    [
      isPosition,
      marketAddress,
      marketsOptions.collateralWithPosition,
      onSelectCollateralAddress,
      prevMarketAddress,
    ],
  )

  return null
}

export default MarketOptionsUpdater
