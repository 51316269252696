import Tooltip from '@components/shared/Tooltip'
import tradeboxStore, {
  tradeboxStoreBigNumberSelector,
} from '@store/tradeboxStore'
import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import { PoolSelector } from 'components/MarketSelector/PoolSelector'
import {
  Market,
  MarketInfo,
  getMarketIndexName,
  getMarketPoolName,
} from 'domain/synthetics/markets'
import { Token } from 'domain/tokens'
import { formatPercentage } from 'gmx/lib/numbers'
import { memo, useCallback, useMemo } from 'react'

export type Props = {
  indexToken?: Token
  selectedMarket?: MarketInfo
  hasExistingPosition?: boolean
  hasExistingOrder?: boolean
  // isOutPositionLiquidity?: boolean
  onSelectMarketAddress: (marketAddress?: string) => void
}

function MarketPoolSelectorRow(p: Props) {
  const {
    selectedMarket,
    indexToken,
    hasExistingOrder,
    hasExistingPosition,
    onSelectMarketAddress,
  } = p

  const marketsOptions = tradeboxStore((store) => store.marketsOptions)
  const isOutPositionLiquidity = tradeboxStore(
    (store) => store.isOutPositionLiquidity,
  )
  const currentPriceImpactBps = tradeboxStoreBigNumberSelector(
    (store) => store.increaseAmounts?.acceptablePriceDeltaBps,
  )

  const {
    isNoSufficientLiquidityInAnyMarket,
    marketWithOrder,
    marketWithPosition,
    maxLiquidityMarket,
    availableMarkets,
    minPriceImpactMarket,
    minPriceImpactBps,
  } = marketsOptions || {}

  const indexName = indexToken
    ? getMarketIndexName({ indexToken, isSpotOnly: false })
    : undefined

  const isSelectedMarket = useCallback(
    (market: Market) => {
      return (
        selectedMarket &&
        market.marketTokenAddress === selectedMarket.marketTokenAddress
      )
    },
    [selectedMarket],
  )

  const message = useMemo(() => {
    if (isNoSufficientLiquidityInAnyMarket) {
      return (
        <div>
          <>
            Insufficient liquidity in any {indexToken?.symbol}/USD market pools
            for your order.
          </>
          <br />
          <br />
          <>V2 is newly live, and liquidity may be low initially.</>
        </div>
      )
    }

    if (
      isOutPositionLiquidity &&
      maxLiquidityMarket &&
      !isSelectedMarket(maxLiquidityMarket)
    ) {
      return (
        <div>
          <>
            Insufficient liquidity in{' '}
            {selectedMarket ? getMarketPoolName(selectedMarket) : '...'} market
            pool. <br />
            <div
              className="clickable mt-4 underline"
              onClick={() =>
                onSelectMarketAddress(maxLiquidityMarket!.marketTokenAddress)
              }
            >
              Switch to {getMarketPoolName(maxLiquidityMarket)} market pool.
            </div>
          </>
        </div>
      )
    }

    if (
      !hasExistingPosition &&
      marketWithPosition &&
      !isSelectedMarket(marketWithPosition)
    ) {
      return (
        <div>
          <>
            You have an existing position in the{' '}
            {getMarketPoolName(marketWithPosition)} market pool.{' '}
            <div
              className="clickable mt-4 underline"
              onClick={() => {
                onSelectMarketAddress(marketWithPosition.marketTokenAddress)
              }}
            >
              Switch to {getMarketPoolName(marketWithPosition)} market pool.
            </div>{' '}
          </>
        </div>
      )
    }

    if (
      !marketWithPosition &&
      !hasExistingOrder &&
      marketWithOrder &&
      !isSelectedMarket(marketWithOrder)
    ) {
      return (
        <div>
          <>
            You have an existing order in the{' '}
            {getMarketPoolName(marketWithOrder)} market pool.{' '}
            <div
              className="clickable mt-4 underline"
              onClick={() => {
                onSelectMarketAddress(marketWithOrder.marketTokenAddress)
              }}
            >
              Switch to {getMarketPoolName(marketWithOrder)} market pool.
            </div>{' '}
          </>
        </div>
      )
    }

    if (
      !marketWithPosition &&
      !marketWithOrder &&
      minPriceImpactMarket &&
      minPriceImpactBps &&
      !isSelectedMarket(minPriceImpactMarket)
    ) {
      return (
        <div>
          <>
            You can get a{' '}
            {formatPercentage(currentPriceImpactBps?.sub(minPriceImpactBps))}{' '}
            better execution price in the{' '}
            {getMarketPoolName(minPriceImpactMarket)} market pool.
            <div
              className="clickable mt-4 underline"
              onClick={() =>
                onSelectMarketAddress(minPriceImpactMarket.marketTokenAddress)
              }
            >
              Switch to {getMarketPoolName(minPriceImpactMarket)} market pool.
            </div>
          </>
        </div>
      )
    }

    return null
  }, [
    currentPriceImpactBps,
    hasExistingOrder,
    hasExistingPosition,
    indexToken?.symbol,
    isNoSufficientLiquidityInAnyMarket,
    isOutPositionLiquidity,
    isSelectedMarket,
    marketWithOrder,
    marketWithPosition,
    maxLiquidityMarket,
    minPriceImpactBps,
    minPriceImpactMarket,
    onSelectMarketAddress,
    selectedMarket,
  ])

  return (
    <ExchangeInfoRow
      className="mb-1"
      label={
        message ? (
          <Tooltip content={<div className="text-th-fgd-1">{message}</div>}>
            {`Pool`}
          </Tooltip>
        ) : (
          `Pool`
        )
      }
      value={
        <>
          <PoolSelector
            label={`Pool`}
            selectedIndexName={indexName}
            selectedMarketAddress={selectedMarket?.marketTokenAddress}
            markets={availableMarkets || []}
            isSideMenu
            onSelectMarket={(marketInfo) =>
              onSelectMarketAddress(marketInfo.marketTokenAddress)
            }
          />
        </>
      }
    />
  )
}

export default memo(MarketPoolSelectorRow)
