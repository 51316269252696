import BuyInputSection from 'components/BuyInputSection/BuyInputSection'
import { PositionInfo } from 'domain/synthetics/positions'
import React from 'react'
import { formatAmount, formatUsd } from 'gmx/lib/numbers'
import { USD_DECIMALS } from 'gmx/lib/legacy'
import { getCloseSizeInput } from './utils'

type Props = {
  existingPosition: PositionInfo | undefined
  closeSizeInputValue: string
  setCloseSizeInputValue: (input: Props['closeSizeInputValue']) => void
}

const DecreaseSizeInput: React.FC<Props> = (p: Props) => {
  const { existingPosition, closeSizeInputValue, setCloseSizeInputValue } = p
  const closeSizeUsd = getCloseSizeInput(closeSizeInputValue)!

  return (
    <BuyInputSection
      topLeftLabel={`Close`}
      topRightLabel={existingPosition?.sizeInUsd ? `Max` : undefined}
      topRightValue={
        existingPosition?.sizeInUsd
          ? formatUsd(existingPosition.sizeInUsd)
          : undefined
      }
      inputValue={closeSizeInputValue}
      onInputValueChange={(e) => setCloseSizeInputValue(e.target.value)}
      // onClickTopRightLabel={() =>
      //   setCloseSizeInputValue(
      //     formatAmount(existingPosition?.sizeInUsd, USD_DECIMALS, 2),
      //   )
      // }
      showMaxButton={
        existingPosition?.sizeInUsd.gt(0) &&
        !closeSizeUsd?.eq(existingPosition.sizeInUsd)
      }
      onClickMax={() =>
        setCloseSizeInputValue(
          formatAmount(existingPosition?.sizeInUsd, USD_DECIMALS, 2),
        )
      }
      showPercentSelector={existingPosition?.sizeInUsd.gt(0)}
      onPercentChange={(percent) =>
        setCloseSizeInputValue(
          formatAmount(
            existingPosition?.sizeInUsd.mul(percent).div(100),
            USD_DECIMALS,
            2,
          ),
        )
      }
    >
      USD
    </BuyInputSection>
  )
}

export default DecreaseSizeInput
