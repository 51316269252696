import ManualRefresh from '@components/shared/ManualRefresh'
import TabButtons from '@components/shared/TabButtons'
import { useLocalStorageSerializeKey } from '@components/trade/gmx/tradingview/lib/localStorage'
import mangoStore from '@store/mangoStore'
import { OrderList } from 'components/Synthetics/OrderList/OrderList'
import { TradeHistory } from 'components/Synthetics/TradeHistory/TradeHistory'
import { IS_PNL_IN_LEVERAGE_KEY } from 'config/localStorage'
import { useMarketsInfo } from 'domain/synthetics/markets'
import { useOrdersInfo } from 'domain/synthetics/orders/useOrdersInfo'
import { usePositionsInfo } from 'domain/synthetics/positions/usePositionsInfo'
import { useChainId } from 'gmx/lib/chains'
import { usePendingTxns } from 'gmx/lib/usePendingTxns'
import useWallet from 'gmx/lib/wallets/useWallet'
import { useViewport } from 'hooks/useViewport'
import { useEffect, useMemo, useState } from 'react'

const SwapInfoTabs = () => {
  const [selectedTab, setSelectedTab] = useState('balances')
  const { isMobile, isTablet } = useViewport()
  const { swapOrTrigger } = mangoStore((s) => s.swap)
  const { account } = useWallet()
  const { chainId } = useChainId()
  const [selectedOrdersKeys, setSelectedOrdersKeys] = useState<{
    [key: string]: boolean
  }>({})
  const [, setPendingTxns] = usePendingTxns()

  const { marketsInfoData, tokensData, pricesUpdatedAt, marketsData } =
    useMarketsInfo(chainId)

  const savedIsPnlInLeverage = useLocalStorageSerializeKey(
    [chainId, IS_PNL_IN_LEVERAGE_KEY],
    false,
  )

  const { positionsInfoData } = usePositionsInfo(chainId, {
    marketsInfoData,
    tokensData,
    pricesUpdatedAt,
    showPnlInLeverage: !!savedIsPnlInLeverage,
    account,
    marketsData,
  })

  const { ordersInfoData, isLoading: isOrdersLoading } = useOrdersInfo(
    chainId,
    {
      account,
      marketsInfoData,
      positionsInfoData,
      tokensData,
    },
  )

  const tabsWithCount: [string, number][] = useMemo(() => {
    const tabs: [string, number][] = [['swap:swap-history', 0]]
    return tabs
  }, [])

  useEffect(() => {
    if (swapOrTrigger !== 'swap') {
      setSelectedTab('trade:trigger-orders')
    } else {
      if (selectedTab !== 'swap:swap-history') {
        setSelectedTab('swap:swap-history')
      }
    }
  }, [selectedTab, swapOrTrigger])

  return (
    <div className="h-full">
      <div className="flex items-center border-b border-th-input-border">
        <div className="w-full">
          <TabButtons
            activeValue={selectedTab}
            onChange={(tab: string) => setSelectedTab(tab)}
            values={tabsWithCount}
            showBorders
          />
        </div>
        <ManualRefresh
          classNames="fixed bottom-16 right-4 md:relative md:px-2 md:bottom-0 md:right-0 z-10 shadow-lg md:shadow-none bg-th-bkg-3 md:bg-transparent"
          hideBg={isMobile || isTablet}
          size={isTablet ? 'large' : 'small'}
        />
      </div>
      <div className="hide-scroll h-[calc(100%-48px)] overflow-y-scroll">
        {selectedTab === 'trade:trigger-orders' ? (
          <OrderList
            marketsInfoData={marketsInfoData}
            tokensData={tokensData}
            positionsData={positionsInfoData}
            ordersData={ordersInfoData}
            selectedOrdersKeys={selectedOrdersKeys}
            setSelectedOrdersKeys={setSelectedOrdersKeys}
            isLoading={isOrdersLoading}
            setPendingTxns={setPendingTxns}
            isSwap={true}
          />
        ) : null}
        {selectedTab === 'swap:swap-history' ? (
          <TradeHistory
            activeTab="Swap"
            account={account || ''}
            marketsInfoData={marketsInfoData}
            tokensData={tokensData}
            shouldShowPaginationButtons
          />
        ) : null}
      </div>
    </div>
  )
}

export default SwapInfoTabs
