import { ResolutionString } from '@public/charting_library/charting_library'

export const SUPPORTED_RESOLUTIONS_V1: Record<
  string | number,
  ResolutionString
> = {
  5: '5m' as ResolutionString,
  15: '15m' as ResolutionString,
  60: '1h' as ResolutionString,
  240: '4h' as ResolutionString,
  '1D': '1d' as ResolutionString,
}
export const SUPPORTED_RESOLUTIONS_V2: Record<
  string | number,
  ResolutionString
> = {
  1: '1m' as ResolutionString,
  5: '5m' as ResolutionString,
  15: '15m' as ResolutionString,
  60: '1h' as ResolutionString,
  240: '4h' as ResolutionString,
  '1D': '1d' as ResolutionString,
}
export const LAST_BAR_REFRESH_INTERVAL = 5000 // 5 seconds
