import tradeboxStore from '@store/tradeboxStore'
import ExchangeInfoRow from 'components/Exchange/ExchangeInfoRow'
import { formatAmount } from 'gmx/lib/numbers'

const BorrowFeeRow = () => {
  const borrowingRate = tradeboxStore((store) => {
    const borrowingRate = store.marketCardInfo.borrowingRate
    return borrowingRate ? `${formatAmount(borrowingRate, 30, 4)}% / 1h` : '...'
  })

  return <ExchangeInfoRow label={`Borrow Fee`} value={borrowingRate} />
}

export default BorrowFeeRow
